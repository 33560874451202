import { FetchResult } from 'apollo-link'
import gql from 'graphql-tag'
import { getApolloClient, handleGraphQLErrors } from '../getApolloClient'

export async function requestResetPassword (resetToken: string, password: string): Promise<void> {
  type Result = FetchResult<{sendPasswordResetEmail: never}>
  return getApolloClient()
    .then(apolloClient =>
      apolloClient.mutate({
        mutation: gql`
          mutation ($password: String!, $resetToken: String!){
            resetPassword(password: $password, resetToken: $resetToken)
          }
        `,
        variables: { password, resetToken },
      }),
    )
    .then((result: Result) => {
      if (result.errors) {
        console.error({ result })
        throw result.errors[0]
      }
      if (!result.data) throw new Error('!result.data')
    })
    .catch(handleGraphQLErrors)
}
