/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { BLACK } from '../../style'
import pdfIcon from './iconPdf.png'

interface Document {
  link: string
  name: string
  date?: string
}

interface Props {
  title?: React.ReactNode
  documents: Document[]
}

const DocumentsList: React.FC<Props> = ({ title, documents }) => {
  return (
    <div>
      {title}
      <div style={{ display: 'flex', flexWrap: 'wrap', margin: '20px 0' }}>
        {documents.map(doc => (
          <div
            key={doc.name}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: '0 50%',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <a
              href={doc.link}
              download
              target='_blank'
              rel='nofollow noopener'
              style={{ marginRight: 24 }}
            >
              <img src={pdfIcon} alt="PDF Icon" style={{ maxWidth: 80, maxHeight: 60 }} />
            </a>
            <a
              href={doc.link}
              download
              target='_blank'
              rel='nofollow noopener'
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <span
                style={{
                  fontSize: 16,
                  color: BLACK,
                  textDecoration: 'underline',
                }}
              >
                {doc.name}
              </span>
              <span style={{ fontSize: 14, color: BLACK }}>{doc.date}</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DocumentsList
