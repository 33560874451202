import { User } from '../../User'
import { ClientProfile, ClientProfileUpdate } from './index'

export interface ClientConsentMap {
  readonly marketing?: Consent
  readonly privacy?: Consent
  readonly research?: Consent
}

export interface ClientConsentMapUpdate {
  readonly marketing?: ConsentUpdate
  readonly privacy?: ConsentUpdate
  readonly research?: ConsentUpdate
}

export interface ClientConsentMapUpload extends ClientConsentMapUpdate {}

export interface Consent {
  readonly value: boolean
  readonly date?: string
}
export interface ConsentUpdate {
  readonly value: boolean
}

export const Consent = {
  setPrivacy: (value: boolean): Promise<void> => {
    const consent: ClientConsentMapUpdate = { privacy: { value } }
    const update: ClientProfileUpdate = { consent }
    return ClientProfile.update(update).then(User.clearCache)
  },
}
