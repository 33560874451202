export interface I18n {
  CONTACT_US: string
  LOGOUT: string
  TOGGLE_BETA: string
  EXPIRES_IN: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    CONTACT_US: frCA ? 'Contact' : 'Contact Us',
    LOGOUT: frCA ? 'Se Déconnecter' : 'Logout',
    TOGGLE_BETA: frCA ? 'Basculer en Mode Bêta' : 'Toggle Beta',
    EXPIRES_IN: frCA ? 'Votre session expire ' : 'Your session expires ',
  }
}
