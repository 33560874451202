import React, { FC, memo, SyntheticEvent, useEffect, useState } from 'react'
import { Form, Modal } from 'antd'
import Button, { btnTypeConfigs } from '../../../../../../../components/Button'
import { RouteComponentProps, withRouter } from 'react-router'
import { InsuranceType, ProfileInsurance } from './index'
import { ClientProfile, IInsuranceCoverage } from '../../../../../../../redux/State/Client/Profile/IClientProfile'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { InsuranceProvider } from '../../../../../../../redux/State/InsuranceProvider'

import Notification from '../../../../../../../components/Notification'
import { AccessToken } from '../../../../../../../auth'
import { postInsuranceCoverage } from './postInsuranceCoverage'
import { User } from '../../../../../../../redux/State/User'
import { toInsuranceData } from '../../../../../../../components/RegAppForm'
import { I18n } from '../index.i18n'

const formItemLayout = {
  labelCol: {
    span: 24,
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

interface UpdateInsuranceProps {
  insuranceProviders?: InsuranceProvider[]
  profile?: ClientProfile | undefined
  insuranceCoverage?: IInsuranceCoverage
  i18n: I18n
}

interface UpdateInsuranceFormProps extends UpdateInsuranceProps {
  form: WrappedFormUtils
}

interface UpdateInsurancePresProps extends RouteComponentProps, UpdateInsuranceFormProps {}

export enum FormState {
  INIT,
  LOADING,
  SUBMITTING,
  ERROR,
}
export const showNoDirectBillingWarning = (callback: () => void, i18n: I18n) => {
  Modal.info({
    title: i18n.THANK_YOU_FOR_SUBMITTING_INSURANCE_COVERAGE_DETAILS_TITLE,
    content: (
      <div>{i18n.THANK_YOU_FOR_SUBMITTING_INSURANCE_COVERAGE_DETAILS_TEXT}</div>
    ),
    maskClosable: true,
    onOk: callback,
    okButtonProps: {
      shape: 'round',
      style: {
        ...btnTypeConfigs['rounded-blue'].style,
        minWidth: '80px',
        height: '46px',
      },
    },
  })
}

export const UpdateInsurancePres: FC<UpdateInsurancePresProps> = memo(({
  form,
  history,
  insuranceProviders,
  insuranceCoverage,
  i18n,
}) => {
  const [formState, setFormState] = useState<FormState | undefined>(FormState.INIT)
  const [error, setError] = useState<string | undefined>()
  const [warning, setWarning] = useState<string | undefined>()
  const [initialValue, setInitialValue] = useState<IInsuranceCoverage | undefined>(undefined)

  useEffect(() => {
    if (insuranceCoverage?.insuranceType) {
      const initials: IInsuranceCoverage = { ...insuranceCoverage }
      setFormState(undefined)
      setInitialValue(initials)
    }
  }, [insuranceCoverage])

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setError(undefined)
    setWarning(undefined)

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    form.validateFieldsAndScroll((err, _valuez) => {
      if (err) {
        console.log(err)
        return
      }
      setFormState(FormState.SUBMITTING)
      const insuranceData = toInsuranceData(form.getFieldsValue() as any, insuranceProviders)

      if (insuranceData) {
        AccessToken.get().then(accessToken => {
          postInsuranceCoverage(insuranceData, accessToken as any).then(() => {
            if (form.getFieldValue('directBilling') !== 'true') {
              showNoDirectBillingWarning(onSubmitSuccess, i18n)
            } else {
              onSubmitSuccess()
            }
          }).catch(onError)
        }).catch(onError)
      } else {
        onError()
      }
    })
  }

  const onError = () => {
    setError('Server Error: Please retry.')
    setFormState(undefined)
  }

  const onSubmitSuccess = () => {
    User.clearCache()
    User.get()
    const matchUrl = window.location.href.match(/\?back=(.+)$/)
    const redirectBackUrl = matchUrl ? matchUrl[1] : null

    if (redirectBackUrl) {
      setFormState(undefined)

      setWarning('Your changes have been submitted, please use the link on the right to return to the Shop and complete your order.')
      return
    }
    history.push('/secure/client')
  }

  return (
    <div>
      {formState === FormState.INIT && <p>loading...</p>}
      {formState === FormState.ERROR && <p>Server Error: Please reload.</p>}
      {formState !== FormState.INIT && formState !== FormState.ERROR && <Form
        {...formItemLayout}
        onSubmit={handleSubmit}
        labelAlign="right"
        style={{
          maxWidth: 800,
          margin: 'auto',
        }}
        wrapperCol={{ span: 24 }}
      >
        <ProfileInsurance form={form} initialMode="rw" initialValue={initialValue}
          insuranceProviders={insuranceProviders}
          typeReadOnly={initialValue?.insuranceType === InsuranceType.VAC || initialValue?.insuranceType === InsuranceType.GROUP_PLAN}
          formReadOnly={ { [InsuranceType.GROUP_PLAN]: false, [InsuranceType.VAC]: true, [InsuranceType.NONE]: false } }
        />
        {error && <div style={{ marginTop: 10 }}>
          <Notification
            text={error}
            type="error"
            title=""
            onClose={() => setError(undefined)}
          />
        </div>}
        {warning && <div style={{ marginTop: 10 }}>
          <Notification text={warning} title="" onClose={() => {
            history.push('/secure/client')
          }} type="success"/>
        </div>}
        <Form.Item style={{ marginTop: 20 }}>
          {form.getFieldValue('insuranceType') === InsuranceType.GROUP_PLAN && <Button
            btnType="rounded-blue"
            htmlType="submit"
            onSubmit={handleSubmit}
            style={{
              height: '46px',
            }}
            disabled={formState === FormState.SUBMITTING}
          >
            {formState === FormState.SUBMITTING ? i18n.LOADING : i18n.SUBMIT}
          </Button>}
          <Button
            btnType="rounded-blue"
            onClick={() => history.push('/secure/client')}
            style={{
              marginLeft: 20,
              height: '46px',
            }}
            disabled={formState === FormState.SUBMITTING}
          >
            {i18n.CANCEL}
          </Button>
        </Form.Item>
      </Form>}
    </div>
  )
})

UpdateInsurancePres.displayName = 'UpdateInsurance'

export const UpdateInsuranceForm: React.ComponentClass<UpdateInsuranceProps> = Form.create<UpdateInsuranceFormProps>(
  { name: 'application' },
)(withRouter(UpdateInsurancePres))
