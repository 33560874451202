import { Config } from '../redux/State/Config'
import { LRError } from './LRError'

export async function requestTokenFromCookie (): Promise<string|null> {
  interface Result {
    isauthenticated: boolean
    token: string|null
  }
  const { URL_LOGIN_RADIUS } = await Config.promise
  const xhr = new XMLHttpRequest()
  const url = URL_LOGIN_RADIUS + '/ssologin/login'
  xhr.open('GET', url, true)
  xhr.withCredentials = true
  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = function () {
      if (xhr.readyState !== 4) return
      if (!xhr.status || !xhr.responseText) {
        return reject(new Error('Invalid ssologin response; xhr: ' + xhrToString(xhr)))
      }
      let data: Result|LRError
      try {
        data = JSON.parse(xhr.responseText)
      } catch (e) {
        return reject(new Error(`Error parsing ssologin response: ${e.message}; xhr: ${xhrToString(xhr)}`))
      }
      if (LRError.is(data)) {
        return reject(new Error(data.Message))
      }
      if (xhr.status !== 200) {
        return reject(new Error(`${xhr.status}: ${xhr.responseText}`))
      }
      const token = data.isauthenticated ? data.token : null
      return resolve(token)
    }
    xhr.send()
  })
}

function xhrToString (xhr: XMLHttpRequest): string {
  // This explicit logging added for ITPL-742
  return JSON.stringify({
    readyState: xhr.readyState,
    response: xhr.response,
    responseText: xhr.responseText,
    responseType: xhr.responseType,
    responseUrl: xhr.responseURL,
    status: xhr.status,
    statusText: xhr.statusText,
  })
}
