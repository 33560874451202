import { Button } from 'antd'
import React, { useEffect } from 'react'
import { Caregiver } from '../../redux/State/Caregiver'
import { CaregiverClient } from '../../redux/State/Caregiver/ICaregiver'
import { CaregiverClientProfile } from '../../redux/State/Caregiver/Profile/ICaregiverProfile'
import Table from '../Table'

interface Props {
  list: CaregiverClient[]
}

export const ClientList: React.FC<Props> = props => {
  const { list } = props

  const columnHeaders = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: 'Name',
    dataIndex: 'profile',
    key: 'name',
    render: (p: CaregiverClientProfile) => p.firstName + ' ' + p.lastName,
  }, {
  //   title: 'Date of Birth',
  //   dataIndex: 'profile.dateOfBirth',
  //   key: 'profile.dateOfBirth',
  // }, {
    title: 'Email Address',
    dataIndex: 'profile.emailAddress',
    key: 'profile.emailAddress',
  }, {
  //   title: 'Phone Number',
  //   dataIndex: 'profile.telephoneNumber',
  //   key: 'profile.telephoneNumber',
  // }, {
    title: 'Shop',
    key: 'shop',
    // eslint-disable-next-line react/display-name
    render: (_: any|never, c: any|CaregiverClient) => <ShopAsButton client={c}/>,
  }]

  useEffect(() => {
    // Hack to prevent navigation on pagination
    // https://auroramj.atlassian.net/browse/ITPL-1476
    const nextA = document.querySelectorAll('.ant-pagination-next > a')[0] as HTMLAnchorElement
    const prevA = document.querySelectorAll('.ant-pagination-prev > a')[0] as HTMLAnchorElement
    if (nextA) {
      // eslint-disable-next-line
      nextA.href = 'javascript:void()'
    }
    if (prevA) {
      // eslint-disable-next-line
      prevA.href = 'javascript:void()'
    }
  }, [list])

  return (
    <Table
      dataSource={list}
      columns={columnHeaders}
      pagination={{ pageSize: 5 }}
    />
  )
}

const ShopAsButton = ({ client }: { client: CaregiverClient}) => {
  return <Button
    type="link"
    onClick={() => Caregiver.shopAsClient(client.id)}
  >Shop as {client.profile.firstName}</Button>
}
