import { Alert, Form } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { parseResetTokenFromUrl } from '../../../auth/index'
import { requestResetPassword } from '../../../auth/requestResetPassword'
import Button from '../../../components/Button/index'
import Card from '../../../components/Card'
import FormInput from '../../../components/FormInput'
import { State as RState } from '../../../redux/State'
import { I18n, getI18n } from './index.i18n'

interface StateProps {
  i18n: I18n
}

interface StateFormProps {
  form: WrappedFormUtils
  i18n: I18n
}

interface State {
  message?: string
  password2?: string
  password?: string
  saving?: boolean
  token?: string
}

export class ResetPasswordPagePres extends React.Component<StateFormProps, State> {
  state: State = {}

  componentDidMount () {
    const { token } = parseResetTokenFromUrl(window.location.href)
    if (!token) {
      console.error('No Reset Token', window.location.href)
      return this.setState({ message: 'Error: No Reset Token' })
    }
    this.setState({ token })
  }

  handleResetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const { token } = this.state
    this.props.form.validateFields((err, { password2, password }) => {
      if (err) {
        return this.setState({ message: undefined })
      }
      if (password.length < 12) {
        return this.setState({ message: 'Password must be at least 12 characters' })
      }
      if (!/[a-zA-Z0-9]/.test(password)) {
        return this.setState({ message: 'Password must contain at least one letter or number' })
      }
      if (!/[^a-zA-Z0-9]/.test(password)) {
        return this.setState({ message: 'Password must contain at least one symbol' })
      }
      if (password !== password2) {
        return this.setState({ message: 'Passwords must match' })
      }
      if (!token) {
        return this.setState({ message: 'Error: No Reset Token' })
      }
      this.setState({ saving: true, message: undefined })
      requestResetPassword(token, password)
        .then(() => {
          window.location.href = '#/secure'
        })
        .catch(error => {
          console.error(error)
          this.setState({ saving: false, message: error.message })
        })
    })
  }

  render () {
    const { message, saving } = this.state
    const { form, i18n } = this.props

    return (
      <Form
        onSubmit={this.handleResetPassword}
        style={{ maxWidth: 350, margin: 'auto', textAlign: 'center' }}
      >
        <Form.Item><h3>Password Reset</h3></Form.Item>
        <Card style={{ flexDirection: 'column' }}>
          <p>{i18n.INSTRUCTIONS_1}</p>
          <p>{i18n.INSTRUCTIONS_2}</p>
        </Card>
        <FormInput
          type="password"
          name="password"
          label="Password"
          form={form}
          decoratorOptions={{
            rules: [{
              required: true,
              message: 'Please enter a new password',
            }],
          }}
        />
        <FormInput
          type="password"
          name="password2"
          label="Confirm Password"
          form={form}
          decoratorOptions={{
            rules: [{
              required: true,
              message: 'Please confirm your new password',
            }],
          }}
        />
        {message && (
          <Alert
            message={message}
            type="error"
            style={{ margin: '15px 0px' }}
          />
        )}
        <Button htmlType="submit" disabled={saving} style={{ minWidth: '70%', height: 48 }}>
          {saving ? 'Saving...' : 'Reset'}
        </Button>
      </Form>
    )
  }
}

const ResetPasswordForm = Form.create({ name: 'reset-paaword' })(ResetPasswordPagePres)

const mapStateToProps: MapStateToProps<StateProps, {}, RState> = (
  state,
  props,
): StateProps => {
  return {
    i18n: getI18n(state.language),
  }
}

export const ResetPasswordPage = connect(mapStateToProps)(ResetPasswordForm)
