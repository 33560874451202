export interface I18n {
  INSTRUCTIONS_1: string
  INSTRUCTIONS_2: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    INSTRUCTIONS_1: frCA
      ? 'Entrez le mot de passe de votre choix! Il doit comporter au moins 12 caractères.'
      : 'Enter a password of your choosing! Your password must be at least 12 characters long.',
    INSTRUCTIONS_2: frCA
      ? 'Nous vous recommandons de choisir un mot de passe difficile à deviner, comme une combinaison de symboles et de chiffres. Créez un mot de passe que vous utiliserez seulement pour votre compte Aurora.'
      : 'We recommend using a password that is hard to guess. Try using a combination of symbols and numbers and choose a password that you will only use for your Aurora account.',
  }
}
