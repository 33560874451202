import gql from 'graphql-tag'
import { ExecutionResult } from 'graphql/execution/execute'
import { Client } from '../'
import { AccessToken } from '../../../../auth/index'
import {
  getApolloClient,
  handleGraphQLErrors,
} from '../../../../getApolloClient'
import { getToday } from '../../../getToday'
import { AddressSetBuilder, MaybeAddress } from '../../Address'
import { User } from '../../User'
import {
  ClientProfile as IClientProfile,
  ClientProfileAmendment,
  ClientProfileDownload,
  ClientProfileRenewal,
  ClientProfileUpdate as IClientProfileUpdate,
  ClientProfileUpload,
} from './IClientProfile'

export type ClientProfile = IClientProfile
export type ClientProfileUpdate = IClientProfileUpdate

export const ClientProfile = {
  amend: amendClientProfile,
  get: () => Client.get().map(c => c.profile),
  fromDownload: (dl: ClientProfileDownload): ClientProfile => {
    return {
      ...dl,
      consent: dl.consent2,
    }
  },
  update: updateClientProfile,
}

export async function submitClientApplication (clientProfile: ClientProfileUpload): Promise<string> {
  type Result = ExecutionResult<{addClientApplication: Client}>
  const apolloClient = await getApolloClient()
  return apolloClient.mutate({
    mutation: gql`
      mutation (
        $clientProfile: ClientApplicationInput!,
        $today: String!
      ){
        addClientApplication(
          clientApplication: $clientProfile,
          today: $today
        ){ id }
      }
    `,
    variables: {
      clientProfile,
      today: getToday(),
    },
  }).then((result: Result) => {
    if (!result.data) {
      console.error('!result.data', result)
      throw new Error('!result.data: ' + JSON.stringify(result))
    }
    const client: Client = result.data.addClientApplication
    return client.id
  }).catch(handleGraphQLErrors)
}

export async function amendClientProfile (amendment: ClientProfileAmendment): Promise<void> {
  const accessToken = await AccessToken.get()
  if (!accessToken) return
  const apolloClient = await getApolloClient()
  return apolloClient.mutate({
    mutation: gql`
      mutation (
        $accessToken: String!,
        $amendment: ClientAmendmentInput!
      ) {
        amendClientProfile(
          accessToken: $accessToken,
          amendment: $amendment
        ){ id }
      }
    `,
    variables: {
      accessToken,
      amendment,
    },
  }).then((result: ExecutionResult) => {
    User.clearCache()
  }).catch(handleGraphQLErrors)
}

export async function renewClientProfile (renewal: ClientProfileRenewal): Promise<void> {
  const accessToken = await AccessToken.get()
  if (!accessToken) return
  const apolloClient = await getApolloClient()
  return apolloClient.mutate({
    mutation: gql`
      mutation (
        $accessToken: String!,
        $renewal: ClientRenewalInput!,
        $today: String!
      ) {
        renewClientProfile(
          accessToken: $accessToken,
          renewal: $renewal,
          today: $today
        ){ id }
      }
    `,
    variables: {
      accessToken,
      renewal,
      today: getToday(),
    },
  }).then(() => {}).catch(handleGraphQLErrors)
}

export async function updateClientProfile (update: ClientProfileUpdate): Promise<void> {
  const accessToken = await AccessToken.get()
  if (!accessToken) return
  const apolloClient = await getApolloClient()
  return apolloClient.mutate({
    mutation: gql`
      mutation (
        $accessToken: String!,
        $today: String!,
        $update: ClientProfileUpdateInput!
      ) {
        updateClientProfile(
          accessToken: $accessToken,
          today: $today,
          update: $update
        ){ id }
      }
    `,
    variables: {
      accessToken,
      today: getToday(),
      update,
    },
  }).then((result: ExecutionResult) => {
    User.clearCache()
  }).catch(handleGraphQLErrors)
}

export function diffClientAmendment (current: ClientProfileAmendment, next: ClientProfileAmendment): ClientProfileAmendment {
  const firstName = current.firstName === next.firstName ? undefined : next.firstName
  const lastName = current.lastName === next.lastName ? undefined : next.lastName
  const physicalAddress = MaybeAddress.equals(current.physicalAddress, next.physicalAddress) ? undefined : next.physicalAddress
  const mailingAddress = MaybeAddress.equals(current.mailingAddress, next.mailingAddress) ? undefined : next.mailingAddress
  const shippingAlias = AddressSetBuilder.fromRecord(current).getShippingAlias() === next.shippingAlias ? undefined : next.shippingAlias
  return {
    firstName,
    lastName,
    physicalAddress,
    mailingAddress,
    shippingAlias,
  }
}
