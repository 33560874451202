import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ClientMatchingPage } from './ClientMatching'
import { ClinicianProfilePage } from './Profile'
import { ReferClientApplicationPage } from './ReferClient'
export class ClinicianPage extends React.Component {
  render () {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}>
        <div style={{ flex: 1, padding: '1em' }}>
          <Switch>
            <Route path="*/clinician/clientmatching" component={ClientMatchingPage} />
            <Route path="*/clinician/referClient" component={ReferClientApplicationPage} />
            <Route component={ClinicianProfilePage} />
          </Switch>
        </div>
      </div>

    )
  }
}
