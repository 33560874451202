import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Mode } from '../../../../../components/RegAppForm/Mode'
import {
  RegAppForm,
  RegFormData,
} from '../../../../../components/RegAppForm/index'
import { Beta } from '../../../../../redux/State/Beta'
import { submitClientApplication } from '../../../../../redux/State/Client/Profile'
import { ClientProfileUpload } from '../../../../../redux/State/Client/Profile/IClientProfile'
import { InsuranceProvider } from '../../../../../redux/State/InsuranceProvider'
import { SyncPromise } from '../../../../../redux/SyncPromise'
import Card from '../../../../../components/Card'
import { State as RootState } from '../../../../../redux/State'

interface StateProps {
  beta: boolean
  insuranceProviders: SyncPromise<InsuranceProvider[]>
  language: 'en-CA'|'fr-CA'
}

interface StateFormProps extends RouteComponentProps, StateProps {}

interface State {
  saving?: boolean
  submitError?: string
}

export class ReferClientPagePres extends React.Component<StateFormProps, State> {
  state: State = {}
  render () {
    const { beta, insuranceProviders, language } = this.props
    const { submitError, saving } = this.state

    const handleSave = (data: RegFormData) => {
      this.setState({ saving: true })
      submitClientApplication(data as ClientProfileUpload)
        .then((id: string|void) => {
          this.setState({ saving: false })
          console.log('Client ID: ', id)
          this.props.history.push('/public/client-registration-applied')
        })
        .catch(error => {
          console.log(error)
          this.setState({ saving: false, submitError: error.message })
        })
    }

    return (
      <>
        <Card style={{ maxWidth: 800, margin: '0 auto 1rem auto' }}>
          <h2>Client Referral</h2>
        </Card>
        <RegAppForm
          handleSave={handleSave}
          initialMode="rw"
          beta={beta}
          insuranceProviders={insuranceProviders.value ?? []}
          saving={saving}
          mode={Mode.APPLICATION}
          submitError={submitError}
          language={language}
        />
      </>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (
  state,
  props,
): StateProps => {
  const beta = Beta.is()
  const insuranceProviders = InsuranceProvider.get()
  return { beta, insuranceProviders, language: state.language }
}

export const ReferClientApplicationPage = withRouter(connect(mapStateToProps)(ReferClientPagePres))
