export interface I18n {
  WELCOME: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    WELCOME: frCA ? 'Bienvenue' : 'Welcome',
  }
}
