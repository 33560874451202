import { Maybe } from '../../Maybe'

export interface Address {
  line1: string
  line2: string
  province: string
  city: string
  postalCode: string
}

export const Address = {
  clean (a: Address): Address {
    const { line1, line2, province, city, postalCode } = a
    return { line1, line2, province, city, postalCode }
  },
  equals (a: Address, b: Address) {
    return true &&
    a.line1 === b.line1 &&
    a.line2 === b.line2 &&
    a.province === b.province &&
    a.city === b.city &&
    a.postalCode === b.postalCode
  },
}
export const MaybeAddress = {
  clean <T extends Maybe<Address>>(a: T): T {
    // @ts-expect-error
    return a && Address.clean(a)
  },
  equals (a: Maybe<Address>, b: Maybe<Address>): boolean {
    return !!a && !!b && Address.equals(a, b)
  },
}
export type ShippingAlias = 'physical'|'mailing'|'practitioner'

export interface AddressSet {
  physical: Address
  mailing: Address
  shipping: Address
  shippingAlias: ShippingAlias
}

export class AddressSetBuilder {
  physical: Maybe<Address>
  mailing: Maybe<Address>
  shipping: Maybe<Address>
  practitioner: Maybe<Address>

  constructor (physical?: Maybe<Address>, mailing?: Maybe<Address>, shipping?: Maybe<Address>) {
    this.physical = MaybeAddress.clean(physical)
    this.mailing = MaybeAddress.clean(mailing ?? physical)
    this.shipping = MaybeAddress.clean(shipping ?? physical)
    if (!this.isShippingAddressSameAsPhysical() && !this.isShippingAddressSameAsMailing()) {
      this.practitioner = MaybeAddress.clean(shipping)
    }
  }

  /* Physical Address */

  setPhysicalAddress (physical: Address) {
    if (this.isMailingAddressSameAsPhysical()) this.mailing = physical
    if (this.isShippingAddressSameAsPhysical()) this.shipping = physical
    this.physical = physical
  }

  /* Mailing Address */

  isMailingAddressSameAsPhysical (): boolean {
    return MaybeAddress.equals(this.mailing, this.physical)
  }

  setMailingAddress (mailing: Address) {
    if (this.isShippingAddressSameAsMailing()) this.shipping = mailing
    this.mailing = mailing
  }

  setMailingAddressDiffToPhysical () {
    if (this.isMailingAddressSameAsPhysical()) this.mailing = undefined
  }

  setMailingAddressSameAsPhysical () {
    if (this.isShippingAddressSameAsMailing()) this.shipping = this.physical
    this.mailing = this.physical
  }

  toggleMailingAddressSameAsPhysical () {
    if (this.isMailingAddressSameAsPhysical()) {
      this.setMailingAddressDiffToPhysical()
    } else {
      this.setMailingAddressSameAsPhysical()
    }
  }

  /* Shipping Address */

  getShippingAlias (): Maybe<ShippingAlias> {
    if (!this.shipping) return
    if (this.isShippingAddressSameAsPhysical()) return 'physical'
    if (this.isShippingAddressSameAsMailing()) return 'mailing'
    return 'practitioner'
  }

  isShippingAddressSameAsPhysical (): boolean {
    return MaybeAddress.equals(this.shipping, this.physical)
  }

  isShippingAddressSameAsMailing (): boolean {
    return MaybeAddress.equals(this.shipping, this.mailing)
  }

  setShippingAddress (shipping: Address) {
    this.shipping = shipping
  }

  setShippingAddressSameAs (alias: ShippingAlias) {
    this.shipping = this[alias]
  }

  sethippingAddressSameAsPhysical () {
    this.shipping = this.physical
  }

  setShippingAddressSameAsMailing () {
    this.shipping = this.mailing
  }

  /* Misc. */

  setAddress (name: ShippingAlias, address: Address) {
    switch (name) {
      case 'physical': this.setPhysicalAddress(address); break
      case 'mailing': this.setMailingAddress(address); break
      default: throw new Error('Cannot set address: ' + name)
    }
  }

  toAddressSet (): AddressSet {
    if (!this.physical) throw new Error('!this.physical')
    return {
      physical: this.physical,
      mailing: this.mailing ?? this.physical,
      shipping: this.shipping ?? this.physical,
      shippingAlias: this.getShippingAlias() ?? 'physical',
    }
  }

  static fromRecord (x: Record<string, Address|any>) {
    return new AddressSetBuilder(
      x.physical || x.physicalAddress,
      x.mailing || x.mailingAddress,
      x.shipping || x.shippingAddress,
    )
  }
}
