import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Button from '../../../components/Button'
import { State } from '../../../redux/State'
import { Beta } from '../../../redux/State/Beta'
import { User } from '../../../redux/State/User'
import { SyncPromise } from '../../../redux/SyncPromise'

export interface Props {}
export interface StateProps {
  beta: boolean
  roles: SyncPromise<string[]>
}

export interface StateRouteProps extends RouteComponentProps, StateProps {}

export class HomePres extends React.Component<StateRouteProps> {
  render () {
    const { roles } = this.props
    const goto = (path: string) => this.props.history.push(path)
    if (roles.value?.length === 1) {
      goto('/secure/' + roles.value[0])
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          flexWrap: 'wrap',
        }}
      >{roles.switchThen(
          <p>ERROR</p>,
          <p>loading...</p>,
          () => <>
            {roles.value?.includes('caregiver') && <Button onClick={() => goto('/secure/caregiver')} style={{ marginBottom: 7 }}>
              Continue as a Caregiver
            </Button>}
            {roles.value?.includes('client') && <Button onClick={() => goto('/secure/client')} style={{ marginBottom: 7 }}>
              Continue as a Client
            </Button>}
            {roles.value?.includes('clinician') && <Button onClick={() => goto('/secure/clinician')} style={{ marginBottom: 7 }}>
              Continue as a Clinician
            </Button>}
          </>,
        )}
      </div>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const beta = Beta.is()
  const roles = User.getRoles()
  return { beta, roles }
}

export const Home = connect(mapStateToProps)(withRouter(HomePres))
