import React, { CSSProperties } from 'react'
import { Route, Switch } from 'react-router'
import { Dashboard } from './Dashboard'
import { Privacy } from './Privacy'
import { Profile } from './Profile'
import { ProfileAmendment } from './ProfileAmendment'
import { ProfileUpdate } from './ProfileUpdate'
import { RenewalPage } from './Renewal/index'
import { InsurancePage } from './Insurance/index'

interface Props {
  style?: CSSProperties
}

export class LeftColumn extends React.Component<Props> {
  render () {
    const { style } = this.props
    return (
      <div style={style}>
        <Switch>
          <Route path="/secure/client/privacy" component={Privacy} />
          <Route
            path="/secure/client/profile-amendment"
            component={ProfileAmendment}
          />
          <Route
            path="/secure/client/profile-update"
            component={ProfileUpdate}
          />
          <Route path="/secure/client/profile" exact component={Profile} />
          <Route path="/secure/client/renewal" component={RenewalPage} />
          <Route
            path="/secure/client/insurance"
            component={InsurancePage}
          />
          <Route component={Dashboard} />
        </Switch>
      </div>
    )
  }
}
