import { Alert, Form } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { login } from '../../auth'
import { AccessToken } from '../../auth/'
import { requestForgotPasswordEmail } from '../../auth/requestForgotPasswordEmail'
import Button from '../../components/Button'
import FormInput from '../../components/FormInput'
import { State as RootState } from '../../redux/State'
import { getI18nCommon, I18nCommon } from '../../components/index.i18n'

interface StateProps {
}

interface FormProps extends StateProps {
  form: WrappedFormUtils
  i18n: I18nCommon
}

interface State {
  email?: string
  loading?: boolean
  message?: string
  password?: string
}

export class LoginPagePres extends React.Component<FormProps, State> {
  state: State = {}

  async componentDidMount () {
    if (await AccessToken.exists()) {
      window.location.href = '#/secure'
    }
  }

  handleFogotPassword = (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.setState({ message: undefined })
    this.props.form.validateFields(['email'], (err, { email }) => {
      if (!err) {
        requestForgotPasswordEmail(email)
          .then(() => {
            this.setState({ message: `An email has been sent to ${email}` })
          })
          .catch(error => {
            console.error(error)
            this.setState({ message: error.message })
          })
      }
    })
  }

  handleLogin = (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.setState({ message: undefined, loading: true })
    this.props.form.validateFields((err, { email, password }) => {
      if (err) {
        return this.setState({ loading: false })
      }
      login(email, password)
        .then(() => {
          this.setState({ loading: false })
          window.location.href = '#/secure'
        })
        .catch(error => {
          console.error(error)
          this.setState({
            message: error.message,
            loading: false,
          })
        })
    })
  }

  render () {
    const { form, i18n } = this.props
    const { message, loading } = this.state

    const alertType = message?.indexOf('An email has been sent') === 0 ? 'info' : 'error'

    return (
      <Form
        onSubmit={this.handleLogin}
        style={{ maxWidth: 350, margin: 'auto', textAlign: 'center' }}
      >
        <Form.Item><h3>{i18n.LOGIN}</h3></Form.Item>
        <FormInput
          type="email"
          name="email"
          label={i18n.EMAIL_ID}
          form={form}
          decoratorOptions={{
            rules: [
              {
                type: 'email',
                message: i18n.NOT_VALID_EMAIL,
              },
              {
                required: true,
                message: i18n.ENTER_EMAIL,
              },
            ],
          }}
        />
        <FormInput
          type='password'
          name="password"
          label={i18n.PASSWORD}
          form={form}
          decoratorOptions={{
            validateTrigger: 'onSubmit',
            rules: [{
              required: true,
              message: i18n.ENTER_PASSWORD,
            }],
          }}
        />
        {message && (
          <Alert
            message={message}
            type={alertType}
            style={{ margin: '15px 0px' }}
          />
        )}
        <Button
          htmlType="submit"
          disabled={loading}
          style={{ minWidth: '70%', height: 48, marginBottom: 10 }}
        >
          { loading ? i18n.LOADING : i18n.LOGIN_NOW }
        </Button>
        <Button
          onClick={this.handleFogotPassword}
          style={{ minWidth: '70%', height: 48, marginBottom: 10 }}
        >
          {i18n.FORGOT_PASSWORD}
        </Button>
        <div>{i18n.NOT_ACCOUNT}</div>
        <Link to="/public">{i18n.APPLY_NOW}</Link>
      </Form>
    )
  }
}

const LoginForm = Form.create({ name: 'login' })(LoginPagePres)

const mapStateToProps = (state: RootState): { i18n: I18nCommon } => ({ i18n: getI18nCommon(state.language) })

export const LoginPage = connect(mapStateToProps)(LoginForm)
