import { FetchResult } from 'apollo-link'
import gql from 'graphql-tag'
import { getApolloClient, handleGraphQLErrors } from '../../../../../../../getApolloClient'
import { ClientInsuranceUpload } from '../../../../../../../redux/State/Client/Profile/IClientProfile'

export async function postInsuranceCoverage (
  insuranceCoverage: ClientInsuranceUpload,
  accessToken?: string,
  clientId?: string,
): Promise<any> {
  console.log('DA', accessToken, insuranceCoverage)
    type Result = FetchResult<{postInsuranceCoverage: any}>
    return getApolloClient()
      .then(apolloClient =>
        apolloClient.mutate({
          mutation: gql`
          mutation PostInsuranceCoverage($insuranceCoverage: InsuranceCoverage!, $credentials: CredentialsInput!, $clientId: String) {
            postInsuranceCoverage(insuranceCoverage: $insuranceCoverage, credentials: $credentials,  clientId: $clientId) {
              status
              insuranceCoverageUUID
            }
          }`,
          variables: {
            clientId: clientId,
            credentials: {
              accessToken: accessToken,
            },
            insuranceCoverage,
          },
        }),
      )
      .then((result: Result) => {
        if (result.errors) {
          console.error({ result })
          throw result.errors[0]
        }
        if (!result.data) throw new Error('!result.data')
        return result.data.postInsuranceCoverage
      })
      .catch(handleGraphQLErrors)
}
