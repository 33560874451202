import AntButton, { ButtonProps } from 'antd/lib/button'
import React, { CSSProperties } from 'react'
import { BLUE_DEEP, WHITE } from '../../style'

type ButtonTypes = 'rounded-blue' | 'bordered-blue'

type ButtonConfig = {
  [key in ButtonTypes]: { style?: CSSProperties } & ButtonProps;
}
type Props = {
  btnType?: ButtonTypes
  style?: CSSProperties
} & ButtonProps

export const btnTypeConfigs: ButtonConfig = {
  'rounded-blue': {
    shape: 'round',
    style: {
      backgroundColor: BLUE_DEEP,
      color: WHITE,
      borderWidth: 0,
    },
  },
  'bordered-blue': {
    style: {
      color: BLUE_DEEP,
      border: `1.5px solid ${BLUE_DEEP}`,
      borderRadius: '6px',
    },
  },
}

export const Button: React.FC<Props> = ({
  onClick,
  btnType = 'rounded-blue',
  shape = 'round',
  style,
  children,
  ...restProps
}) => {
  const { style: configStyle, ...restConfig } = btnTypeConfigs[btnType]
  const restBtnSettings = { ...restProps, ...restConfig }

  return (
    <AntButton
      onClick={onClick}
      style={{
        height: 54,
        fontSize: 16,
        padding: '0px 30px',
        ...configStyle,
        ...style,
      }}
      {...restBtnSettings}
    >
      {children}
    </AntButton>
  )
}

export default Button
