import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import Button from '../../../../../../components/Button'
import Card from '../../../../../../components/Card'
import { getGenders, getLanguages } from '../../../../../../components/RegAppForm/data'
import { Address } from '../../../../../../redux/State/Address'
import { Beta } from '../../../../../../redux/State/Beta'
import { ClientProfile } from '../../../../../../redux/State/Client/Profile/IClientProfile'
import { Client } from '../../../../../../redux/State/Client/index'
import { State } from '../../../../../../redux/State/index'
import { BLUE_DARK } from '../../../../../../style'
import { I18n, getI18n } from './index.i18n'

interface StateProps {
  beta?: boolean
  canAmend?: boolean
  profile?: ClientProfile
  i18n: I18n
  langProp: 'en-CA'|'fr-CA'
}

export class ProfilePres extends React.Component<StateProps> {
  renderInfoField = (title: string, info?: string | null) => {
    return <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
      <div style={{ width: '30%', maxWidth: 150, marginRight: 5 }}>{title}:</div>
      <div style={{ color: BLUE_DARK }}>{info ?? '—'}</div>
    </div>
  }

  renderBranchValue = (bool: boolean | undefined) => bool
    ? <strong style={{ fontSize: 20, color: BLUE_DARK }}>✓</strong>
    : <strong style={{ fontSize: 20, color: BLUE_DARK }}>&times;</strong>

  renderAdressField = (data: Address, title: string) => {
    return <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
      <div style={{ width: '30%', maxWidth: 150, marginRight: 5 }}>{title}:</div>
      <div style={{ color: BLUE_DARK }}>
        {data.line1}<br />
        {data.line2 && <>{data.line2}<br /></>}
        {data.city}, {data.province}, {data.postalCode}
      </div>
    </div>
  }

  render () {
    const { canAmend, profile, i18n, langProp } = this.props

    if (!profile) return <p>{i18n.LOADING}</p>

    const { gender: g, language: lang, consent = {} } = profile

    const genderItem = getGenders(langProp).find(el => el.value === g)
    const gender = genderItem?.label
    const language = getLanguages(langProp).find(el => el.value === lang)?.label

    return <>
      <Card style={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}>
        {this.renderInfoField(i18n.NAME, `${profile.firstName} ${profile.lastName}`)}
        {this.renderInfoField(i18n.GENDER, gender)}
        {this.renderInfoField(i18n.LANGUAGE, language)}
        {this.renderInfoField(i18n.DATE_OF_BIRTH, profile.dateOfBirth)}
        {this.renderInfoField(i18n.PHONE_NUMBER, profile.telephoneNumber)}
        {this.renderInfoField(i18n.EMAIL, profile.emailAddress)}
        {this.renderInfoField(i18n.K_NUMBER, profile.knumber)}
        <div>
          {i18n.I_HAVE_A_PHYSICIAN_TO_PRESCRIBE}&nbsp;
          {this.renderBranchValue(profile.hasPhysician)}
        </div>
      </Card>
      { profile.insurance.private?.yourPlan?.provider &&
        <Card style={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}>
          {this.renderInfoField(i18n.YOUR_PROVIDER, profile.insurance.private?.yourPlan?.providerName)}
          {this.renderInfoField(i18n.YOUR_POLICY_NUMBER, profile.insurance.private?.yourPlan?.number)}
          {this.renderInfoField(i18n.OTHER_PROVIDER, profile.insurance.private?.otherPlan?.providerName)}
          {this.renderInfoField(i18n.OTHER_POLICY_NUMBER, profile.insurance.private?.otherPlan?.number)}
          <div>
            {i18n.INSURANCE_COVERS_MEDICATION}&nbsp;
            {this.renderBranchValue(profile.insurance.private?.insuranceCoversMedication)}
          </div>
          <div>
            {i18n.INSURANCE_COVERS_CANNABIS}&nbsp;
            {profile.insurance.private?.insuranceCoversCannabis === null ? 'unsure' : this.renderBranchValue(profile.insurance.private?.insuranceCoversCannabis)}
          </div>
        </Card>
      }
      <Card style={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}>
        {this.renderAdressField(profile.physicalAddress, i18n.PHYSICAL_ADDRESS)}
        {this.renderAdressField(profile.mailingAddress || profile.physicalAddress, i18n.MAILING_ADDRESS)}
        {this.renderAdressField(profile.shippingAddress || profile.physicalAddress, i18n.SHIPPING_ADDRESS)}
      </Card>
      <Card style={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}>
        <div style={{ marginBottom: '10px' }}>
          {i18n.NEWS_AND_UPDATES}&nbsp;
          {this.renderBranchValue(!consent.marketing?.value)}
        </div>
        <div style={{ marginBottom: '10px' }}>
          {i18n.PARTICIPATE_IN_RESEARCH}&nbsp;
          {this.renderBranchValue(!consent.research?.value)}
        </div>
        <div>
          {i18n.ACCEPT_POLICY}&nbsp;
          {this.renderBranchValue(consent.privacy?.value)}
        </div>
      </Card>
      {canAmend && <Button onClick={() => { window.location.href = '/#/secure/client/profile-amendment' }} >
        {i18n.AMEND_NAME_OR_ADDRESS}
      </Button>}
      <Button onClick={() => { window.location.href = '/#/secure/client/profile-update' }} >
        {i18n.UPDATE_CONTACT_INFO}
      </Button>
    </>
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (
  state,
  props,
): StateProps => {
  const client = Client.get()
  if (!client.value) return { i18n: getI18n(state.language), langProp: state.language }
  const beta = Beta.is()
  const canAmend = Client.get().map(c => c.canAmend).value ?? false
  const profile = client.value.profile

  return { beta, canAmend, profile, i18n: getI18n(state.language), langProp: state.language }
}

export const Profile = connect(mapStateToProps)(ProfilePres)
