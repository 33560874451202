import { Config } from '../redux/State/Config'
import { requestCookieFromToken } from './requestCookieFromToken'
import { requestTokenFromCookie } from './requestTokenFromCookie'
import { requestTokenFromCreds } from './requestTokenFromCreds'

interface LRTokenResult { accessToken: string|null, expiresIn: string|null }

const localToken = {
  get (): LRTokenResult {
    const expiresIn: string|null = localStorage.getItem('accessTokenExpiry')
    if (expiresIn === null) return { accessToken: null, expiresIn: null }
    if (new Date(expiresIn) <= new Date()) return { accessToken: null, expiresIn: null }
    const accessToken: string|null = localStorage.getItem('accessToken')
    return { accessToken, expiresIn }
  },
  set (accessToken: string, expiresIn: string|null): void {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('accessTokenExpiry', expiresIn ?? new Date(Date.now() + 60 * 60 * 1000).toString())
    // TODO expiry hard coded to 1 hour
  },
  unset (): void {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('accessTokenExpiry')
  },
}

const cookieToken = {
  get (): Promise<string|null> {
    return requestTokenFromCookie()
  },
  set (accessToken: string): Promise<void> {
    return requestCookieFromToken(accessToken)
  },
  async unset (): Promise<void> {
    const lrv2 = await getLRv2()
    return new Promise((resolve, reject) => {
      lrv2.init('logout', { onSuccess: resolve })
    })
  },
}

export const AccessToken = {
  async exists (): Promise<boolean> {
    return !!await AccessToken.get(false)
  },
  async get (redirect?: boolean): Promise<string|null> {
    if (typeof redirect === 'undefined') {
      redirect = window.location.href.includes('secure')
    }
    let { accessToken, expiresIn }: LRTokenResult = localToken.get()
    if (accessToken) return accessToken
    accessToken = await cookieToken.get()
      .catch(e => {
        console.error(e)
        return null
      })
    if (accessToken) {
      localToken.set(accessToken, expiresIn) // TODO resets expiry
      return accessToken
    }
    if (redirect) {
      window.location.href = '#/login'
    }
    return null
  },
  async onExpiration () {
    localToken.unset()
    return AccessToken.get().then(undefined)
  },
}

export const login = async (email: string, password: string) => {
  const { accessToken, expiresIn } = await requestTokenFromCreds(email, password)
  setTimeout(() => { cookieToken.set(accessToken).catch(console.error) }, 0)
  localToken.set(accessToken, expiresIn)
  window.location.href = '#/secure'
}

export const logout = async () => {
  await cookieToken.unset()
  localToken.unset()
  window.location.href = '#/login'
}

export const init = () => {
  const { token, newUrl } =
    parseAccessTokenFromUrl(window.location.href)
  if (newUrl) {
    setTimeout(() => {
      window.history.pushState(null, '', newUrl)
    }, 0)
  }
  if (token) localToken.set(token, null)
}

export const parseAccessTokenFromUrl = (url: string) =>
  parseTokenFromUrl(url, /[?&]token=([a-fA-F0-9-]{36})/)

export const parseResetTokenFromUrl = (url: string) =>
  parseTokenFromUrl(url, /[?&]vtoken=([a-fA-F0-9]{32})/)

export const parseTokenFromUrl = (url: string, regex: RegExp) => {
  // This regex is neccessary because LR route back to a
  //   malformed url by appending the query after the fragment
  const match = url.match(regex)
  if (!match) return {}
  const [match0, token] = match
  const newUrl = url.replace(match0, '')
  return { newUrl, token }
}

async function getLRv2 () {
  const { LR_API_KEY, LR_SITE_NAME } = await Config.promise
  // @ts-ignore
  return new LoginRadiusV2({
    apiKey: LR_API_KEY,
    appName: LR_SITE_NAME,
  })
}
