import './style.less'
import { Alert, Form } from 'antd'
import { ValidationRule, WrappedFormUtils } from 'antd/lib/form/Form'
import React, { SyntheticEvent } from 'react'
import { Maybe } from '../../Maybe'
import { AddressSet, AddressSetBuilder, ShippingAlias } from '../../redux/State/Address'
import { ClientConsentMap } from '../../redux/State/Client/Profile/Consent'
import {
  ClientInsuranceUpload,
  ClientProfileAmendment,
  ClientProfileRenewal,
  ClientProfileUpdate,
  ClientProfileUpload, IInsuranceCoverage,
} from '../../redux/State/Client/Profile/IClientProfile'
import { diffClientAmendment } from '../../redux/State/Client/Profile'
import { Config } from '../../redux/State/Config'
import { InsuranceProvider } from '../../redux/State/InsuranceProvider'
import Button from '../Button'
import Card from '../Card'
import Checkbox from '../Checkbox'
import Collapse from '../Collapsible'
import FormInput from '../FormInput'
import RegAppProgress from '../RegAppProgress'
import { Acknowledgement } from './Acknowledgement'
import { Mode } from './Mode'
import { getGenders, getLanguages, getShippingOptions } from './data'
import { initPCA } from './initPCA'
import {
  getProviderUUIDByName,
  InsuranceType,
  ProfileInsurance,
} from '../../screens/Page/secure/client/LeftColumn/Insurance/InsuranceForm'
import { getI18n, I18n } from './index.i18n'
import { getI18nCommon, I18nCommon } from '../index.i18n'

const formItemLayout = {
  labelCol: {
    span: 24,
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

export type RegFormData
  = ClientProfileAmendment
  | ClientProfileRenewal
  | ClientProfileUpdate
  | ClientProfileUpload

export interface Props {
  beta?: boolean
  handleSave?: (x: RegFormData, insuranceData?: ClientInsuranceUpload) => unknown
  initialMode: 'ro'|'rw'
  initialValue?: RegFormData
  initialInsurance?: IInsuranceCoverage
  insuranceProviders?: InsuranceProvider[]
  mode: Mode
  saving?: boolean
  submitError?: string
  language: 'en-CA'|'fr-CA'
}

export interface FormProps extends Props {
  form: WrappedFormUtils
}

interface State {
  addresses: AddressSetBuilder
  canSubmit?: boolean
  disabled?: boolean
  hrefPrivacy: string
  showMailingAddress?: boolean
  i18n: I18n
  i18nCommon: I18nCommon
}

export class RegAppFormPres extends React.Component<FormProps, State> {
  state: State = {
    addresses: new AddressSetBuilder(),
    hrefPrivacy: '',
    i18n: getI18n(this.props.language),
    i18nCommon: getI18nCommon(this.props.language),
  }

  componentDidMount (): void {
    const { initialMode, insuranceProviders, mode, language } = this.props
    this.setState({ disabled: initialMode === 'ro' })
    this.componentDidUpdate({ initialMode, insuranceProviders, mode, language })
    Config.promise
      .then(({ URL_PRIVACY_POLICY }) => {
        this.setState({ hrefPrivacy: URL_PRIVACY_POLICY })
      })
      .catch(console.log)
    initPCA((error, { type, address }) => {
      if (error) {
        console.error(error)
        throw error
      }
      const { addresses } = this.state
      addresses.setAddress(type as ShippingAlias, address)
      this.setState({ addresses })
    })

    // TODO IMPROVE LOGIC
    setInterval(() => {
      const canSubmit = this.canSubmit()
      this.setState({
        canSubmit,
      })
    }, 1000)
  }

  componentDidUpdate (prev: Props) {
    const { form, initialValue, mode, language } = this.props
    if (!prev.initialValue && initialValue) {
      const addresses = toAddressSetBuilder(initialValue, mode)
      const showMailingAddress = !addresses.isMailingAddressSameAsPhysical()
      this.setState({
        addresses,
        canSubmit: this.canSubmit(),
        showMailingAddress,
      }, () => form.setFieldsValue(fromRegFormData(initialValue, addresses)))
    }

    if (prev.language !== language) {
      this.setState({
        i18n: getI18n(language),
        i18nCommon: getI18nCommon(language),
      })
    }
  }

  handleSubmit = (e: SyntheticEvent) => {
    const { form, handleSave, insuranceProviders, mode } = this.props
    const { addresses } = this.state
    e.preventDefault()
    if (!handleSave) throw new Error('!handleSave')
    if (mode !== Mode.UPDATE) {
      this.resetSearch()
    }
    form.validateFieldsAndScroll((err) => {
      if (err) {
        console.log(err) // TODO
        return
      }
      handleSave(toRegFormData(form.getFieldsValue() as any, addresses, mode), toInsuranceData(form.getFieldsValue() as any, insuranceProviders))
    })
  }

  resetSearch () {
    this.props.form.setFieldsValue({
      'mailingAddress-search': '',
      'physicalAddress-search': '',
    })
    // @ts-ignore
    document.getElementById('mailingAddress-search').value = ''
    // @ts-ignore
    document.getElementById('physicalAddress-search').value = ''
  }

  toggleMailingAddress () {
    const { addresses } = this.state
    addresses.toggleMailingAddressSameAsPhysical()
    this.setState({ addresses, showMailingAddress: !addresses.isMailingAddressSameAsPhysical() })
  }

  canSubmit () {
    const { form, initialValue, mode, saving } = this.props
    const { addresses } = this.state
    if (!addresses.physical) {
      return true
    }
    if (saving) {
      return false
    }
    if (mode !== Mode.AMENDMENT) {
      return true
    }

    const potentialAmendment = toRegFormData(form.getFieldsValue() as any, addresses, mode)
    const initValue = initialValue as ClientProfileAmendment
    const newAmendment = diffClientAmendment(initValue, potentialAmendment as ClientProfileAmendment)

    if (Object.keys(newAmendment).length === 0) {
      return false
    }

    return !!Object.values(newAmendment).filter(x => x).length
  }

  render () {
    const {
      form,
      initialMode,
      initialInsurance,
      insuranceProviders = [],
      mode,
      saving = false,
      submitError,
      language,
    } = this.props
    const {
      addresses,
      canSubmit,
      disabled,
      showMailingAddress,
      i18n,
      i18nCommon,
    } = this.state

    const decOptRequired = {
      rules: [{
        required: true,
        message: i18nCommon.FIELD_REQUIRED,
      }],
    }
    const decOptRequiredTrue = {
      rules: [{
        message: i18nCommon.FIELD_REQUIRED,
        validator: (r: unknown, v: unknown, cb: (b: any) => unknown) => cb(!v || undefined),
      }],
    }
    const decOptRequiredIf = (required: boolean): {rules: ValidationRule[]} => ({
      rules: required ? [{
        required: true,
        message: i18nCommon.FIELD_REQUIRED,
      }] : [],
    })
    const decOptPhone = {
      rules: [{
        validator: (r: unknown, v: string, cb: Function) => {
          const minLen = 10
          if (!v) {
            cb(new Error(i18nCommon.ENTER_PHONE))
            return
          }
          if (v.replace(/-/g, '').length < minLen) {
            cb(new Error(`${i18nCommon.MINIMUM} ${i18nCommon.VALUE_OF_CHARACTERS} ${minLen}`))
            return
          }
          cb()
        },
      }],
    }
    const decOptDob = {
      rules: [{
        validator: (rule: object, value: string, callback: Function) => {
          if (!value) {
            callback(new Error(i18nCommon.FIELD_REQUIRED))
            return
          }
          const r = new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/)
          if (!r.test(value)) {
            callback(new Error(`${i18nCommon.INPUT_CORRECT} ${i18nCommon.DATE_BIRTH}`))
            return
          }
          const enteredDate = new Date(value.replace(/-/g, '/')).setHours(0, 0, 0, 0)
          const now = new Date().setHours(0, 0, 0, 0)
          if (now < enteredDate) {
            callback(new Error(i18nCommon.DATE_NOT_FUTURE))
            return
          }
          const dobYear = +value.split('-')[0]
          if (dobYear < 1900) {
            callback(new Error(`${i18n.MINIMUM_BIRTH} 1900`))
            return
          }
          callback()
        },
      }],
    }
    return (
      <div>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          labelAlign="right"
          style={{ maxWidth: 800, margin: 'auto' }}
          wrapperCol={{ span: 24 }}
        >
          <fieldset disabled={disabled}>
            <Collapse header={i18n.APPLICANT_INFORMATION} className="patient-info">
              { mode !== Mode.UPDATE && <div className="double-input-field">
                <FormInput
                  name="firstName"
                  label={i18n.FIRST_NAME}
                  form={form}
                  maxLength={50}
                  mode={initialMode}
                  decoratorOptions={decOptRequired}
                />
                <FormInput
                  name="lastName"
                  label={i18n.LAST_NAME}
                  form={form}
                  maxLength={50}
                  mode={initialMode}
                  decoratorOptions={decOptRequired}
                />
              </div>}
              {[Mode.APPLICATION, Mode.RENEWAL].includes(mode) && <>
                <FormInput
                  type="radioGroup"
                  name="gender"
                  selectItems={getGenders(language)}
                  form={form}
                  mode={initialMode}
                  decoratorOptions={decOptRequired}
                />
              </>}
              {mode === Mode.APPLICATION && <>
                <FormInput
                  type="select"
                  name="language"
                  label={i18n.LANGUAGE}
                  selectItems={getLanguages(language)}
                  form={form}
                  mode={initialMode}
                  decoratorOptions={decOptRequired}
                />
              </>}
              <div className="double-input-field">
                {[Mode.APPLICATION, Mode.RENEWAL].includes(mode) && <FormInput
                  name="dateOfBirth"
                  label={i18n.DATE_BIRTH}
                  form={form}
                  mode={initialMode}
                  maskType='dob'
                  decoratorOptions={decOptDob}
                />}
                {[Mode.APPLICATION, Mode.UPDATE].includes(mode) && <FormInput
                  name="telephoneNumber"
                  label={i18n.PHONE}
                  form={form}
                  mode={initialMode}
                  maskType='phone'
                  decoratorOptions={decOptPhone}
                />}
              </div>
              {[Mode.APPLICATION, Mode.UPDATE].includes(mode) && <FormInput
                type="email"
                name="emailAddress"
                label={i18n.EMAIL}
                form={form}
                mode={initialMode}
                decoratorOptions={{
                  rules: [
                    {
                      type: 'email',
                      message: i18nCommon.NOT_VALID_EMAIL,
                    },
                    {
                      required: true,
                      message: i18nCommon.ENTER_EMAIL,
                    },
                  ],
                }}
              />}
              {mode === Mode.APPLICATION && <FormInput
                type="checkbox"
                name="hasPhysician"
                label={i18n.HAVE_PHYSICIAN}
                form={form}
                mode={initialMode}
              />}
            </Collapse>
            {mode === Mode.APPLICATION &&
              <Collapse header={i18n.INSURANCE_INFORMATION}>
                <ProfileInsurance form={form} initialMode="rw" insuranceProviders={insuranceProviders}/>
              </Collapse>
            }
            {mode === Mode.RENEWAL &&
              <Collapse header={i18n.INSURANCE_INFORMATION}>
                <ProfileInsurance form={form} initialMode="rw" insuranceProviders={insuranceProviders}
                  initialValue={initialInsurance}
                  typeReadOnly={initialInsurance?.insuranceType === InsuranceType.VAC || initialInsurance?.insuranceType === InsuranceType.GROUP_PLAN}
                  formReadOnly={ { [InsuranceType.GROUP_PLAN]: false, [InsuranceType.VAC]: true, [InsuranceType.NONE]: false } }
                />
              </Collapse>
            }
            {mode !== Mode.UPDATE && <>
              <Collapse header={i18n.RESIDENTIAL_ADDRESS} className="physicalAddress">
                <p>{i18nCommon.PHYSICAL_ADDRESS_EXPLAINED}</p>
                <FormInput
                  decoratorOptions={decOptRequiredIf(!addresses?.physical)}
                  form={form}
                  label={i18nCommon.SEARCH}
                  mode={initialMode}
                  name="physicalAddress-search"
                />
                {addresses.physical && <>
                  <p>{addresses.physical.line1}</p>
                  <p>{addresses.physical.line2}</p>
                  <p>{addresses.physical.city}</p>
                  <p>{addresses.physical.province} {addresses.physical.postalCode}</p>
                </>}
                <Checkbox
                  checked={!showMailingAddress}
                  disabled={!addresses}
                  onChange={() => this.toggleMailingAddress()}
                >{i18n.MAILING_ADDRESS_SAME}</Checkbox>
              </Collapse>
              <div style={{ display: showMailingAddress ? 'block' : 'none' }}>
                <Collapse header={i18n.MAILING_ADDRESS} className="mailingAddress">
                  <p>{i18nCommon.MAILING_ADDRESS_EXPLAINED}</p>
                  <FormInput
                    decoratorOptions={decOptRequiredIf(!!showMailingAddress && !addresses.mailing)}
                    form={form}
                    label={i18nCommon.SEARCH}
                    mode={initialMode}
                    name="mailingAddress-search"
                  />
                  {addresses.mailing && <>
                    <p>{addresses.mailing.line1}</p>
                    <p>{addresses.mailing.line2}</p>
                    <p>{addresses.mailing.city}</p>
                    <p>{addresses.mailing.province} {addresses.mailing.postalCode}</p>
                  </>}
                </Collapse>
              </div>
              <div style={{ display: getShippingOptions(addresses).length > 1 ? 'block' : 'none' }}>
                <Collapse header={i18n.SHIPPING_ADDRESS}>
                  <p>{i18nCommon.SHIPPING_ADDRESS_EXPLAINED}</p>
                  <FormInput
                    type="select"
                    name="shipping"
                    label={i18n.SHIP_TO}
                    selectItems={getShippingOptions(addresses, language)}
                    form={form}
                    mode={initialMode}
                    decoratorOptions={decOptRequiredIf(!!showMailingAddress)}
                    onSelectAnt={(key: ShippingAlias) => {
                      addresses.setShippingAddressSameAs(key)
                      this.setState({ addresses })
                    }}
                  />
                  {addresses.shipping && <>
                    <p>{addresses.shipping.line1}</p>
                    <p>{addresses.shipping.line2}</p>
                    <p>{addresses.shipping.city}</p>
                    <p>{addresses.shipping.province} {addresses.shipping.postalCode}</p>
                  </>}
                </Collapse>
              </div>
            </>}
            {[Mode.APPLICATION, Mode.RENEWAL, Mode.UPDATE].includes(mode) && <Collapse header={i18n.ACKNOWLEDGEMENT}>
              {[Mode.APPLICATION, Mode.RENEWAL].includes(mode) && <>
                <Acknowledgement i18n={i18n}/>
                <FormInput
                  type="checkbox"
                  name="acknowledgement"
                  label={i18n.CORRECT_COMPLETE}
                  form={form}
                  mode={initialMode}
                  decoratorOptions={decOptRequiredTrue}
                />
              </>}
              {[Mode.APPLICATION, Mode.UPDATE, Mode.RENEWAL].includes(mode) && <>
                <FormInput
                  type="checkbox"
                  name="consentMarketing"
                  label={i18n.OFFER_SPECIAL_PROMOTIONS}
                  form={form}
                  mode={initialMode}
                />
                <FormInput
                  type="checkbox"
                  name="consentResearch"
                  label={i18n.PARTICIPATE_IN_RESEARCH}
                  form={form}
                  mode={initialMode}
                />
              </>}
              {[Mode.APPLICATION, Mode.UPDATE].includes(mode) && <FormInput
                type="checkbox"
                name="consentPrivacy"
                label={<>
                  {i18n.ACCEPT} <a
                    href={this.state.hrefPrivacy}
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target='_blank'
                    rel='nofollow noopener'
                  >
                    {i18n.AURORA_PRIVACY_POLICY}
                  </a>
                </>}
                form={form}
                mode={initialMode}
                decoratorOptions={decOptRequiredTrue}
              />}
            </Collapse>}

            {mode === Mode.AMENDMENT && <Card style={{ flexDirection: 'column' }}>
              <p>{i18n.AMENDMENTS_SUSPEND}</p>
            </Card>}
            {submitError && (
              <Alert
                message={submitError}
                type="error"
                showIcon
                style={{ margin: '15px 0px' }}
              />
            )}
            <RegAppProgress loading={saving} />
            <Form.Item>
              <Button
                btnType="rounded-blue"
                htmlType="submit"
                onClick={() => {
                  canSubmit && this.setState({
                    canSubmit: false,
                  })
                }}
                disabled={!this.canSubmit()}
                style={{
                  height: '46px',
                  fontFamily: 'Polaris, sans-serif',
                }}
              >
                {{
                  [Mode.AMENDMENT]: i18nCommon.SUBMIT,
                  [Mode.APPLICATION]: i18nCommon.APPLY,
                  [Mode.RENEWAL]: i18nCommon.RENEW,
                  [Mode.UPDATE]: i18nCommon.UPDATE,
                }[mode]}
              </Button>
              <Button
                btnType="rounded-blue"
                onClick={() => window.history.back()}
                style={{
                  height: '46px',
                  fontFamily: 'Polaris, sans-serif',
                  marginLeft: '22px',
                }}
              >
                {i18nCommon.CANCEL}
              </Button>
            </Form.Item>
          </fieldset>
        </Form>
      </div>
    )
  }
}

export const RegAppForm: React.ComponentClass<Props> = Form.create<FormProps>({ name: 'application' })(RegAppFormPres)

interface FormOutputValues extends IInsuranceCoverage {
  acknowledgement: boolean
  dateOfBirth: string
  emailAddress: string
  firstName: string
  gender: 'female'|'male'|'other'|'undisclosed'
  hasPhysician?: boolean
  language: 'en-CA'|'fr-CA'
  lastName: string
  telephoneNumber: string
}

function toClientAmendment (values: FormOutputValues, addresses: AddressSet): ClientProfileAmendment {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    physicalAddress: addresses.physical,
    mailingAddress: addresses.mailing,
    shippingAlias: addresses.shippingAlias,
  }
}

function toClientUpdate (values: FormOutputValues): ClientProfileUpdate {
  return {
    consent: toClientConsentMap(values),
    emailAddress: values.emailAddress,
    telephoneNumber: values.telephoneNumber,
  }
}

function toClientRenewal (values: FormOutputValues, addresses: AddressSet): ClientProfileRenewal {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    acknowledgement: values.acknowledgement,
    dateOfBirth: values.dateOfBirth,
    gender: values.gender,
    physicalAddress: addresses.physical,
    mailingAddress: addresses.mailing,
    shippingAddress: addresses.shipping,
    consent: toClientConsentMap(values),
  }
}

function toClientUpload (values: FormOutputValues, addresses: AddressSet): ClientProfileUpload {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    acknowledgement: values.acknowledgement,
    consent: toClientConsentMap(values),
    dateOfBirth: values.dateOfBirth,
    emailAddress: values.emailAddress,
    gender: values.gender,
    hasPhysician: !!values.hasPhysician,
    knumber: values.kNumber ?? undefined,
    language: values.language,
    telephoneNumber: values.telephoneNumber,
    physicalAddress: addresses.physical,
    mailingAddress: addresses.mailing,
    shippingAddress: addresses.shipping,
  }
}

function toRegFormData (values: FormOutputValues, addresses: AddressSetBuilder, mode: Mode): RegFormData {
  if (mode === Mode.UPDATE) {
    return toClientUpdate(values)
  }
  if (!addresses) {
    throw new Error('!addresses')
  }
  return {
    [Mode.AMENDMENT]: toClientAmendment,
    [Mode.APPLICATION]: toClientUpload,
    [Mode.RENEWAL]: toClientRenewal,
    [Mode.UPDATE]: toClientUpdate,
  }[mode](values, addresses.toAddressSet())
}

export function toInsuranceData (values: FormOutputValues, insuranceProviders?: InsuranceProvider[]): ClientInsuranceUpload | undefined {
  if (values.insuranceType !== InsuranceType.GROUP_PLAN) {
    return
  }

  const insuranceProviderUUID = getProviderUUIDByName(insuranceProviders, values.insuranceProviderName)
  return {
    insuranceProviderUUID: insuranceProviderUUID ?? '',
    groupPlanNumber: values.groupPlanNumber ?? '',
    certificateNumber: values.certificateNumber ?? '',
    cardholderId: values.cardholderId ?? '',
    relationshipToPolicyHolder: values.relationshipToPolicyHolder ?? '',
    priorAuthorizationRequired: false, // TODO Prior Authorization
  }
}

function fromRegFormData (rfd: RegFormData, addresses: AddressSetBuilder) {
  const {
    consent,
    physicalAddress,
    mailingAddress,
    shippingAddress,
    ...values
  } = rfd as ClientProfileUpload
  return {
    shipping: addresses.getShippingAlias(),
    ...values,
    ...fromClientConsentMap(consent),
  }
}

function toAddressSetBuilder (data: RegFormData, mode: Mode): AddressSetBuilder {
  const { physicalAddress, mailingAddress, shippingAddress } = data as ClientProfileAmendment
  const asb = new AddressSetBuilder(physicalAddress, mailingAddress, shippingAddress)
  if (mode === Mode.RENEWAL && asb.practitioner) {
    delete asb.shipping
    delete asb.practitioner
  }
  return asb
}

function toClientConsentMap (values: any): ClientConsentMap {
  return {
    marketing: { value: values.consentMarketing == null || !values.consentMarketing },
    privacy: { value: values.consentPrivacy ?? false },
    research: { value: values.consentResearch == null || !values.consentResearch },
  }
}

function fromClientConsentMap (consent: Maybe<ClientConsentMap>): any {
  if (!consent) return {}
  return {
    consentMarketing: !consent.marketing?.value,
    consentPrivacy: consent.privacy?.value,
    consentResearch: !consent.research?.value,
  }
}
