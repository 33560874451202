import { FetchResult } from 'apollo-link'
import gql from 'graphql-tag'
import { getApolloClient, handleGraphQLErrors } from '../../../../../getApolloClient'

export async function requestClients (accessToken: string, firstName: string, lastName: string, dateOfBirth: string): Promise<any[]> {
    type Result = FetchResult<{matchClientsV2: any[]}>
    return getApolloClient()
      .then(apolloClient =>
        apolloClient.query({
          query: gql`
             query ($accessToken: String!, $firstName: String!, $lastName: String!, $dateOfBirth: String!){
                matchClientsV2(
                    credentials: {
                        accessToken:$accessToken
                    },
                    pattern: {
                        firstName: $firstName,
                        lastName: $lastName,
                        dateOfBirth: $dateOfBirth,
                    }
                ) 
                { 
                    clientId, 
                    emailAddress, 
                    firstName 
                }
            }`,
          variables: { accessToken, firstName, lastName, dateOfBirth },
        }),
      )
      .then((result: Result) => {
        if (result.errors) {
          console.error({ result })
          throw result.errors[0]
        }
        if (!result.data) throw new Error('!result.data')
        return result.data.matchClientsV2
      })
      .catch(handleGraphQLErrors)
}
