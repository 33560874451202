import { Location } from 'history'
import React from 'react'

interface Props {
  items?: string[]
  location?: Location
}

export const Breadcrumb: React.FC<Props> = props => {
  let { items = [], location } = props
  if (location) {
    items = location.pathname.split('/').filter(el => el !== '')
  }
  return (
    <div style={{ cursor: 'default' }}>
      {items.join(' / ')}
    </div>
  )
}

export default Breadcrumb
