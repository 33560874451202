import React from 'react'
import { connect } from 'react-redux'
import Button from '../../../../components/Button'
import Cta from '../../../../components/Cta'
import CtaTwoCol from '../../../../components/CtaTwoCol'
import { Config } from '../../../../redux/State/Config'
import ReceivedApplicationCard from './ReceivedApplicationCard'

interface StateProps {
  shopifyUrl?: string
}

export class PagePres extends React.Component<StateProps> {
  onDownloadForms = () => {
    window.location.href = 'assets/CAF-001B.07_Medical Document_EN_FILLABLE.pdf'
  }

  onContactPress = () => {
    const { shopifyUrl } = this.props
    if (shopifyUrl) {
      window.location.href = shopifyUrl + '/pages/our-contacts'
    }
  }

  onBook = () => {
    window.location.href = 'https://cannabisclinics.adracare.com/online-intake?oid=2a30c340-af6f-4f7b-8046-016a641b932d&utm_source=aurora&utm_medium=referral&utm_campaign=aurora_page_after_registration_book_link'
  }

  onAboutClicked = () => {
    window.location.href = 'https://www.cannabisclinics.ca/appointment/virtual?utm_source=aurora&utm_medium=referral&utm_campaign=aurora_page_after_registration_about_us_link'
  }

  render () {
    return (
      <ReceivedApplicationCard
        title="We’ve received your application!"
        topic="Just a few more steps to go. Before you can place an order, you will need to submit a completed medical document from a healthcare practitioner.">
        <div style={{ marginTop: '40px' }}>
          <Cta
            title="Interested in an Online Consultation?"
            copy="We have partnered with Canadian Cannabis Clinics to offer our patients a service called Virtual Care. Through a free online consultation, a qualified healthcare practitioner can determine if medical cannabis is right for you and fill out your medical document. It’s quick and easy to use."
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Button onClick={this.onBook} style={{ marginBottom: 10 }}>
              Book an Appointment Today
            </Button>
            <Button onClick={this.onAboutClicked}>
              About Canadian Cannabis Clinics
            </Button>
          </div>
        </div>
        <CtaTwoCol
          items={[
            {
              title: 'Do you already have a healthcare practitioner?',
              copy: 'Download our form and then have your healthcare practitioner fill out this medical document and fax it to 1-403-637-3121.',
              btnText: 'Download Form',
              onClick: this.onDownloadForms,
            },
            {
              title: 'Need help finding a healthcare practitioner?',
              copy: 'If you would like to get information about cannabis-educated practitioners in your area, our Client Care Team is happy to support you. Email us for a list of cannabis clinics near you at askus@auroramedical.com.',
              btnText: 'Contact Us',
              onClick: this.onContactPress,
            },
          ]}
        />
      </ReceivedApplicationCard>
    )
  }
}

export const ClientRegistrationAppliedPage = connect(() => ({
  shopifyUrl: Config.get().value?.URL_SHOPIFY,
}))(PagePres)
