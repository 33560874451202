/* eslint-disable react/jsx-no-target-blank */
import './style.less'
import { Col, Row } from 'antd'
import React, { CSSProperties, ReactElement } from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { State } from '../../../redux/State'
import { Config } from '../../../redux/State/Config'
import { BLUE_LIGHT } from '../../../style'
import { I18n, getI18n } from './FooterSiteMap.i18n'
import fbIcon from './icon-facebook.png'
import instIcon from './icon-instagram.png'
import lnIcon from './icon-linkedin.png'

interface StateProps {
  shopifyUrl: string
  i18n: I18n
}

const itemStyle: CSSProperties = { lineHeight: '27px', fontWeight: 500 }
const itemMarginStyle: CSSProperties = { marginTop: 18, ...itemStyle }
const sectionColProps = {
  span: 24,
  lg: 12,
  className: 'footer-block centered-mobile-block',
  style: { marginBottom: 60, padding: '0px 4px' },
}

export class FooterSiteMapPres extends React.Component<StateProps> {
  wrapBlock = (content: ReactElement, style: CSSProperties | undefined = {}) =>
    style ? <div style={style}>{content}</div> : content

  render (): ReactElement {
    const { shopifyUrl, i18n } = this.props
    const emptyLink = shopifyUrl ? null : '/'
    return (
      <div
        style={{ paddingTop: 100, color: BLUE_LIGHT }}
        className="layout-main-box layout-footer-map-box"
      >
        <Row>
          <Col span={24} lg={12}>
            <Row>
              <Col {...sectionColProps}>
                <h2>{i18n.PATIENTS_HEADER.toUpperCase()}</h2>
                <div style={itemStyle}>
                  <a href={emptyLink ?? `${shopifyUrl}/blogs/news/compassionate-pricing`}>{i18n.COMPASSIONATE_PRICING}</a>
                </div>
                <div style={itemMarginStyle}>
                  <a href={emptyLink ?? `${shopifyUrl}/blogs/news/veterans-coverage`}>{i18n.VETERAN_COVERAGE}</a>
                </div>
              </Col>
              <Col {...sectionColProps}>
                <h2>{i18n.BRANDS_HEADER.toUpperCase()}</h2>
                <div style={itemStyle}>
                  <a href={emptyLink ?? `${shopifyUrl}/pages/why-aurora`}>{i18n.OUR_STORY}</a>
                </div>
                <div style={itemMarginStyle}>
                  <a href="https://investor.auroramj.com">{i18n.FOR_INVESTORS}</a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col {...sectionColProps}>
                <h2>{i18n.LEARN_HEADER.toUpperCase()}</h2>
                <div style={itemStyle}>
                  <a href={emptyLink ?? `${shopifyUrl}/blogs/news/how-does-medical-cannabis-work`}>{i18n.CANNABIS_101}</a>
                </div>
                <div style={itemMarginStyle}>
                  <a href={emptyLink ?? `${shopifyUrl}/pages/blog`}>{i18n.BLOG}</a>
                </div>
                <div style={itemMarginStyle}>
                  <a href={emptyLink ?? `${shopifyUrl}/pages/frequently-asked-questions`}>{i18n.FAQ}</a>
                </div>
              </Col>
              <Col {...sectionColProps}>
                <h2>{i18n.CONTACT_HEADER.toUpperCase()}</h2>
                <div>
                  <a href={`mailto:${i18n.CONTACT_EMAIL}`}>{i18n.CONTACT_EMAIL}</a>
                </div>
                <div style={itemMarginStyle}>
                  <a href={`tel:${i18n.CONTACT_PHONE}`}>{i18n.CONTACT_PHONE}</a>
                </div>
                <div style={itemMarginStyle}>
                  <a
                    href="https://www.instagram.com/aurora.medical/"
                    target='_blank'
                    rel='nofollow noopener'
                    className="social-icon"
                  >
                    <img src={instIcon} alt="Instagram" style={{ height: 20 }} />
                  </a>
                  <a
                    href="https://www.facebook.com/auroramedical.ca/"
                    target='_blank'
                    rel='nofollow noopener'
                    className="social-icon"
                  >
                    <img src={fbIcon} alt="Facebook" style={{ height: 20 }} />
                  </a>
                  <a
                    href="https://ca.linkedin.com/company/aurora-cannabis-inc-"
                    target='_blank'
                    rel='nofollow noopener'
                    className="social-icon"
                  >
                    <img src={lnIcon} alt="Linkedin" style={{ height: 20 }} />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row>
              <Col
                span={24}
                lg={12}
                md={{ span: 18, pull: 3, push: 3 }}
                sm={{ span: 20, pull: 2, push: 2 }}
                className="footer-block centered-mobile-block"
                style={{ fontSize: '16px', lineHeight: '24px', marginBottom: 20 }}
              >
                <small>{i18n.DISCLAIMER}</small>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

interface Props {}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const shopifyUrl = Config.get().value?.URL_SHOPIFY ?? ''
  return { shopifyUrl, i18n: getI18n(state.language) }
}

export const FooterSiteMap = connect(mapStateToProps)(FooterSiteMapPres)
