import './style.less'
import { Tooltip } from 'antd'
import React, { CSSProperties } from 'react'
import { WHITE } from '../../style'

export interface CtaItem {
  label?: string
  onClick?: () => void
  icon?: string
  toolTipText?: string
}

interface Props extends CtaItem {
  style?: CSSProperties
  titleStyle?: CSSProperties
}

export const HelpPromptTooltip: React.FC<Props> = ({
  label,
  onClick,
  toolTipText,
  icon,
  style = {},
  titleStyle = {},
}) => {
  return (
    <div
      style={{
        display: 'block',
        flexDirection: 'row',
        backgroundColor: WHITE,
        ...style,
      }}
    >
      <label>{label}</label>

      <Tooltip title={toolTipText} trigger={'click'}>
        {toolTipText && <img src='/assets/help_prompt_icon.png' alt ="" width='20px' height = '20px' className='help-image'></img>}
      </Tooltip>
    </div>
  )
}

export default HelpPromptTooltip
