import { Action } from '../Action'
import * as registry from '../registry'
import { store } from '../store'
import { State } from './index'

export type Language = 'en-CA'|'fr-CA'

export const Language = {
  get: () => store.getState().language,
  set: (language: Language) => store.dispatch({ type: TYPE, language }),
}

const TYPE = 'LANGUAGE'

interface LanguageAction extends Action {
  readonly type: typeof TYPE
  readonly language: Language
}

const isLanguageAction = (x: any): x is LanguageAction =>
  x.type === TYPE

const reduce = (state: State, action: Action): State|undefined => {
  if (!isLanguageAction(action)) return
  return {
    ...state,
    language: action.language,
  }
}

registry.register(reduce)
