import './style.less'
import { Progress } from 'antd'
import React, { useEffect, useState } from 'react'
import { BLACK, BLUE } from '../../../../../../../style'

interface IProps {
  value: number
  maxValue: number
  animating?: boolean
  animatingTime?: number
  style?: React.CSSProperties
}

const PrescriptionProgress: React.FC<IProps> = props => {
  const { value, maxValue, animating = false, animatingTime = 1500, style = {} } = props
  const [currentVal, setCurrentVal] = useState(0)
  const animRate = 120 // interval (ms) for increasing value during animation

  useEffect(() => {
    let animation: any
    if (animating) {
      const diffVal = Math.round((value * animRate) / animatingTime)
      const clearAnimation = () => {
        clearInterval(animation)
        animation = null
      }

      animation = setInterval(() => {
        setCurrentVal(prevState => {
          const val = prevState + diffVal
          if (val > value) {
            clearAnimation()
            return value
          }
          return val
        })
      }, animRate)

      // clean function without resetting state (without setting currentVal to 0)
      return clearAnimation
    } else {
      setCurrentVal(value)
    }
  }, [animating, animatingTime, value])

  const percents = (currentVal / maxValue) * 100

  return (
    <Progress
      type="circle"
      strokeWidth={8}
      strokeColor={BLUE}
      percent={percents}
      className="prescription-progress"
      style={style}
      format={() => {
        return (
          <>
            <div style={{ color: BLACK }}>{currentVal}</div>
            <div
              style={{
                fontSize: 15,
                color: BLACK,
                marginTop: 5,
              }}
            >
              grams
            </div>
          </>
        )
      }}
    />
  )
}

export default PrescriptionProgress
