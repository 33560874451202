import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CaregiverClientsPage } from './Clients/index'
import { CaregiverProfilePage } from './Profile/index'
import { CaregiverProfileCard } from '../../../../components/UserProfileCard'

export class CaregiverPage extends React.Component {
  render () {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}>
        <div style={{ flex: 1, padding: '1em' }}>
          <Switch>
            <Route path="*/caregiver/profile" component={CaregiverProfilePage} />
            <Route path="*/caregiver/clients" component={CaregiverClientsPage} />
            <Route component={CaregiverClientsPage} />
          </Switch>
        </div>
        <div style={{ maxWidth: '30em', padding: '1em' }}>
          <CaregiverProfileCard />
        </div>
      </div>

    )
  }
}
