import { Config } from './redux/State/Config'

export function initGtag () {
  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  function gtag (...args: any[]) {
    // @ts-ignore
    dataLayer.push(arguments)
  }
  // @ts-ignore
  window.gtag = gtag
  gtag('js', new Date())
  function sendPageView () {
    Config.promise.then(({ GA_UA_IDS }) => {
      GA_UA_IDS.forEach((id) => {
        gtag('config', id, { page_path: window.location.hash.slice(1) })
      })
    }).catch(console.error)
  }
  sendPageView()
  window.addEventListener('hashchange', sendPageView, false)
}
