import { ApolloQueryResult } from 'apollo-client'
import gql from 'graphql-tag'
import { getApolloClient, handleGraphQLErrors } from '../../getApolloClient'
import { Action } from '../Action'
import { SyncPromise } from '../SyncPromise'
import * as registry from '../registry'
import { store } from '../store'
import { State } from './'

export interface InsuranceProvider {
  id: string
  name: string
}

const TYPE = 'INSURANCE_PROVIDERS'

export const InsuranceProvider = {
  get (): SyncPromise<InsuranceProvider[]> {
    const { insuranceProviders } = store.getState()
    if (!insuranceProviders.isUninitiated()) return insuranceProviders
    download().catch(console.error)
    return SyncPromise.LOADING
  },
}

const download = async function (): Promise<void> {
  type Result = ApolloQueryResult<{getInsuranceProviders: InsuranceProvider[]}>
  dispatchInsuranceProvider(SyncPromise.LOADING)
  const promise = getApolloClient()
    .then(apolloClient => {
      return apolloClient.query({
        query: gql`
          query {
            getInsuranceProviders {
              id
              name
            }
          }
        `,
      })
    })
    .then(result => {
      if (result.errors) {
        console.error(result)
        throw result.errors[0]
      }
      return result.data.getInsuranceProviders
    })
    .catch(handleGraphQLErrors)
  SyncPromise.fromPromise(promise, dispatchInsuranceProvider)
}

interface InsuranceProviderAction extends Action {
  readonly type: typeof TYPE
  readonly value: SyncPromise<InsuranceProvider[]>
}

function dispatchInsuranceProvider (value: SyncPromise<InsuranceProvider[]>) {
  store.dispatch({ type: TYPE, value })
}

const isInsuranceProviderAction = (x: any): x is InsuranceProviderAction =>
  x.type === TYPE

const reduce = (state: State, action: Action): State|undefined => {
  if (!isInsuranceProviderAction(action)) return
  const insuranceProviders: SyncPromise<InsuranceProvider[]> = action.value
  return {
    ...state,
    insuranceProviders,
  }
}

registry.register(reduce)
