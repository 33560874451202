import { Card } from 'antd'
import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { Maybe } from '../../../../../../Maybe'
import { Button } from '../../../../../../components/Button'
import Checkbox from '../../../../../../components/Checkbox'
import { ClientProfile } from '../../../../../../redux/State/Client/Profile'
import {
  ClientConsentMap,
  Consent,
} from '../../../../../../redux/State/Client/Profile/Consent'
import { Config } from '../../../../../../redux/State/Config'
import { State } from '../../../../../../redux/State/index'
import { SyncPromise } from '../../../../../../redux/SyncPromise'
import { getI18n, I18n } from './index.i18n'

interface StateProps {
  i18n: I18n
  consent: SyncPromise<ClientConsentMap>
  href: string
}

export class PrivacyPres extends React.Component<StateProps> {
  state = { saving: false }

  setPrivacyConsent = (value: boolean) => {
    this.setState({ saving: true })
    Consent.setPrivacy(value)
      .then(() => {
        this.setState({ saving: false })
      })
      .catch(error => {
        console.error(error)
        alert('ERROR')
      })
  }

  renderCheckbox = ({ value, date }: Consent, i18n: I18n) => (
    <div>
      <Checkbox
        checked={value}
        onChange={() => this.setPrivacyConsent(!value)}
        style={{ direction: 'rtl' }}
      >{i18n.ACCEPT_AURORA_POLICY}</Checkbox>
      {date && <p>Date: {date}</p>}
    </div>
  )

  renderStatus (privacy: Maybe<Consent>, i18n: I18n) {
    if (typeof (privacy?.value) !== 'boolean') {
      return <p>{i18n.PRIVACY_POLICY_TO_BE_ACCEPTED}</p>
    }
    if (privacy.value) {
      return <p>{i18n.PRIVACY_POLICY_ACCEPTED}</p>
    } else {
      return <p>{i18n.PRIVACY_POLICY_DECLINED}</p>
    }
  }

  renderButton (privacy: Maybe<Consent>, i18n: I18n) {
    const onClick = () => this.setPrivacyConsent(!privacy?.value)
    if (privacy?.value) {
      return <Button onClick={onClick} type="ghost">{i18n.PRIVACY_POLICY_NO_LONGER_ACCEPTED}</Button>
    } else {
      return <Button onClick={onClick}>{i18n.ACCEPT_THIS_POLICY}</Button>
    }
  }

  render () {
    const { i18n, consent, href } = this.props
    const { saving } = this.state

    return (
      <Card>
        <h1>{i18n.MY_PRIVACY}</h1>
        <p>
          {i18n.AURORA_SAFEGUARD}
        </p><p>
          {i18n.PRIVACY_POLICY_INFO}
        </p><p>
          {i18n.PRIVACY_POLICY_MORE_INFO}<a href={href}>{i18n.PRIVACY_POLICY}</a>.
        </p><p>
          {i18n.ACCEPT_PRIVACY_POLICY}
        </p>
        { saving
          ? <p>saving...</p>
          : consent.map(c => c.privacy).switchThen(
            <p>ERROR</p>,
            <p>loading...</p>,
            (p) => <>
              {this.renderStatus(p, i18n)}
              {this.renderButton(p, i18n)}
            </>,
          )
        }
      </Card>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (
  state,
  props,
): StateProps => {
  const consent = ClientProfile.get().map(p => p.consent)
  const href = Config.get().value?.URL_PRIVACY_POLICY ?? '/'
  return { i18n: getI18n(state.language), consent, href }
}

export const Privacy = connect(mapStateToProps)(PrivacyPres)
