import './style.less'
import React, { useEffect, useRef, useState } from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { I18n, getI18n } from './index.i18n'
import { State } from '../../redux/State'
import { User } from '../../redux/State/User'
import { NavItem, getContactLink, getNavItems } from './getNavItems'
import { NavBarComponent } from '../NavBar'
import { SyncPromise } from '../../redux/SyncPromise'
import { logout } from '../../auth'
import { Language } from '../../redux/State/Language'
import { Beta } from '../../redux/State/Beta'
import { Config } from '../../redux/State/Config'
import { DateTime } from 'luxon'

interface StateProps {
  beta: boolean
  i18n: I18n
  user: SyncPromise<User>
  showBeta: boolean
}

interface OwnProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps

const SIDE_NAV_BREAKPOINT = 900
export const HeaderPres: React.FC<Props> = ({ i18n, user, showBeta }) => {
  const language = Language.get()
  // 'isSideNav' is used in styles to know whether screen width less than breakpoint value or not
  const [isSideNav, setIsSideNav] = useState(
    window.innerWidth <= SIDE_NAV_BREAKPOINT,
  )
  const [, setIsSideNavOpened] = useState(false) // for side navigation menu
  const [navItems, setNavItems] = useState<NavItem[]>()
  const [contactLink, setContactLink] = useState('')
  const [expiresInText, setExpiresInText] = useState('')

  if (!navItems) getNavItems().then(setNavItems).catch(console.log)
  if (!contactLink) getContactLink().then(setContactLink).catch(console.log)

  const sideNavStateRef = useRef(isSideNav)
  const setSideNavState = (isSide: boolean) => {
    sideNavStateRef.current = isSide
    setIsSideNav(isSide)
  }

  const onResize = () => {
    const isSideMenu = window.innerWidth <= SIDE_NAV_BREAKPOINT
    if (sideNavStateRef.current !== isSideMenu) {
      setSideNavState(isSideMenu)
      setIsSideNavOpened(false)
    }
  }

  useEffect(() => {
    const expiresIn = localStorage.getItem('accessTokenExpiry')
    if (expiresIn) {
      const date = DateTime.fromISO(expiresIn)
      const locale = language === 'fr-CA' ? 'fr' : 'en'
      setExpiresInText(date.setLocale(locale).toRelative() as string)
    }
  }, [language])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  })

  const onLogout = () => {
    logout()
      .then(User.clearCache)
      .catch(console.error)
  }

  return (
    <header className="header-outer">
      <div className="header-top-bar-desktop">
        <div className="container">
          <div className="left-side"></div>
          <div className="right-side">
            <span>
              { user.value?.id &&
                <span><span className="customer-name">{ user.value?.client?.profile.firstName } { user.value?.client?.profile.lastName }</span> | <span
                  className="logout-link"
                  title={user.value?.id ? i18n.EXPIRES_IN + expiresInText : 'Logout' }
                  aria-hidden="true"
                  onClick={onLogout}>{i18n.LOGOUT}</span></span>}
              { !user.value?.id &&
                <form className="header-top-bar-menu-account-form">
                  <li className="header-top-bar-list-item register-link-wrap">
                    <Link to="/public"
                      className="account-link header-top-bar-list-item-text"
                      title="Register"
                      aria-hidden="true">Register</Link>
                  </li>
                </form>}
              <li className="header-top-bar-list-item language-select">
                <span className="header-top-bar-list-item-text" style={{ display: 'inline-block', cursor: 'pointer' }}>
                  {language === 'en-CA' && <span onClick={() => Language.set('fr-CA')}>Français</span>}
                  {language === 'fr-CA' && <span onClick={() => Language.set('en-CA')}>English</span>}
                </span>
              </li>
              {showBeta &&
                <li className="header-top-bar-list-item language-select">
                  <span className="header-top-bar-list-item-text" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={Beta.toggle}>{i18n.TOGGLE_BETA}</span>
                </li>
              }
            </span>
          </div>
        </div>
      </div>
      <div id="navbar-react">
        <NavBarComponent />
      </div>
    </header>
  )
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state,
  props,
): StateProps => {
  const { user } = state
  return {
    beta: Beta.is(),
    i18n: getI18n(state.language),
    user,
    showBeta: Config.get().value?.SHOW_BETA ?? false,
  }
}

export const HeaderContent = connect(mapStateToProps)(HeaderPres)
