export interface LRError {
  Description: "The user's account does not exist, please use a valid user or create the user before processing this request."
  ErrorCode: 938
  IsProviderError: false
  Message: 'User does not exist'
  ProviderErrorResponse: null
}

export const LRError = {
  is (x: any): x is LRError {
    return !!x.ErrorCode
  },
}
