import gql from 'graphql-tag'
import { getApolloClient, handleGraphQLErrors } from '../../getApolloClient'
import { Action } from '../Action'
import { SyncPromise } from '../SyncPromise'
import * as registry from '../registry'
import { store } from '../store'
import { State } from './'
import { ClientInsuranceCoverage, IInsuranceCoverage } from './Client/Profile/IClientProfile'
import { FetchResult } from 'apollo-link'
import { Client } from './Client'
import { Client as IClient } from './Client/IClient'
import { Maybe } from '../../Maybe'
import { InsuranceType } from '../../screens/Page/secure/client/LeftColumn/Insurance/InsuranceForm'

const TYPE = 'INSURANCE_COVERAGE'

function isActualInsuranceCoverage (insuranceCoverage?: Maybe<IInsuranceCoverage>, client?: Maybe<IClient>): boolean {
  const insurance = client?.profile?.insurance
  return (insurance?.vac?.kNumber == null && insurance?.private?.insuranceCoverageUUID == null && insuranceCoverage?.insuranceType === InsuranceType.NONE) ||
    (insurance?.vac?.kNumber != null && insurance?.vac?.kNumber === insuranceCoverage?.kNumber) ||
    (insurance?.private?.insuranceCoverageUUID != null && insurance?.private?.insuranceCoverageUUID === insuranceCoverage?.insuranceCoverageUUID)
}

export const InsuranceCoverage = {
  get (): SyncPromise<IInsuranceCoverage> {
    const { insuranceCoverage, user } = store.getState()

    if (!insuranceCoverage.isUninitiated() && !user.isUninitiated() && isActualInsuranceCoverage(insuranceCoverage.value, user.value?.client)) return insuranceCoverage

    if (!insuranceCoverage.error && !insuranceCoverage.loading) {
      // eslint-disable-next-line
      Client.getInsuranceCoverage().map((insuranceKeys) => {
        if (insuranceKeys.kNumber) {
          dispatchInsuranceCoverage(new SyncPromise<IInsuranceCoverage>({ value: { insuranceType: InsuranceType.VAC, kNumber: insuranceKeys.kNumber } }))
        } else if (insuranceKeys.insuranceCoverageUUID) {
          download(insuranceKeys.insuranceCoverageUUID).catch(console.error)
        } else {
          dispatchInsuranceCoverage(new SyncPromise<IInsuranceCoverage>({ value: { insuranceType: InsuranceType.NONE } }))
        }
      })
    }

    return SyncPromise.LOADING
  },
  clearCache (): void {
    dispatchInsuranceCoverage(SyncPromise.UNINIT)
  },
}

const download = async function (insuranceCoverageUUID: string): Promise<void> {
  type Result = FetchResult<{getInsuranceCoverage: ClientInsuranceCoverage}>

  dispatchInsuranceCoverage(SyncPromise.LOADING)
  const promise = getApolloClient()
    .then(apolloClient => {
      return apolloClient.query({
        query: gql`
          query($insuranceCoverageUUID: String!) {
              getInsuranceCoverage(insuranceCoverageUUID: $insuranceCoverageUUID) {
                insurancePolicyGroupNumber
                insuranceCoverageUUID
                status
                relationshipToPolicyHolder
                cardholderId
                certificateNumber
                insuranceProvider {
                    name
                }
                groupPlan {
                    groupPlanNumber
                    name
                }
              }
            }
        `,
        variables: { insuranceCoverageUUID },
      })
    })
    .then((result: Result) => {
      if (result.errors) {
        console.error(result)
        throw result.errors[0]
      }
      if (!result.data) throw new Error('!result.data')
      return fromCoverageData(result.data.getInsuranceCoverage)
    })
    .catch(handleGraphQLErrors)
  SyncPromise.fromPromise(promise, dispatchInsuranceCoverage)
}

interface InsuranceCoverageAction extends Action {
  readonly type: typeof TYPE
  readonly value: SyncPromise<IInsuranceCoverage>
}

function dispatchInsuranceCoverage (value: SyncPromise<IInsuranceCoverage>) {
  store.dispatch({ type: TYPE, value })
}

const isInsuranceCoverageAction = (x: any): x is InsuranceCoverageAction =>
  x.type === TYPE

const reduce = (state: State, action: Action): State|undefined => {
  if (!isInsuranceCoverageAction(action)) return
  const insuranceCoverage: SyncPromise<IInsuranceCoverage> = action.value
  return {
    ...state,
    insuranceCoverage,
  }
}

export function fromCoverageData (insuranceCoverage: ClientInsuranceCoverage): IInsuranceCoverage {
  return {
    insuranceCoverageUUID: insuranceCoverage.insuranceCoverageUUID,
    insuranceType: InsuranceType.GROUP_PLAN,
    relationshipToPolicyHolder: insuranceCoverage.relationshipToPolicyHolder,
    cardholderId: insuranceCoverage.cardholderId || '',
    certificateNumber: insuranceCoverage.certificateNumber,
    groupPlanNumber: insuranceCoverage.groupPlan?.groupPlanNumber ?? insuranceCoverage.insurancePolicyGroupNumber,
    insuranceProviderName: insuranceCoverage.insuranceProvider?.name,
  }
}

registry.register(reduce)
