import { Maybe } from '../Maybe'

export interface ISyncPromise<T, E = Error|string> {
  readonly async?: Promise<T>
  readonly error?: E
  readonly loading?: boolean
  readonly value?: Maybe<T>
}

export class SyncPromise<T, E = Error|string> implements ISyncPromise<T, E> {
  static readonly UNINIT = new SyncPromise<any, any>({})
  static readonly LOADING = new SyncPromise<any, any>({ loading: true })

  static fromPromise<T, E> (async: Promise<T>, dispatch: (l: SyncPromise<T, E>) => void): SyncPromise<T, E> {
    async.then(value => {
      dispatch(new SyncPromise({ async, value }))
    }).catch(error => {
      dispatch(new SyncPromise({ async, error }))
      console.error(error)
    })
    const loading = new SyncPromise<T, E>({ async, loading: true })
    dispatch(loading)
    return loading
  }

  readonly async?: Promise<T>
  readonly error?: E
  readonly loading?: boolean
  readonly value?: Maybe<T>

  constructor ({ async, error, loading, value }: ISyncPromise<T, E>) {
    this.async = async
    this.error = error
    this.loading = loading
    this.value = value
  }

  isNullish () {
    return this.value === null || this.value === undefined
  }

  isUninitiated () {
    return !this.error && !this.loading && this.value === undefined
  }

  map<U> (f: (v: T) => Maybe<U>): SyncPromise<U, E> {
    return new SyncPromise({
      error: this.error,
      loading: this.loading,
      value: Maybe.map(f)(this.value),
    })
  }

  then<U> (f: (v: Maybe<T>) => Maybe<U>): SyncPromise<U, E> {
    return new SyncPromise<U, E>({
      error: this.error,
      loading: this.loading,
      value: f(this.value),
    })
  }

  switch<X, Y, Z> (errorValue: X, loadingValue: Y, nullishValue: Z): X|Y|Z|T {
    if (this.error) return errorValue
    if (this.loading) return loadingValue
    if (this.isNullish()) return nullishValue
    return this.value as T
  }

  switchMap<X, Y, Z, W> (errorValue: X, loadingValue: Y, nullishValue: Z, f: (v: T) => W): X|Y|Z|W {
    if (this.error) return errorValue
    if (this.loading) return loadingValue
    if (this.isNullish()) return nullishValue
    return this.map(f).value as W
  }

  switchThen<X, Y, W> (errorValue: X, loadingValue: Y, f: (v: Maybe<T>) => W): X|Y|W {
    if (this.error) return errorValue
    if (this.loading) return loadingValue
    return this.then(f).value as W
  }

  private devalue<U> (): SyncPromise<U, E> {
    if (this.value !== undefined) throw new Error('lossy devalue')
    return new SyncPromise({ error: this.error, loading: this.loading })
  }
}
