export interface I18n {
  ORDERS: string
  SHOP: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    SHOP: frCA ? 'Boutique' : 'Shop',
    ORDERS: frCA ? 'Commandes' : 'Orders',
  }
}
