export interface I18n {
  VAC_COVERAGE_TITLE: string
  REMAINING_COVERAGE: string
  END_DATE: string
  NEXT_PERIOD_STARTS: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    VAC_COVERAGE_TITLE: frCA ? 'Couverture D\'ACC - Période Courante' : 'VAC Coverage - Current Period',
    REMAINING_COVERAGE: frCA ? 'Couverture disponible' : 'Remaining Coverage',
    END_DATE: frCA ? 'Dernière journée de la période courante' : 'Current period end date',
    NEXT_PERIOD_STARTS: frCA ? 'Votre prochaine période de couverture commence le jour après la fin de la période courante'
      : 'Your next coverage period starts the day after the current period end date',
  }
}
