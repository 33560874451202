export interface I18n {
  DRIED_FLOWER: string
  CANNABIS_OIL: string
  EDIBLES: string
  VAPES: string
  CONCENTRATES: string
  ACCESSORIES: string
  SHOP_BY_BRAND: string
  SHOP_BY_CATEGORY: string
  CONTACT: string
  FAQ: string
  WHY_AURORA: string
  GETTING_STARTED: string
  VETERANS_COVERAGE: string
  COMPASSIONATE_PRICING: string
  BLOG: string
  PROFILE: string
  WELCOME: string
  ORDER_HISTORY: string
  SHOP: string
  COLLECTIONS: string
  BY_CATEGORY: string
  PRIVACY_POLICY: string
  SHOP_ALL: string
  MERCHANDISE: string
  TOPICALS: string
  CAPSULES: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    DRIED_FLOWER: frCA ? 'Cannabis séché' : 'Dried Flower',
    CANNABIS_OIL: frCA ? 'Extraits' : 'Cannabis Oil',
    EDIBLES: frCA ? 'Edibles' : 'Edibles',
    VAPES: frCA ? 'Vaporisateurs' : 'Vapes',
    CONCENTRATES: frCA ? 'Concentrés' : 'Concentrates',
    ACCESSORIES: frCA ? 'Accessoires' : 'Accessories',
    SHOP_BY_BRAND: frCA ? 'Par Marque' : 'Shop By Brand',
    SHOP_BY_CATEGORY: frCA ? 'Par catégorie' : 'Shop By Category',
    CONTACT: frCA ? 'Contact' : 'Contact Us',
    FAQ: frCA ? 'FAQ' : 'FAQ',
    WHY_AURORA: frCA ? 'Pourquoi Aurora' : 'Why Aurora?',
    GETTING_STARTED: frCA ? 'Commencer' : 'Getting Started',
    VETERANS_COVERAGE: frCA ? 'Couverture des Anciens Combattants' : 'Veterans Coverage',
    COMPASSIONATE_PRICING: frCA ? 'Tarification compatissante' : 'Compassionate Pricing',
    BLOG: frCA ? 'Blogue' : 'Blog',
    PROFILE: frCA ? 'Profile' : 'My Account',
    WELCOME: frCA ? 'Bienvenue' : 'Welcome',
    ORDER_HISTORY: frCA ? 'Historique des commandes' : 'Order History',
    SHOP: frCA ? 'Boutique' : 'Shop',
    COLLECTIONS: frCA ? 'Nos Produits' : 'All Products',
    BY_CATEGORY: frCA ? 'Par catégorie' : 'By Category',
    PRIVACY_POLICY: frCA ? 'Politique de confidentialité' : 'Privacy Policy',
    SHOP_ALL: frCA ? 'Nos Produits' : 'Shop All Products',
    MERCHANDISE: frCA ? 'Marchandise' : 'Merchandise',
    TOPICALS: frCA ? 'Topiques' : 'Topicals',
    CAPSULES: frCA ? 'Сapsules' : 'Сapsules',
  }
}
