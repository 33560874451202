import React from 'react'
import { Alert, Form } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import Card from '../../../../../components/Card'
import FormInput from '../../../../../components/FormInput'
import Button from '../../../../../components/Button'
import { connect } from 'react-redux'
import { requestClients } from './requestClients'
import { AccessToken } from '../../../../../auth'

interface StateProps {
}

interface FormProps extends StateProps {
  form: WrappedFormUtils
}

interface State {
  loading?: boolean
  message?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  userList?: any[]
  showResult?: boolean
}

const fetchUsers = async (firstName: string, lastName: string, dateOfBirth: string): Promise< any[] > => {
  const accessToken: string|null = await AccessToken.get()
  if (accessToken) {
    const data = await requestClients(accessToken, firstName, lastName, dateOfBirth)
    return data
  } else throw new Error('Invalid access token')
}
export class ClientMatchingPagePres extends React.Component<FormProps, State> {
  state: State = {
    loading: false,
    message: '',
    userList: [],
    showResult: false,
  }

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.setState({ loading: true })
    this.props.form.validateFields((err, { firstName, lastName, dateOfBirth }) => {
      if (err) {
        return this.setState({ loading: false })
      }
      fetchUsers(firstName, lastName, dateOfBirth).then((userList) => {
        this.setState({ loading: false, userList: userList, showResult: true })
      }).catch(error => {
        console.error(error)
        this.setState({
          message: error.message,
          loading: false,
        })
      })
    })
  }

  render () {
    const { form } = this.props
    const { message, loading, userList, showResult } = this.state

    return (
      <Card style={{ flexDirection: 'column' }}>
        <p><strong>Important</strong> : This page is for Beta testing only and will not be available in production</p>
        <div>
          <Form
            onSubmit={this.handleSubmit}
            style={{ maxWidth: 350, margin: 'auto', textAlign: 'center' }}
          >
            <Form.Item><h3>Search Client</h3></Form.Item>
            <FormInput
              name='firstName'
              label='First Name'
              form={form}
              maxLength={50}
              decoratorOptions={{
                rules: [
                  {
                    required: true,
                    message: 'Please enter a first name',
                  },
                ],
              }}
            />
            <FormInput
              name='lastName'
              label='Last Name'
              form={form}
              maxLength={50}
              decoratorOptions={{
                rules: [
                  {
                    required: true,
                    message: 'Please enter a last name',
                  },
                ],
              }}
            />
            <FormInput
              name='dateOfBirth'
              label='Date of Birth (YYYY-MM-DD)'
              form={form}
              maskType='dob'
              decoratorOptions={{
                rules: [
                  {
                    required: true,
                    message: 'Please enter a date of birth',
                  },
                ],
              }}
            />
            {message && (
              <Alert
                message={message}
                style={{ margin: '15px 0px' }}
              />
            )}
            <Button
              htmlType='submit'
              disabled={loading}
              style={{ minWidth: '70%', height: 48, marginBottom: 10 }}
            >
              { loading ? 'Searching...' : 'Search' }
            </Button>
          </Form>
        </div>
        {
          showResult &&
         <div style={{ marginTop: '1em', padding: 20, border: '1px solid rgba(175, 177, 175, 0.5)' }}>
           <h3>Search Results</h3>
           {
             (userList !== undefined && userList.length > 0) ? userList?.map(function (user, i) { return <li key={i}>{user.clientId} - {user.firstName} - {user.emailAddress} </li> }) : <p>No results found</p>
           }
         </div> }
      </Card>
    )
  }
}

const ClientMatchingForm = Form.create({ name: 'client matching' })(ClientMatchingPagePres)

export const ClientMatchingPage = connect(() => ({}))(ClientMatchingForm)
