export function formatQuery () {
  return `
  query ($accessToken: String!) {
    getUser(accessToken: $accessToken) {
      id
      client {
        id
        canAmend
        canRenew
        registrationStatus
        callToActionMessage
        displayStatus
        registrationPackages {
          statusCode
        }
        registrationPackageStatus
        prescription {
          allowancePerDay
          allowanceRemaining
          allowanceTotal
          endDate
          startDate
        }
        priceAdjustment { discount, type }
        profile {
          firstName
          lastName
          consent2 {
            research { value },
            marketing { value },
            privacy { value, date }
          }
          dateOfBirth
          emailAddress
          gender
          hasPhysician
          knumber
          insurance {
            period {
              allowanceRemaining
              endDate
              startDate
            }
            vac {
                kNumber
            }
            private {
              insuranceCoversMedication
              insuranceCoversCannabis
              insuranceCoverageUUID
              yourPlan {
                number
                provider
                providerName
              }
              otherPlan {
                number
                provider
                providerName
              }
            }
          }
          language
          telephoneNumber
          physicalAddress { # TODO fragment for addresses
            line1
            line2
            province
            city
            postalCode
          }
          mailingAddress {
            line1
            line2
            province
            city
            postalCode
          }
          shippingAddress {
            line1
            line2
            province
            city
            postalCode
          }
        }
      }
      caregiver {
        id
        status
        profile {
            firstName
            lastName
            dateOfBirth
            emailAddress
            telephoneNumber
        }
        clients {
          id
          profile {
            firstName
            lastName
            emailAddress
            telephoneNumber
          }
        }
      }
      clinician {
        clinicId
      }
    }
  }
  `
}
