import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Location } from 'history'

interface Props {
  location: Location
}

export const ScrollToTop: React.FC<Props> = props => {
  useEffect(() => {
    // to save a position of previous screen (page)
    // when using standard goBack function such a code can be used:
    // props.history.action !== 'POP' && window.scrollTo(0, 0)
    window.scrollTo(0, 0)
  }, [props.location.pathname])
  return null
}

export default withRouter(ScrollToTop)
