import './style.less'
import { Icon, Table as AntTable } from 'antd'
import { TableProps } from 'antd/lib/table'
import React, { ReactElement } from 'react'
import { BLUE_DARK } from '../../style'

// TODO: add custom styles for cases with sorting

export const Table: React.FC<TableProps<any>> = props => {
  const { pagination = {}, loading = false, ...restProps } = props

  const itemRender = (curPage: number, type: string, elem: ReactElement) =>
    type === 'prev' ? (
      <a style={{ color: BLUE_DARK }} href='/'>
        <Icon type="left" style={{ fontSize: 12 }} /> Prev
      </a>
    ) : type === 'next' ? (
      <a style={{ color: BLUE_DARK }} href='/'>
        Next <Icon type="right" style={{ fontSize: 12 }} />
      </a>
    ) : {
      ...elem,
      props: {
        ...elem.props,
        children: String(elem.props.children).padStart(2, '0'),
      },
    }

  return (
    <AntTable
      pagination={{
        pageSize: 5, // 5 items per page by default
        itemRender,
        ...pagination,
      }}
      rowKey={record => record.id}
      loading={loading}
      {...restProps}
    />
  )
}

export default Table
