import './style.less'
import { AutoComplete, Input, DatePicker, Form, Icon, Select } from 'antd'
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form'
import React, { ReactElement } from 'react'
import { WHITE } from '../../style'
import Checkbox from '../Checkbox'
import Input1, { MaskType } from '../Input'
import RadioGroup from '../RadioGroup'
import {
  checkboxStyle,
  generalFieldStyle,
  inputStyle,
  labelStyle,
  textareaStyle,
  wrapperStyle,
} from './styles'

const { Option } = Select

export interface SelectItem {
  label: string
  value: string
  disabled?: boolean
}

interface Props {
  decoratorOptions?: GetFieldDecoratorOptions
  disabled?: boolean
  form: WrappedFormUtils
  id?: string
  label?: string | ReactElement
  maskType?: MaskType
  maxLength?: number
  mode?: 'ro'|'rw'
  name: string
  placeholder?: string
  showSearch?: boolean
  onChange?: (value: any) => void
  onSelectAnt?: (value: any) => void
  onSelect?: (value: string) => void
  onDateChange?: (value1: any, value2: any) => void
  selectItems?: SelectItem[]
  type?: 'text' | 'checkbox' | 'datePicker' | 'textArea' | 'email' | 'radioGroup' | 'select' | 'password' | 'autoSuggest'
}

const statusBar = (<div
  className="status-bar"
  style={{ position: 'absolute', bottom: 0, width: '100%' }}
/>)

const FormInput: React.FC<Props> = ({
  id,
  decoratorOptions = {},
  disabled = false,
  form,
  label,
  maskType,
  maxLength,
  mode,
  name,
  placeholder,
  showSearch,
  onChange,
  onSelectAnt,
  onSelect,
  onDateChange,
  selectItems = [],
  type = 'text',
}): JSX.Element | null => {
  const { getFieldDecorator, getFieldValue } = form
  const value = getFieldValue(name)
  const isNotEmpty = Boolean(value)
  const inputClass = `form-input ${isNotEmpty ? 'not-empty' : ''}`

  const isHidden = mode === 'ro' && !value

  if (isHidden) {
    return null
  }

  const wrapField = (el: ReactElement) => (
    <Form.Item>
      {getFieldDecorator(name, decoratorOptions)(el)}
    </Form.Item>
  )

  const onSelctedItem = (selected: string) => {
    if (typeof onSelectAnt === 'function') {
      onSelectAnt(selected)
    } else {
      const selectedItem = selectItems.find(selectItem => selectItem.label === selected)?.value
      onChange?.(selectedItem ?? '')
      onSelect?.(selectedItem ?? '')
    }
  }

  switch (type) {
    case 'text':
    case 'email':
    case 'password':
      return wrapField(
        <div style={wrapperStyle}>
          <Input1
            className={inputClass}
            value={value}
            disabled={disabled}
            id={id ?? name}
            maskType={maskType}
            maxLength={maxLength}
            style={inputStyle}
            type={type}
          />
          <label style={labelStyle}>{label}</label>
          {statusBar}
        </div>,
      )
    case 'textArea':
      return wrapField(
        <div
          style={{
            ...wrapperStyle,
            ...generalFieldStyle,
            height: 'auto',
            paddingTop: '30px',
            backgroundColor: WHITE,
          }}
        >
          <textarea
            rows={2}
            className={inputClass}
            style={{ resize: 'none', ...textareaStyle }}
          />
          <label style={labelStyle}>{label}</label>
          {statusBar}
        </div>,
      )
    case 'checkbox':
      return wrapField(<div style={wrapperStyle}>
        <Checkbox style={checkboxStyle} className="form-checkbox" checked={value}>{label}</Checkbox>
      </div>)

    case 'select':
      return (
        <Form.Item>
          <div style={{ fontSize: 12, lineHeight: '12px', paddingLeft: 14, marginBottom: 3 }}>{label}</div>
          {getFieldDecorator(
            name,
            decoratorOptions,
          )(<Select showSearch={showSearch} style={{ width: '100%', border: 'none' }} onChange={onSelctedItem} disabled={mode === 'ro'} placeholder={placeholder}>
            {selectItems.map(item => (
              <Option value={showSearch ? item.label : item.value} key={item.value}>{item.label}</Option>
            ))}
          </Select>)}
        </Form.Item>
      )
    case 'autoSuggest':
      return (
        <Form.Item>
          <div style={{ fontSize: 12, lineHeight: '12px', paddingLeft: 14, marginBottom: 3 }}>{label}</div>
          {getFieldDecorator(
            name,
            decoratorOptions,
          )(<AutoComplete dataSource={selectItems.map(item => item.value)} style={{ width: '100%', height: 35, border: 'none' }} onChange={onChange} onSelect={onChange} disabled={mode === 'ro'}>
            <Input placeholder={placeholder} prefix={<Icon type='search' style={{ marginTop: 12.5 }}/>}/>
            {statusBar}
          </AutoComplete>)}
        </Form.Item>
      )
    case 'datePicker': return wrapField(<DatePicker onChange={onDateChange} placeholder={placeholder} mode='date'/>)
    case 'radioGroup':
      return wrapField(<RadioGroup items={selectItems} onChange={onChange}/>)
    default:
      return null
  }
}

export default FormInput
