import gql from 'graphql-tag'
import { ExecutionResult } from 'graphql/execution/execute'
import { AccessToken } from '../../../auth'
import { getApolloClient, handleGraphQLErrors } from '../../../getApolloClient'

export async function getImpersonationToken (clientId: string): Promise<string> {
  const accessToken = await AccessToken.get()
  if (!accessToken) {
    console.warn('!accessToken')
    return new Promise((resolve, reject) => {})
  }
  const apolloClient = await getApolloClient()
  return apolloClient.query({
    query: gql`
      query (
        $accessToken: String!,
        $clientId: String!
      ){
        getImpersonationToken(
          accessToken: $accessToken,
          clientId: $clientId
        )
      }
    `,
    variables: {
      accessToken,
      clientId,
    },
  }).then((result: ExecutionResult) => {
    const { data } = result
    if (!data) throw new Error('!data')
    return data.getImpersonationToken
  }).catch(handleGraphQLErrors)
}
