import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import Card from '../../../../../../components/Card'
import {
  RegAppForm,
  RegFormData,
} from '../../../../../../components/RegAppForm'
import { Mode } from '../../../../../../components/RegAppForm/Mode'
import { updateClientProfile } from '../../../../../../redux/State/Client/Profile'
import { ClientProfileUpdate } from '../../../../../../redux/State/Client/Profile/IClientProfile'
import { Client } from '../../../../../../redux/State/Client'
import { SyncPromise } from '../../../../../../redux/SyncPromise'
import { State as RootState } from '../../../../../../redux/State'

interface StateProps {
  update: SyncPromise<ClientProfileUpdate>
  language: 'en-CA'|'fr-CA'
}

interface State {
  error?: string
  saving?: boolean
}

export class ProfileUpdatePres extends React.Component<StateProps, State> {
  state: State = {}

  render () {
    const { update } = this.props
    return update.switchMap(
      <Card><p>ERROR</p></Card>,
      <Card><p>Loading...</p></Card>,
      <Card><p>No Profile</p></Card>,
      p => this.renderValue(p),
    )
  }

  renderValue (update: ClientProfileUpdate) {
    const { language } = this.props
    const { error, saving } = this.state

    const handleSave = (data: RegFormData) => {
      this.setState({ saving: true })
      updateClientProfile(data as ClientProfileUpdate)
        .then(() => {
          window.location.href = '/#/secure/client/profile'
        })
        .catch(error => {
          console.error(error)
          this.setState({ error: error.message || error, saving: false })
        })
    }

    return (
      <RegAppForm
        handleSave={handleSave}
        initialMode="rw"
        initialValue={update}
        saving={saving}
        mode={Mode.UPDATE}
        submitError={error}
        language={language}
      />
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (
  state,
  props,
): StateProps => {
  const update = Client.get().map(c => c.profile)
    .map(
      ({ consent, emailAddress, telephoneNumber }) =>
        ({ consent, emailAddress, telephoneNumber }),
    )
  return { update, language: state.language }
}

export const ProfileUpdate = connect(mapStateToProps)(ProfileUpdatePres)
