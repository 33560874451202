import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Home } from './Home'
import { CaregiverPage } from './caregiver'
import { ClientPage } from './client'
import { ClinicianPage } from './clinician'

export class SecurePage extends React.Component {
  render () {
    return (
      <Switch>
        <Route path="/*/caregiver" component={CaregiverPage} />
        <Route path="/*/client" component={ClientPage} />
        <Route path="/*/clinician" component={ClinicianPage} />
        <Route component={Home} />
      </Switch>
    )
  }
}
