export interface I18n {
  PATIENTS_HEADER: string
  COMPASSIONATE_PRICING: string
  VETERAN_COVERAGE: string
  BRANDS_HEADER: string
  OUR_STORY: string
  FOR_INVESTORS: string
  LEARN_HEADER: string
  CANNABIS_101: string
  BLOG: string
  FAQ: string
  CONTACT_HEADER: string
  CONTACT_EMAIL: string
  CONTACT_PHONE: string
  DISCLAIMER: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    PATIENTS_HEADER: frCA ? 'Patients' : 'Patients',
    COMPASSIONATE_PRICING: frCA ? 'Tarif de compassion' : 'Compassionate Pricing',
    VETERAN_COVERAGE: frCA ? 'Couverture Anciens combattants' : 'Veteran Coverage',
    BRANDS_HEADER: frCA ? 'Nos marques' : 'Our Brands',
    OUR_STORY: frCA ? 'Notre histoire' : 'Our Story',
    FOR_INVESTORS: frCA ? 'Pour investisseurs' : 'For Investors',
    LEARN_HEADER: frCA ? 'Apprendre' : 'Learn',
    CANNABIS_101: frCA ? 'Cannabis 101' : 'Cannabis 101',
    BLOG: frCA ? 'Blogue' : 'Blog',
    FAQ: frCA ? 'FAQ' : 'FAQ',
    CONTACT_HEADER: frCA ? 'Contact' : 'Contact Us',
    CONTACT_EMAIL: frCA ? 'parleznous@auroramedical.com' : 'askus@auroramedical.com',
    CONTACT_PHONE: frCA ? '1-877-928-7672' : '1-877-928-7672',
    DISCLAIMER: frCA
      ? 'Ce site Web a été créé à titre informatif seulement. Ces renseignements ne devraient en aucun cas remplacer le processus d’autorisation par un professionnel de la santé pour l’utilisation de cannabis médicinal fondé un diagnostic précis et un programme de traitement.'
      : 'This website is intended for informational purposes only. This information is not intended to replace health care practitioners’ process of authorizing patients’ use of medical cannabis based on their specific diagnoses and proposed treatment plans',
  }
}
