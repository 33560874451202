import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { Config } from '../../redux/State/Config'

interface Props {}
export const GoogleTagManagerHelmet: React.FC<Props> = props => {
  useEffect(() => {
    setGTMId()
      .catch(err => console.log(err))
  })

  function setGTMURL (window: any, doc: any, script: any, layer: string, gtmId: string) {
    window[layer] = window[layer] || []
    window[layer].push({
      'gtm.start': new
      Date().getTime(),
      event: 'gtm.js',
    })
    var f = doc.getElementsByTagName(script)[0]
    var j = doc.createElement(script)
    var dl = layer !== 'dataLayer' ? '&layer=' + layer : ''
    j.async = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dl
    f.parentNode.insertBefore(j, f)
  }

  async function setGTMId () {
    const config = await Config.promise
    setGTMURL(window, document, 'script', 'dataLayer', config.GTM_ID)
  }

  return (
    <Helmet>
      <noscript>
        {`<iframe src=${URL} height="0" width="0"></iframe>`}
      </noscript>
    </Helmet>
  )
}
export default GoogleTagManagerHelmet
