import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ClientRegistrationApplicationPage } from './client-registration-application'
import { ClientRegistrationAppliedPage } from './client-registration-applied'

export class PublicPage extends React.Component {
  render () {
    return (
      <Switch>
        <Route path="/public/client-registration-application" component={ClientRegistrationApplicationPage} />
        <Route path="/public/client-registration-applied" component={ClientRegistrationAppliedPage} />
        <Route path="/registration-confirmation" component={ClientRegistrationAppliedPage} />
        <Route path="/register" component={ClientRegistrationApplicationPage} />
        <Route component={ClientRegistrationApplicationPage} />
      </Switch>
    )
  }
}
