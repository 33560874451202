import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LoginPage } from './Login'
import { MaintenancePage } from './Maintenance'
import { ResetPasswordPage } from './ResetPassword'
import { BuildInfoPage } from './build-info'
import { PublicPage } from './public'
import { SecurePage } from './secure'

export class Page extends React.Component {
  render () {
    return (
      <Switch>
        <Route path="/build-info" component={BuildInfoPage} />
        <Route path="/maintenance" component={MaintenancePage} />
        <Route path="/my-account" component={SecurePage} />
        <Route path="/public" component={PublicPage} />
        <Route path="/regist*" component={PublicPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/secure" component={SecurePage} />
        <Route component={LoginPage} />
      </Switch>
    )
  }
}
