export interface I18n {
  MY_PRIVACY: string
  MY_PROFILE: string
  MY_INSURANCE: string
  MY_PROFILE_EDIT: string
  PRICE_ADJUSTMENTS: string
  WELCOME: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    MY_PRIVACY: frCA ? 'Ma Confidentialité' : 'My Privacy',
    MY_PROFILE: frCA ? 'Mon Profil' : 'My Profile',
    MY_INSURANCE: frCA ? 'Mon Assurance' : 'My Insurance',
    MY_PROFILE_EDIT: frCA ? 'Mon Profil - Modifier' : 'My Profile - Edit',
    PRICE_ADJUSTMENTS: frCA ? 'Remise:' : 'Price Adjustments:',
    WELCOME: frCA ? 'Bienvenue ' : 'Welcome ',
  }
}
