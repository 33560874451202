import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import Breadcrumb from '../../../../components/Breadcrumb'
import { State } from '../../../../redux/State'
import { Beta } from '../../../../redux/State/Beta'
import { LeftColumn } from './LeftColumn'
import { RightColumn } from './RightColumn'

interface Props {}
interface StateProps {
  beta: boolean
}

interface StateRouteProps extends RouteComponentProps, StateProps {}

export class ClientPagePres extends React.Component<StateRouteProps> {
  render () {
    const { beta } = this.props
    return (
      <>
        {beta && <div style={{ padding: '0 1em' }}>
          <Breadcrumb location={this.props.location} />
        </div>}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}>
          <LeftColumn style={{ flex: 2, padding: '1em' }}/>
          <RightColumn style={{ maxWidth: '30em', padding: '1em' }} />
        </div>
      </>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const beta = Beta.is()
  return { beta }
}

export const ClientPage = connect(mapStateToProps)(withRouter(ClientPagePres))
