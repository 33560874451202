import { Action } from '../Action'
import * as registry from '../registry'
import { store } from '../store'
import { State } from './index'

export const Beta = {
  is: () => store.getState().beta,
  toggle: () => store.dispatch({ type: TYPE }),
}

const TYPE = 'BETA'

interface BetaAction extends Action {
  readonly type: typeof TYPE
}

const isUserAction = (x: any): x is BetaAction =>
  x.type === TYPE

const reduce = (state: State, action: Action): State|undefined => {
  if (!isUserAction(action)) return
  return {
    ...state,
    beta: !state.beta,
  }
}

registry.register(reduce)

if (window) {
  // @ts-ignore
  window.Beta = Beta
}
