export interface I18n {
  COPYRIGHT: string
  PRIVACY: string
  TERMS: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    COPYRIGHT: frCA ? '© 2020 Aurora Cannabis Inc. Tous droits réservés.' : '© 2020 Aurora Cannabis Inc. All Rights Reserved.',
    PRIVACY: frCA ? 'Politique de confidentialité' : 'Privacy Policy',
    TERMS: frCA ? 'Conditions' : 'Terms and Conditions',
  }
}
