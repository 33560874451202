import './style.less'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import React from 'react'
import { GREY, WHITE } from '../../style'

interface Props {
  items: Array<{
    label: string
    value: string
    disabled?: boolean
  }>
  onChange?: (ev: RadioChangeEvent) => void
}

class RadioGroup extends React.Component<Props> {
  render () {
    const { items, ...rest } = this.props
    const itemWidth = `${100 / items.length}%`
    return (
      <Radio.Group style={{ width: '100%', textAlign: 'center' }} {...rest}>
        {items.map(item => (
          <div
            key={item.value}
            style={{
              display: 'inline-block',
              minWidth: itemWidth,
              backgroundColor: WHITE,
              border: `1px solid ${GREY}`,
              padding: '10px 10px',
              marginBottom: '1px',
            }}
            className="radio-btn"
          >
            <Radio value={item.value} disabled={item.disabled}>{item.label}</Radio>
          </div>
        ))}
      </Radio.Group>
    )
  }
}

export default RadioGroup
