import React, { CSSProperties } from 'react'
import { WHITE, BLACK } from '../../style'
import Button from '../Button'

export interface CtaItem {
  title: string
  copy?: string
  btnText?: string
  onClick?: () => void
  img?: string
}

interface Props extends CtaItem {
  style?: CSSProperties
  titleStyle?: CSSProperties
}

export const Cta: React.FC<Props> = ({
  title,
  copy,
  btnText,
  onClick,
  img,
  style = {},
  titleStyle = {},
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: WHITE,
        ...style,
      }}
    >
      <div>
        <h1 style={{
          fontFamily: '"Copernicus", serif',
          color: BLACK,
          marginBottom: 20,
          ...titleStyle,
        }}>
          {title}
        </h1>
        {copy && <div style={{
          color: BLACK,
          marginBottom: 30,
        }}>
          {copy}
        </div>}
        {btnText && <Button onClick={onClick}>
          {btnText}
        </Button>}
      </div>
      {(img && onClick) && <div style={{ marginLeft: 24 }}>
        <img src={img} alt="" style={{ borderRadius: 6 }} />
      </div>}
    </div>
  )
}

export default Cta
