import './style.less'
import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import NavbarBrand from 'react-bootstrap/NavbarBrand'
import Nav from 'react-bootstrap/Nav'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { DropdownSubmenu, NavDropdownMenu } from 'react-bootstrap-submenu'
import { Config } from '../../redux/State/Config'
import { User } from '../../redux/State/User'
import { Link } from 'react-router-dom'
import { I18n, getI18n } from './index.i18n'
import { connect, MapStateToProps } from 'react-redux'
import { State } from '../../redux/State'

interface StateProps {
  i18n: I18n
}

interface OwnProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps

export const NavBarPres: React.FC<Props> = ({ i18n }) => {
  const user = User.get()
  const { URL_SHOPIFY } = Config.get().value ?? {}
  const [show, setShow] = useState(false)
  const [showMenu, setShowMenu] = useState([false, false, false])
  const [isMobile, setIsMobile] = useState(false)
  const [mobileSearch, setMobileSearch] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const size = useWindowSize()

  useEffect(() => {
    // @ts-ignore
    if (size.width < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
      setMobileSearch(false)
    }
  }, [size.width])

  useEffect(() => {
    if (show) {
      setMobileSearch(false)
    }
  }, [show])

  useEffect(() => {
    if (mobileSearch) {
      setShow(false)
    }
  }, [mobileSearch])

  useEffect(() => {
    if (!isMobile) {
      setShow(false)
    }
  }, [isMobile])

  function useWindowSize () {
    const initState: any = {
      width: undefined,
      height: undefined,
    }
    const [windowSize, setWindowSize] = useState(initState)
    useEffect(() => {
      function handleResize () {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowSize
  }

  const showDropdown = (index: number) => {
    const show = [false, false, false]
    show[index] = true
    setShowMenu(show)
  }

  const hideDropdown = () => {
    setShowMenu([false, false, false])
  }

  return (
    <Navbar className='navbar-custom' collapseOnSelect expand="xl" expanded={false}>
      <Container className='container-nav'>
        <button className='navbar-toggler styled-flyout-button' onClick={handleShow}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <NavbarBrand href="/" ><img src="//cdn.shopify.com/s/files/1/0274/1547/8407/files/Aurora_Medical_Logo_2020_TMMC_191Wide.png" alt="Aurora Medical logo"></img></NavbarBrand>
        <Navbar.Collapse>
          <Nav className="me-auto">
            <NavDropdown onMouseEnter={() => showDropdown(0)} onMouseLeave={hideDropdown} show={showMenu[0]} title={ i18n.GETTING_STARTED } id="navdropmenu1">
              <NavDropdown.Item href={`${URL_SHOPIFY}/pages/patients`}>{ i18n.GETTING_STARTED }</NavDropdown.Item>
              <NavDropdown.Item href={`${URL_SHOPIFY}/pages/why-aurora`}>{ i18n.WHY_AURORA }</NavDropdown.Item>
              <NavDropdown.Item href={`${URL_SHOPIFY}/blogs/news/veterans-coverage`}>{ i18n.VETERANS_COVERAGE }</NavDropdown.Item>
              <NavDropdown.Item href={`${URL_SHOPIFY}/blogs/news/compassionate-pricing`}>{ i18n.COMPASSIONATE_PRICING }</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown onMouseEnter={() => showDropdown(1)} show={showMenu[1]} onMouseLeave={hideDropdown} className='nav-dropdown-menu tiered-link' title={ i18n.SHOP} id="navdropmenu2">
              <div className='tiered-dropdown'>
                <div className="dropdown-section">
                  <span>{ i18n.SHOP_ALL}</span>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections`}>{ i18n.COLLECTIONS }</NavDropdown.Item>
                </div>
                <div className="dropdown-section">
                  <span>{ i18n.SHOP_BY_CATEGORY }</span>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/dried-flower`}>{ i18n.DRIED_FLOWER }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/cannabis-oil`}>{ i18n.CANNABIS_OIL }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/capsules`}>{ i18n.CAPSULES }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/edibles`}>{ i18n.EDIBLES }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/vapes`}>{ i18n.VAPES }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/concentrates`}>{ i18n.CONCENTRATES }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/topicals`}>{ i18n.TOPICALS }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/merchandise`}>{ i18n.MERCHANDISE }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/accessories`}>{ i18n.ACCESSORIES }</NavDropdown.Item>
                </div>
                <div className="dropdown-section">
                  <span>{ i18n.SHOP_BY_BRAND }</span>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/aurora`}>Aurora</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/cannimed`}>CanniMed</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/medreleaf`}>MedReleaf</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/wmmc`}>WMMC</NavDropdown.Item>
                  <NavDropdown.Item href="/collections/marketplace">Marketplace</NavDropdown.Item>
                </div>
              </div>
            </NavDropdown>
            <NavDropdown onMouseEnter={() => showDropdown(2)} onMouseLeave={hideDropdown} show={showMenu[2]} title="Support" id="navdropmenu3">
              <NavDropdown.Item href={`${URL_SHOPIFY}/pages/our-contacts`}>{ i18n.CONTACT }</NavDropdown.Item>
              <NavDropdown.Item href={`${URL_SHOPIFY}/pages/frequently-asked-questions`}>{ i18n.FAQ }</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href={`${URL_SHOPIFY}/pages/blog`}>{ i18n.BLOG }</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className='navbar-collapse-search'>
          <Nav>
            {user.value?.id && <div className="navbar-icons">
              <Link to="/secure/client">
                <i className="fas fa-user"></i>
                <span>{i18n.PROFILE}</span>
              </Link>
            </div>}
            {user.value?.id && <div className="navbar-icons">
              <a href={`${URL_SHOPIFY}/account`}>
                <i className="fas fa-history"></i>
                <span>{i18n.ORDER_HISTORY}</span>
              </a>
            </div>}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Offcanvas id="offcanvasNavbar" className="offcanvas-custom" aria-labelledby="offcanvasNavbarLabel" show={show} onHide={handleClose} backdrop={false}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">{ i18n.WELCOME } { user.value?.client?.profile.firstName } { user.value?.client?.profile.lastName }</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {user.value?.id && <Nav.Link href="/#/secure/client" onClick={handleClose}>{ i18n.PROFILE }</Nav.Link>}
              {user.value?.id && <Nav.Link href={`${URL_SHOPIFY}/account`}>{ i18n.ORDER_HISTORY }</Nav.Link>}
              <NavDropdownMenu title={ i18n.GETTING_STARTED } id="navdropmenu0">
                <NavDropdown.Item href={`${URL_SHOPIFY}/pages/patients`}>{ i18n.GETTING_STARTED }</NavDropdown.Item>
                <NavDropdown.Item href={`${URL_SHOPIFY}/pages/why-aurora`}>{ i18n.WHY_AURORA }</NavDropdown.Item>
                <NavDropdown.Item href={`${URL_SHOPIFY}/blogs/news/veterans-coverage`}>{ i18n.VETERANS_COVERAGE }</NavDropdown.Item>
                <NavDropdown.Item href={`${URL_SHOPIFY}/blogs/news/compassionate-pricing`}>{ i18n.COMPASSIONATE_PRICING }</NavDropdown.Item>
              </NavDropdownMenu>
              <NavDropdownMenu title={ i18n.SHOP } id="navdropmenu4">
                <NavDropdown.Item href={`${URL_SHOPIFY}/collections`}>{ i18n.COLLECTIONS }</NavDropdown.Item>
                <DropdownSubmenu className='dropdown-title' href="#action/3.7" title={ i18n.BY_CATEGORY }>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/dried-flower`}>{ i18n.DRIED_FLOWER }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/cannabis-oil`}>{ i18n.CANNABIS_OIL }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/capsules`}>{ i18n.CAPSULES }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/edibles`}>{ i18n.EDIBLES }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/vapes`}>{ i18n.VAPES }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/concentrates`}>{ i18n.CONCENTRATES }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/topicals`}>{ i18n.TOPICALS }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/merchandise`}>{ i18n.MERCHANDISE }</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/accessories`}>{ i18n.ACCESSORIES }</NavDropdown.Item>
                </DropdownSubmenu>
                <DropdownSubmenu className='dropdown-title' href="#action/3.7" title={ i18n.SHOP_BY_BRAND }>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/aurora`}>Aurora</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/cannimed`}>CanniMed</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/medreleaf`}>MedReleaf</NavDropdown.Item>
                  <NavDropdown.Item href={`${URL_SHOPIFY}/collections/wmmc`}>WMMC</NavDropdown.Item>
                  <NavDropdown.Item href="/collections/marketplace">Marketplace</NavDropdown.Item>
                </DropdownSubmenu>
              </NavDropdownMenu>
              <NavDropdownMenu title="Support" id="navdropmenu5">
                <NavDropdown.Item href={`${URL_SHOPIFY}/pages/our-contacts`}>{ i18n.CONTACT }</NavDropdown.Item>
                <NavDropdown.Item href={`${URL_SHOPIFY}/pages/frequently-asked-questions`}>{ i18n.FAQ }</NavDropdown.Item>
              </NavDropdownMenu>
              <Nav.Link href={`${URL_SHOPIFY}/pages/blog`}>{ i18n.BLOG }</Nav.Link>
            </Nav>
            <Nav className="secondary-nav">
              <Nav.Link href={`${URL_SHOPIFY}/pages/our-contacts`}>{ i18n.CONTACT }</Nav.Link>
              <Nav.Link href={`${URL_SHOPIFY}/pages/privacy-policy`}>{ i18n.PRIVACY_POLICY}</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state,
  props,
): StateProps => {
  return {
    i18n: getI18n(state.language),
  }
}

export const NavBarComponent = connect(mapStateToProps)(NavBarPres)
