import Icon from 'antd/lib/icon'
import Inputmask from 'inputmask'
import React from 'react'

// use in mask:
// 9 - numeric, a - alphabetical, * - alphanumeric, spaces & other symbols
const maskTypes = {
  dob: '9999-99-99',
  kNumber: 'K9999999',
  phone: '999-999-9999',
}

export type MaskType
  = 'dob'
  | 'kNumber'
  | 'phone'

interface Props {
  className?: string
  value?: string
  disabled?: boolean
  id?: string
  maskType?: MaskType
  maxLength?: number
  style?: React.CSSProperties
  type?: 'text' | 'email' | 'password'
}

interface State {
  show?: boolean
}

class Input extends React.PureComponent<Props, State> {
  input: HTMLInputElement | null = null
  state: State = {}

  componentDidMount () {
    const { maskType } = this.props
    if (maskType) {
      // dont unmask date of birth and kNumber fields
      const autoUnmask = !['dob', 'kNumber'].includes(maskType)
      this.input && Inputmask(
        maskTypes[maskType],
        { showMaskOnHover: false, autoUnmask },
      ).mask(this.input)
    }
  }

  toggleVisibility () {
    const { show } = this.state
    this.setState({ show: !show })
  }

  render () {
    const {
      className,
      disabled,
      id,
      maxLength,
      style,
      type,
      value,
    } = this.props
    const { show } = this.state
    return (
      <>
        <input
          className={className}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          ref={el => { this.input = el }}
          style={style}
          type={show ? 'text' : type ?? 'text'}
          value={value}
        />
        {type === 'password' && <Eyecon show={show} onClick={() => this.toggleVisibility() }/>}
      </>
    )
  }
}

interface EyeconProps {
  onClick: (event: React.MouseEvent<HTMLElement, any>) => void
  show?: boolean
}
class Eyecon extends React.PureComponent<EyeconProps> {
  render () {
    const { onClick, show } = this.props
    const theme = show ? 'outlined' : 'filled'
    return <Icon
      onClick={onClick}
      style={{ position: 'absolute', top: '22px', right: '22px' }}
      theme={theme}
      type="eye"
    />
  }
}

export default Input
