import { FetchResult } from 'apollo-link'
import gql from 'graphql-tag'
import { getApolloClient, handleGraphQLErrors } from '../getApolloClient'

interface LRTokenResult { accessToken: string, expiresIn: string }

export async function requestTokenFromCreds (email: string, password: string): Promise<LRTokenResult> {
  type Result = FetchResult<{getAccessTokenV2: LRTokenResult}>
  return getApolloClient()
    .then(apolloClient =>
      apolloClient.query({
        query: gql`
          query ($email: String!, $password: String!){
            getAccessTokenV2(email: $email, password: $password) {
              accessToken
              expiresIn
            }
          }
        `,
        variables: { email, password },
      }),
    )
    .then((result: Result) => {
      if (result.errors) {
        console.error({ result })
        throw result.errors[0]
      }
      if (!result.data) throw new Error('!result.data')
      return result.data.getAccessTokenV2
    })
    .catch(handleGraphQLErrors)
}
