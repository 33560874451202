import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { Card } from 'antd'
import { State } from '../../../../../redux/State'
import { BLUE_DARK } from '../../../../../style'
import { Caregiver } from '../../../../../redux/State/Caregiver'

interface StateProps {
  name: string
  dateOfBirth: string
  emailAddress: string
  telephoneNumber: string
}

export class CaregiverProfilePagePres extends React.Component<StateProps> {
  renderInfoField = (title: string, info?: string | null) => {
    return <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
      <div style={{ width: '30%', maxWidth: 150, marginRight: 5 }}>{title}:</div>
      <div style={{ color: BLUE_DARK }}>{info ?? '—'}</div>
    </div>
  }

  render () {
    const { dateOfBirth, emailAddress, name, telephoneNumber } = this.props
    return <Card>
      {this.renderInfoField('Name', name)}
      {this.renderInfoField('Date of Birth', dateOfBirth)}
      {this.renderInfoField('Telephone Number', telephoneNumber)}
      {this.renderInfoField('Email Address', emailAddress)}
    </Card>
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (
  state,
  props,
): StateProps => {
  const caregiver = Caregiver.get()
  const name = Caregiver.getName().switch('ERROR', 'Loading...', '-')
  const emailAddress = caregiver.map(c => c.profile.emailAddress).switch('ERROR', 'Loading...', '-')
  const dateOfBirth = caregiver.map(c => c.profile.dateOfBirth).switch('ERROR', 'Loading...', '-')
  const telephoneNumber = caregiver.map(c => c.profile.telephoneNumber).switch('ERROR', 'Loading...', '-')

  return { name, emailAddress, dateOfBirth, telephoneNumber }
}

export const CaregiverProfilePage = connect(mapStateToProps)(CaregiverProfilePagePres)
