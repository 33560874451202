import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Mode } from '../../../../components/RegAppForm/Mode'
import {
  RegAppForm,
  RegFormData
} from '../../../../components/RegAppForm'
import { Beta } from '../../../../redux/State/Beta'
import { submitClientApplication } from '../../../../redux/State/Client/Profile'
import { ClientInsuranceUpload, ClientProfileUpload } from '../../../../redux/State/Client/Profile/IClientProfile'
import { InsuranceProvider } from '../../../../redux/State/InsuranceProvider'
import { SyncPromise } from '../../../../redux/SyncPromise'
import {
  postInsuranceCoverage,
} from '../../secure/client/LeftColumn/Insurance/InsuranceForm/postInsuranceCoverage'
import { showNoDirectBillingWarning } from '../../secure/client/LeftColumn/Insurance/InsuranceForm/UpdateInsuranceForm'
import { State as RootState } from '../../../../redux/State'
import { I18n, getI18n } from '../../secure/client/LeftColumn/Insurance/index.i18n'

interface StateProps {
  beta: boolean
  insuranceProviders: SyncPromise<InsuranceProvider[]>
  i18n: I18n
  language: 'en-CA'|'fr-CA'
}

interface StateFormProps extends RouteComponentProps, StateProps {}

interface State {
  saving?: boolean
  submitError?: string
}

export class PagePres extends React.Component<StateFormProps, State> {
  state: State = {}
  render () {
    const { beta, insuranceProviders, i18n, language } = this.props
    const { submitError, saving } = this.state

    const handleSave = (data: RegFormData, insuranceData?: ClientInsuranceUpload) => {
      this.setState({ submitError: '', saving: true })
      submitClientApplication(data as ClientProfileUpload)
        .then((id: string|void) => {

          if (insuranceData && id ) {
            postInsuranceCoverage(insuranceData, undefined, id).then(() => {
              if ((document.getElementById('application_directBilling') as HTMLInputElement).value !== 'true') {
                showNoDirectBillingWarning(saveSuccess.bind(this, id), i18n)
              } else {
                saveSuccess(id)
              }
            }).catch(error => {
              console.log(error)
              this.setState({ saving: false, submitError: error.message })
            })
          } else {
            saveSuccess(id)
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({ saving: false, submitError: error.message })
        })
    }

    const saveSuccess = (id: string|void) => {
      this.setState({ saving: false })
      console.log('Client ID: ', id)
      this.props.history.push('/public/client-registration-applied')
    }

    return (
      <RegAppForm
        handleSave={handleSave}
        initialMode="rw"
        beta={beta}
        insuranceProviders={insuranceProviders.value ?? []}
        saving={saving}
        mode={Mode.APPLICATION}
        submitError={submitError}
        language={language}
      />
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (
  state,
  props,
): StateProps => {
  const beta = Beta.is()
  const insuranceProviders = InsuranceProvider.get()
  return { beta, insuranceProviders, i18n: getI18n(state.language), language: state.language }
}

export const ClientRegistrationApplicationPage = withRouter(connect(mapStateToProps)(PagePres))
