import './style.less'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { init } from './auth'
import buildInfo from './build-info.json'
import { initGtag } from './gtag'
import { Config } from './redux/State/Config'
import { App } from './screens/App'

init()
initGtag()

/** @deprecated */
console.log('Build Info', buildInfo)

Config.promise.then(({ MAINTENANCE }) => {
  if (!MAINTENANCE) return
  if (window.location.hash === '#/maintenance') return
  window.location.hash = '#/maintenance'
}).catch(console.error)

const rootElement = document.getElementById('root')
render(<App />, rootElement)
