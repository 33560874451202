import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { Maybe } from '../../Maybe'
import { State } from '../../redux/State'
import { Caregiver } from '../../redux/State/Caregiver'
import { Client } from '../../redux/State/Client'
import { BLACK, BLUE_DARK } from '../../style'
import Card from '../Card'
import UserIcon from './user.svg'

interface Props {}
interface StateProps {
  id: string
  name: string
  status?: Maybe<string>
}

export const UserProfileCardPres: React.FC<StateProps> = props => {
  const { id, name, status } = props
  return (
    <Card icon={UserIcon}>
      <div>
        <div style={{ color: BLUE_DARK }}>{name}{status && ` - ${status}`}</div>
        <div style={{ color: BLACK }}>ID {id}</div>
      </div>
    </Card>
  )
}

const mapStateToPropsClient: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const client = Client.get()
  const id = client.map(c => c.id).switch('ERROR', 'Loading...', 'No ID')
  const name = Client.getName().switch('ERROR', 'Loading...', 'No Name')
  const status = client.map(c => c.displayStatus).value
  return { id, name, status }
}

const mapStateToPropsCaregiver: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const caregiver = Caregiver.get()
  const id = caregiver.map(c => c.id).switch('ERROR', 'Loading...', 'No ID')
  const name = Caregiver.getName().switch('ERROR', 'Loading...', 'No Name')
  const status = Caregiver.get().map(c => c.status).value
  return { id, name, status }
}

export const ClientProfileCard = connect(mapStateToPropsClient)(UserProfileCardPres)
export const CaregiverProfileCard = connect(mapStateToPropsCaregiver)(UserProfileCardPres)
