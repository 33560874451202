import React from 'react'
import { BLUE_DARK, WHITE } from '../../style'
import { FooterCopyright } from './FooterCopyright'
import { FooterSiteMap } from './FooterSiteMap'

export const Footer = () =>
  <footer style={{ background: BLUE_DARK, color: WHITE }}>
    <FooterSiteMap/>
    <FooterCopyright/>
  </footer>
