import { Progress } from 'antd'
import React from 'react'

interface Props {
  loading: boolean
}

interface State {
  progress: number
}

class RegAppProgress extends React.Component<Props, State> {
  state = { progress: 0 }

  updateTick = 100

  componentDidMount () {
    this.props.loading && this.startProgress()
  }

  componentDidUpdate (prevProps: Props) {
    if (!prevProps.loading && this.props.loading) {
      this.startProgress()
    }
  }

  startProgress = () => {
    window.setInterval(() => {
      this.setState(prevState => ({ progress: (100 - (100 - prevState.progress) * 0.99) }))
    }, this.updateTick)
  }

  render () {
    if (!this.props.loading) return null

    return (
      <div style={{ maxWidth: 300 }}>
        <Progress percent={Math.round(this.state.progress)} />
      </div>
    )
  }
}

export default RegAppProgress
