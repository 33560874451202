export interface I18n {
  MY_PRIVACY: string
  AURORA_SAFEGUARD: string
  PRIVACY_POLICY_INFO: string
  PRIVACY_POLICY: string
  PRIVACY_POLICY_MORE_INFO: string
  ACCEPT_PRIVACY_POLICY: string
  PRIVACY_POLICY_ACCEPTED: string
  PRIVACY_POLICY_DECLINED: string
  PRIVACY_POLICY_TO_BE_ACCEPTED: string
  PRIVACY_POLICY_NO_LONGER_ACCEPTED: string
  ACCEPT_THIS_POLICY: string
  ACCEPT_AURORA_POLICY: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    MY_PRIVACY: frCA ? 'Ma vie privée' : 'My Privacy',
    AURORA_SAFEGUARD: frCA ? 'Aurora s\'engage à protéger les informations personnelles de ses patients.' : 'Aurora is committed to safeguarding the personal information of our patients.',
    PRIVACY_POLICY_INFO: frCA ? 'Notre politique de confidentialité explique quelles informations personnelles sont collectées, comment nous utilisons les informations collectées, comment les informations personnelles sont protégées et les choix que vous pouvez faire concernant l\'utilisation de vos informations personnelles.' : 'Our privacy policy explains what personal information is collected,\n' +
            '          how we use the information we collect, how personal information is\n' +
            '          protected, and the choices you can make about how your personal\n' +
            '          information is used.',
    PRIVACY_POLICY: frCA ? 'Politique de confidentialité' : 'Privacy Policy',
    PRIVACY_POLICY_MORE_INFO: frCA ? 'Pour plus d\'informations sur la manière dont vos données personnelles sont collectées et de leur utilisation, consultez la ' : 'For more information on how your personal information is collected and used, refer to the ',
    ACCEPT_PRIVACY_POLICY: frCA ? 'Si vous n\'acceptez pas la politique de confidentialité, vous ne pourrez pas effectuer d\'achats sur ce site.' : 'If you do not accept the privacy policy, you will not be able to make purchases on this website.',
    PRIVACY_POLICY_ACCEPTED: frCA ? 'Vous avez accepté la politique de confidentialité.' : 'You have accepted the privacy policy.',
    PRIVACY_POLICY_DECLINED: frCA ? 'Vous avez refusé la politique de confidentialité.' : 'You have declined the privacy policy.',
    PRIVACY_POLICY_TO_BE_ACCEPTED: frCA ? 'Vous n\'avez pas encore accepté la politique de confidentialité.' : 'You have not yet accepted the privacy policy.',
    PRIVACY_POLICY_NO_LONGER_ACCEPTED: frCA ? 'Je n\'accepte plus cette politique' : 'I no longer accept this policy',
    ACCEPT_THIS_POLICY: frCA ? 'J\'accepte cette politique de confidentialité' : 'I accept this policy',
    ACCEPT_AURORA_POLICY: frCA ? 'J\'accepte la politique de confidentialité d\'Aurora' : 'I accept Aurora Privacy Policy',
  }
}
