export interface I18n {
  RELATIONSHIP_TO_CARDHOLDER: string
  DO_YOU_HAVE_INSURANCE_COVERAGE: string
  GROUP_PLAN: string
  GROUP_PLAN_POLICY_NUMBER: string
  INSURANCE_COVERAGE: string
  INSURANCE_COVERAGE_TOOLTIP: string
  GROUP_PLAN_TOOLTIP: string
  INSURANCE_PROVIDER: string
  INSURANCE_PROVIDER_TOOLTIP: string
  GROUP_PLAN_POLICY_NUMBER_TOOLTIP: string
  CERTIFICATE_NUMBER: string
  CERTIFICATE_NUMBER_TOOLTIP: string
  CARDHOLDER_ID: string
  CARDHOLDER_ID_TOOLTIP: string
  CHECKBOX_I_AM_A_VETERAN: string
  K_NUMBER: string
  TO_UPDATE_DETAILS_CALL: string
  SUBMIT: string
  LOADING: string
  CANCEL: string
  THANK_YOU_FOR_SUBMITTING_INSURANCE_COVERAGE_DETAILS_TITLE: string
  THANK_YOU_FOR_SUBMITTING_INSURANCE_COVERAGE_DETAILS_TEXT: string
  VAC: string
  NONE: string
  CARDHOLDER: string
  SPOUSE: string
  UNDERAGE_CHILD: string
  OVERAGE_CHILD: string
  DISABLED_DEPENDENT: string
  DEPENDENT_STUDENT: string
  UNKNOWN: string
  PLEASE_SELECT: string
  INSURER_NOT_LISTED: string
  OTHER: string
  SELECT_RELATIONSHIP: string
  LOADING_GROUP_PLANS: string
  CERTIFICATE_ERROR: string
  SUBMIT_K_NUMBER: string
  VETERAN_COVERAGE: string
  FINISH: string
  RECEIVED_APPLICATION: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    RELATIONSHIP_TO_CARDHOLDER: frCA ? 'Relation avec le titulaire de la carte' : 'Relationship to Cardholder',
    DO_YOU_HAVE_INSURANCE_COVERAGE: frCA ? 'Avez-vous une couverture d\'assurance?' : 'Do you have insurance coverage?',
    GROUP_PLAN: frCA ? 'Régime collectif' : 'Group Plan',
    GROUP_PLAN_POLICY_NUMBER: frCA ? 'Régime collectif/ Numéro de police d\'assurance' : 'Group Plan/ Policy Number',
    INSURANCE_COVERAGE: frCA ? 'Couverture d\'assurance' : 'Insurance Coverage',
    // eslint-disable-next-line @typescript-eslint/quotes
    INSURANCE_COVERAGE_TOOLTIP: frCA ? `Veuillez spécifier votre relation avec l'assuré, s'il s'agit de votre propre plan, veuillez sélectionner «Titulaire de la carte», sinon sélectionnez la relation correspondante.` : 'Please specify your relationship to the policyholder, if this is your own plan, please select ‘Cardholder’, otherwise select the correct relationship.',
    // eslint-disable-next-line @typescript-eslint/quotes
    GROUP_PLAN_TOOLTIP: frCA ? `Si vous ne trouvez pas votre régime collectif/police d'assurance dans cette liste, Aurora Cannabis n'a pas encore conclu d'accord pour facturer directement. En attendant, veuillez saisir votre numéro de police/plan. Si un accord est conclu pour facturer directement avec votre plan, vous serez automatiquement intégré à ce moment-là.` : 'If you are unable to find your Group Plan in this list, Aurora Cannabis does not yet have an agreement in place to direct bill. In the interim, please enter your policy/plan number, if an agreement is made to direct bill with your plan, you will be onboarded automatically at that point.',
    INSURANCE_PROVIDER: frCA ? 'Fournisseur d\'assurance' : 'Insurance Provider',
    // eslint-disable-next-line @typescript-eslint/quotes
    INSURANCE_PROVIDER_TOOLTIP: frCA ? `Recherchez votre assureur. Si vous ne parvenez pas à trouver votre fournisseur dans cette liste, Aurora Cannabis n'a pas encore conclu d'accord pour facturer directement. Si vous pensez que cela est incorrect, veuillez contacter le service client pour en discuter.` : 'Search for your insurance provider. If you are unable to find your provider in this list, Aurora Cannabis does not yet have an agreement in place to direct bill. If you believe this is incorrect, please contact client care to discuss further.',
    // eslint-disable-next-line @typescript-eslint/quotes
    GROUP_PLAN_POLICY_NUMBER_TOOLTIP: frCA ? `Si votre assureur ne figure pas dans la liste, nous n'avons pas d'accord de facturation directe en place et ne sommes pas en mesure d'ajouter vos informations d'assurance pour le moment. Si vous pensez que cela est incorrect, veuillez contacter le service client au 1-877-928-7672` : 'If your insurer is not listed, we do not have a direct billing agreement in place and are unable to add your insurance information at this time. If you believe this is incorrect, please contact Client Care on 1-877-928-7672',
    CERTIFICATE_NUMBER: frCA ? 'Numéro de certificat' : 'Certificate number',
    // eslint-disable-next-line @typescript-eslint/quotes
    CERTIFICATE_NUMBER_TOOLTIP: frCA ? `Veuillez entrer le numéro de certificat complet de vos informations de couverture (le cas échéant, inclure toutes les lettres)` : 'Please enter the complete certificate number from your coverage information, (if applicable, include all letters)',
    CARDHOLDER_ID: frCA ? 'Identifiant du titulaire de la carte' : 'Cardholder ID',
    // eslint-disable-next-line @typescript-eslint/quotes
    CARDHOLDER_ID_TOOLTIP: frCA ? `Veuillez saisir l'identifiant du titulaire de la carte, généralement les 5 premières lettres du nom de famille du titulaire de la carte.` : 'Please enter your cardholder ID, typically the first 5 letters of the cardholder\'s surname.',
    CHECKBOX_I_AM_A_VETERAN: frCA ? 'Je suis un vétéran et Aurora peut partager mes informations avec VAC' : 'I am a veteran and Aurora may share my information with VAC',
    K_NUMBER: frCA ? 'Numéro K' : 'K Number',
    TO_UPDATE_DETAILS_CALL: frCA ? 'Pour mettre à jour vos coordonnées, veuillez appeler le service client au' : 'To update your details, please call client care on',
    SUBMIT: frCA ? 'Soumettre' : 'Submit',
    LOADING: frCA ? 'Chargement...' : 'Loading...',
    CANCEL: frCA ? 'Annuler' : 'Cancel',
    // eslint-disable-next-line @typescript-eslint/quotes
    THANK_YOU_FOR_SUBMITTING_INSURANCE_COVERAGE_DETAILS_TITLE: frCA ? `Merci d'avoir soumis les détails de votre couverture d'assurance.` : 'Thank you for submitting your insurance coverage details.',
    // eslint-disable-next-line @typescript-eslint/quotes
    THANK_YOU_FOR_SUBMITTING_INSURANCE_COVERAGE_DETAILS_TEXT: frCA ? `Merci d'avoir soumis les détails de votre couverture d'assurance. 
    Votre plan actuel n'a pas de lien direct de facturation mis en place avec Aurora Cannabis, par conséquent, vous devez continuer à soumettre manuellement les réclamations à votre assureur. 
    Nous vous contacterons si cela change à l'avenir.` : `Thank you for submitting your insurance coverage details. Your current plan does not have a direct billing agreement in place with Aurora Cannabis,
    therefore you must continue to manually submit claims to your insurer. We will contact you if this changes in the future.`,
    VAC: frCA ? 'ACC' : 'VAC',
    NONE: frCA ? 'Aucun' : 'None',
    CARDHOLDER: frCA ? 'Titulaire de la carte' : 'Cardholder',
    SPOUSE: frCA ? 'Conjoint(e)' : 'Spouse',
    UNDERAGE_CHILD: frCA ? 'Enfant mineur' : 'Underage Child',
    OVERAGE_CHILD: frCA ? 'Enfant excédentaire' : 'Overage Child',
    DISABLED_DEPENDENT: frCA ? 'Handicapée Dépendante' : 'Disabled Dependent',
    DEPENDENT_STUDENT: frCA ? 'Étudiant à charge' : 'Dependent-Student',
    UNKNOWN: frCA ? 'Inconnu' : 'Unknown',
    PLEASE_SELECT: frCA ? 'Veuillez sélectionner' : 'Please select',
    INSURER_NOT_LISTED: frCA ? 'Si votre assureur ne figure pas dans la liste, veuillez nous contacter à l\'adresse suivante' : 'If your insurer is not listed, please contact us at',
    OTHER: frCA ? 'Autre' : 'Other',
    SELECT_RELATIONSHIP: frCA ? 'Sélectionner la relation' : 'Select Relationship',
    LOADING_GROUP_PLANS: frCA ? 'Veuillez patienter, chargement des plans de groupe...' : 'Please wait, loading group plans...',
    CERTIFICATE_ERROR: frCA ? 'Champ obligatoire, veuillez saisir les données, entrées alphanumériques, 15 caractères maximum' : 'Mandatory field, please enter data, alphanumeric inputs, 15 character maximum',
    SUBMIT_K_NUMBER: frCA ? 'Veuillez indiquer un numéro K valide' : 'Please submit a valid K number',
    VETERAN_COVERAGE: frCA ? 'Couverture Anciens combattants' : 'VAC Coverage',
    FINISH: frCA ? 'Finish' : 'Finish',
    RECEIVED_APPLICATION: frCA ? 'We’ve received your application! We will now review it and contact you if necessary.' : 'We’ve received your application! We will now review it and contact you if necessary.',
  }
}
