import { Card } from 'antd'
import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { State } from '../../../../../../redux/State'
import { IClientPrescription } from '../../../../../../redux/State/Client/Prescription/IClientPrescription'
import { Client } from '../../../../../../redux/State/Client/index'
import { SyncPromise } from '../../../../../../redux/SyncPromise'
import { BLUE_DARK } from '../../../../../../style'
import PrescriptionProgress from './PrescriptionProgress'
import { formatDate } from './formatDate'
import { I18n, getI18n } from '../../RightColumn/PrescriptionCard/index.i18n'

interface Props {}
interface StateProps {
  i18n: I18n
  prescription: SyncPromise<IClientPrescription>
}

interface IStyles {
  [key: string]: React.CSSProperties
}

const styles: IStyles = {
  card: {
    padding: '20px 0',
    margin: '0 0 10px 0',
  },
  cardContent: {
    width: 240,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    fontSize: 16,
  },
}

export const PrescriptionCardPres: React.FC<StateProps> = (props) => {
  const { error, loading, value } = props.prescription
  const i18n = props.i18n
  const {
    allowanceTotal,
    allowanceRemaining,
    allowancePerDay,
    endDate,
  } = value ?? {
    allowancePerDay: 0,
    allowanceRemaining: 0,
    allowanceTotal: 0,
    endDate: undefined,
  }

  return (
    <Card style={styles.card}>
      <div style={styles.cardContent}>
        <h2>{i18n.MY_PRESCRIPTION}</h2>
        <div style={{ marginBottom: 15 }}>
          <PrescriptionProgress
            value={allowanceRemaining}
            maxValue={allowanceTotal}
            animating
            style={{ marginBottom: 10 }}
          />
          <div>
            <span style={styles.blueText}>{allowanceRemaining} / {allowanceTotal}g</span> {i18n.REMAINING}
          </div>
          <div>
            <span style={styles.blueText}>{allowancePerDay} {i18n.GRAMS}</span> {i18n.PER_DAY}
          </div>
        </div>
        {loading && <div>Loading...</div>}
        {error && <div>{i18n.ERROR}</div>}
        {endDate && <div>
          <span>{i18n.EXPIRATION_DATE}: </span>
          <span style={{ color: BLUE_DARK }}>{formatDate(endDate)}</span>
        </div>}
      </div>
    </Card>
  )
}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const prescription = Client.get()
    .map(client => client.prescription)
  return { i18n: getI18n(state.language), prescription }
}

export const PrescriptionCard = connect(mapStateToProps)(PrescriptionCardPres)
