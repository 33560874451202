import { FetchResult } from 'apollo-link'
import gql from 'graphql-tag'
import { getApolloClient, handleGraphQLErrors } from '../getApolloClient'

export async function requestForgotPasswordEmail (emailAddress: string): Promise<void> {
  type Result = FetchResult<{sendPasswordResetEmail: never}>
  const resetPasswordUrl = window.location.origin + '/#/reset-password'
  return getApolloClient()
    .then(apolloClient =>
      apolloClient.mutate({
        mutation: gql`
          mutation ($emailAddress: String!, $resetPasswordUrl: String!){
            sendPasswordResetEmail(
              emailAddress: $emailAddress,
              resetPasswordUrl: $resetPasswordUrl
            )
          }
        `,
        variables: { emailAddress, resetPasswordUrl },
      }),
    )
    .then(results => {
      const result = results as Result
      if (result.errors) {
        console.error({ result })
        throw result.errors[0]
      }
      if (!result.data) throw new Error('!result.data')
    })
    .catch(handleGraphQLErrors)
}
