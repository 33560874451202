import React, { CSSProperties } from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Maybe } from '../../../../../Maybe'
import Button from '../../../../../components/Button'
import Card from '../../../../../components/Card'
import { State } from '../../../../../redux/State'
import { Client } from '../../../../../redux/State/Client'
import { Config } from '../../../../../redux/State/Config'
import { PrescriptionCard } from './PrescriptionCard'
import { ClientProfileCard } from '../../../../../components/UserProfileCard'
import { I18n, getI18n } from './index.i18n'

interface Props {
  style?: CSSProperties
}

type StateProps = Props & {
  config: Maybe<Config>
  isClientActive: boolean
  isPrivacyAccepted: boolean
  i18n: I18n
}

const navBtnStyle: CSSProperties = {
  fontSize: 14,
  minWidth: 120,
}

export class RightColumnPres extends React.Component<StateProps> {
  gotoOrders = () => {
    const url = this.props.config?.URL_MY_ORDERS
    if (!url) throw new Error('!url')
    window.location.href = url
  }

  gotoShop = () => {
    const url = this.props.config?.URL_SHOP
    if (!url) throw new Error('!url')
    window.location.href = url
  }

  render () {
    const { config, style, isClientActive, isPrivacyAccepted, i18n } = this.props
    const message =
      !isClientActive ? <>Shopping Available once Application Approved</>
        : !isPrivacyAccepted ? <>You have indicated that you do not accept the Privacy Policy. You will be unable to make purchases on the website until you accept the <Link to='/secure/client/privacy' >Privacy Policy</Link>.</>
          : !config ? <>Loading...</>
            : undefined
    const ordersDisabled = !isPrivacyAccepted || !config
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flex: 1,
        ...style,
      }}>
        <ClientProfileCard />
        <PrescriptionCard />
        <Card style={{ flexDirection: 'column', padding: '30px 10px' }}>
          {message &&
           <span style={{ marginBottom: 7, fontSize: 12, textAlign: 'center' }}>
             {message}
           </span>
          }
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button onClick={this.gotoShop} disabled={!!message} style={navBtnStyle}>{i18n.SHOP}</Button>
            <Button onClick={this.gotoOrders} disabled={ordersDisabled} style={navBtnStyle}>{i18n.ORDERS}</Button>
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const config = Config.get().value
  const isClientActive =
    Client.get().value?.registrationStatus === 'Active'
  const isPrivacyAccepted =
    Client.get().value?.profile.consent.privacy?.value ?? false

  return {
    i18n: getI18n(state.language),
    config,
    isClientActive,
    isPrivacyAccepted,
  }
}

// @ts-ignore
export const RightColumn: React.ComponentClass<Props> = connect(mapStateToProps)(RightColumnPres)
