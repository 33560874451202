import { Maybe } from '../../../Maybe'
import { SyncPromise } from '../../SyncPromise'
import { User } from '../User'
import { Client as IClient, ClientDownload } from './IClient'
import { ClientProfile } from './Profile'
import { InsuranceKeys } from './Profile/IClientProfile'

export type Client = IClient

export const Client = {
  calcName: (c: Client): string => {
    const a = c.profile
    return `${a.firstName} ${a.lastName}`
  },
  fromDownload: (dl: Maybe<ClientDownload>): Maybe<Client> => {
    return dl && {
      ...dl,
      profile: ClientProfile.fromDownload(dl.profile),
    }
  },
  get: (): SyncPromise<Client> =>
    User.get().map(u => u.client),
  getName: (): SyncPromise<string> =>
    Client.get().map(Client.calcName),
  isActive (): SyncPromise<boolean> {
    return Client.get().map(c => c.registrationStatus === 'Active')
  },
  getCallToActionMessage: (): string[] | undefined => Client.get().value?.callToActionMessage,
  getInsuranceCoverage (): SyncPromise<InsuranceKeys> {
    return Client.get().map(c => {
      return {
        kNumber: c.profile?.insurance?.vac?.kNumber,
        insuranceCoverageUUID: c.profile?.insurance?.private?.insuranceCoverageUUID,
      }
    })
  },
}
