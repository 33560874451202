import React from 'react'
import { BLACK, BLUE_DARK, BLUE } from '../../style'
import Card from '../Card'
import bellIcon from './icons/bell.svg'
import closeIcon from './icons/close-icon.svg'

interface IProps {
  type?: 'default' | 'success' | 'error'
  title: string
  text: string
  icon?: any
  iconStyle?: React.CSSProperties
  onClose(): void
}
interface IStyles {
  [key: string]: React.CSSProperties
}

const styles: IStyles = {
  container: {
    position: 'relative',
    paddingRight: 35,
  },
  title: { color: BLUE_DARK },
  text: { color: BLACK },
  closeBtn: {
    position: 'absolute',
    top: 5,
    right: 5,
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: 'rgb(248, 248, 248)',
    border: 'none',
    outline: 'none',
  },
  closeIcon: {
    width: 12,
    height: 12,
  },
}

// TODO: change icons for different types
const notifConfig = {
  default: {
    icon: bellIcon,
    bgColor: BLUE,
  },
  success: {
    icon: bellIcon,
    bgColor: 'green',
  },
  error: {
    icon: bellIcon,
    bgColor: 'red',
  },
}

const Notification: React.FC<IProps> = ({
  type = 'default',
  title,
  text,
  icon,
  iconStyle,
  onClose,
}) => {
  const notifObj = notifConfig[type]
  const notifIcon = icon || notifObj.icon

  return (
    <Card
      style={styles.container}
      icon={notifIcon}
      iconContainerStyle={{ backgroundColor: notifObj.bgColor }}
      iconStyle={iconStyle}
    >
      <div>
        <div style={styles.title}>{title}</div>
        <div style={styles.text}>{text}</div>
      </div>
      <button style={styles.closeBtn} onClick={onClose}>
        <img src={closeIcon} alt="" style={styles.closeIcon} />
      </button>
    </Card>
  )
}

export default Notification
