export interface I18n {
  MINIMUM_BIRTH: string
  APPLICANT_INFORMATION: string
  FIRST_NAME: string
  LAST_NAME: string
  MALE: string
  FEMALE: string
  OTHER: string
  UNDISCLOSED: string
  LANGUAGE: string
  DATE_BIRTH: string
  PHONE: string
  EMAIL: string
  HAVE_PHYSICIAN: string
  INSURANCE_INFORMATION: string
  PARTICIPATE_IN_RESEARCH: string
  OFFER_SPECIAL_PROMOTIONS: string
  ACKNOWLEDGEMENT: string
  ACKNOWLEDGEMENT0: string
  ACKNOWLEDGEMENT1: string
  ACKNOWLEDGEMENT2: string
  ACKNOWLEDGEMENT3: string
  ACKNOWLEDGEMENT4: string
  ACKNOWLEDGEMENT5: string
  ACKNOWLEDGEMENT6: string
  ACKNOWLEDGEMENT7: string
  ACKNOWLEDGEMENT8: string
  ACKNOWLEDGEMENT9: string
  ACKNOWLEDGEMENT10: string
  ACKNOWLEDGEMENT11: string
  CORRECT_COMPLETE: string
  ACCEPT: string
  AURORA_PRIVACY_POLICY: string
  SHIPPING_ADDRESS: string
  MAILING_ADDRESS: string
  RESIDENTIAL_ADDRESS: string
  MAILING_ADDRESS_SAME: string
  SHIP_TO: string
  AMENDMENTS_SUSPEND: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    MINIMUM_BIRTH: frCA ? 'L\'année de naissance minimale possible est' : 'Minimum possible year of birth is',
    APPLICANT_INFORMATION: frCA ? 'Information du demandeur' : 'Applicant Information',
    FIRST_NAME: frCA ? 'Prénom' : 'First Name',
    LAST_NAME: frCA ? 'Nom de famille' : 'Last Name',
    MALE: frCA ? 'Homme' : 'Male',
    FEMALE: frCA ? 'Femme' : 'Female',
    OTHER: frCA ? 'Autre' : 'Other',
    UNDISCLOSED: frCA ? 'Non dévoilé' : 'Undisclosed',
    LANGUAGE: frCA ? 'Langue' : 'Language',
    DATE_BIRTH: frCA ? 'Date de naissance (AAAA-MM-JJ)' : 'Date of Birth (YYYY-MM-DD)',
    PHONE: frCA ? 'Numéro de téléphone' : 'Phone Number',
    EMAIL: frCA ? 'Adresse courriel' : 'E-Mail Address',
    HAVE_PHYSICIAN: frCA ? 'J\'ai un médecin pour prescrire du cannabis' : 'I have a physician to prescribe cannabis',
    INSURANCE_INFORMATION: frCA ? 'Information sur l\'assurance' : 'Insurance Information',
    PARTICIPATE_IN_RESEARCH: frCA ? 'AuroraMD pourrait vous inviter à participer à des projets de recherche, comme des études cliniques et des groupes de discussion. Veuillez cocher la case si vous ne souhaitez pas recevoir ces courriels.'
      : 'Aurora® may contact you to participate in patient research, such as clinical studies and focus groups. Please check the box if you wish to be excluded from these emails.',
    OFFER_SPECIAL_PROMOTIONS: frCA ? 'Les patients abonnés à l’infolettre d’AuroraMD reçoivent des offres promotionnelles, des rabais et d’autres avantages.  Veuillez cocher la case si vous ne souhaitez pas recevoir ces courriels.'
      : 'We offer special promotions, discounts and more to patients subscribed to the Aurora® newsletter. Please check the box if you wish to be excluded from these emails.',
    ACKNOWLEDGEMENT0: frCA ? `Aurora Cannabis Enterprises Inc. (« AuroraMD ») est tenue de recueillir des renseignements sur le demandeur en vertu de la
     Loi sur le cannabis, avec ses modifications successives. AuroraMD recueille, utilise et divulgue les renseignements personnels conformément aux 
     lois fédérales et provinciales sur la protection des renseignements personnels, à la Loi sur le cannabis et à la politique de confidentialité d’Aurora.
      AuroraMD accorde une grande importance au respect de votre vie privée. Tout renseignement obtenu par votre acceptation à recevoir des correspondances
       de la part d’AuroraMD sera conservé et divulgué conformément à notre politique de confidentialité que vous pouvez consulter dans son intégralité sur notre site Web.`
      : `Aurora Cannabis Enterprises Inc. (“Aurora”) is required to collect the information of the Applicant pursuant to the Cannabis Act
        as may be amended from time to time. Aurora collects, uses and discloses personal information only in accordance with the provisions of the Personal
         Information Protection and Electronic Documents Act, the Alberta Personal Information Protection Act, the Cannabis Act, and Aurora’s Privacy Policy.
          At Aurora we take your privacy seriously. Any information collected by opting in to receive correspondence from Aurora is retained and disclosed
           in strict accordance to our privacy policy which can be viewed in full on our website.`,
    ACKNOWLEDGEMENT1: frCA ? `Le demandeur reconnaît que le cannabis à des fins médicales n’est pas approuvé au Canada à titre de médicament et que les doses 
    appropriées et les risques associés n’ont pas été déterminés. Le demandeur reconnaît qu’il fait usage de cannabis à des fins médicales à ses propres
     risques et qu’AuroraMD ne peut être tenue responsable de tout dommage, perte ou blessure résultant directement ou indirectement de cet usage.`
      : `The Applicant acknowledges that medical cannabis is not approved for use as a drug in Canada and that its risks and appropriate dosages have not
       been determined. The Applicant acknowledges that he/ she is using medical cannabis at his/her own risk and that Aurora is not liable for any damages,
        loss, or injury whatsoever that results, either directly or indirectly, from the use of medical cannabis.`,
    ACKNOWLEDGEMENT2: frCA ? `Le demandeur reconnaît que certains des renseignements fournis dans ce document pourront être communiqués à nos fournisseurs 
    de services à des fins d’expédition seulement.`
      : 'The Applicant acknowledges that some of the information provided in this document may be shared with our service providers for shipping purposes only.',
    ACKNOWLEDGEMENT3: frCA ? `Le demandeur reconnaît que, dans le cas où il est recommandé à AuroraMD par un tiers intermédiaire 
    (c.-à-d., un médecin ou une clinique), AuroraMD pourrait communiquer certains des renseignements personnels recueillis, y compris ceux inscrits dans 
    le présent document, à ce tiers intermédiaire.`
      : `The Applicant acknowledges that, where the Applicant has been referred to Aurora® by a third-party intermediary (i.e. your physician/ clinic), 
      Aurora® may share some personal information collected by Aurora®, including information provided in this document, with the applicable third-party intermediary.`,
    ACKNOWLEDGEMENT4: frCA ? 'Le demandeur réside habituellement au Canada.'
      : 'The Applicant ordinarily resides in Canada.',
    ACKNOWLEDGEMENT5: frCA ? 'Le demandeur utilisera le cannabis à des fins médicales exclusivement pour ses propres besoins médicaux.'
      : 'The Applicant will use medical cannabis only for their own medical purposes.',
    ACKNOWLEDGEMENT6: frCA ? `Les renseignements indiqués dans ce formulaire de demande et dans le document médical ou l’attestation d’inscription 
    sont véridiques et complets.`
      : 'The information in this application and the Medical Document or Registration Certificate is correct and complete.',
    ACKNOWLEDGEMENT7: frCA ? `Le document médical original ou l’attestation d’inscription (pour un approvisionnement provisoire ou de matières premières) 
    que vous ou votre médecin soumettez à AuroraMD n’est pas utilisé pour vous procurer du cannabis à des fins médicales auprès d’un autre fournisseur.`
      : `The original Medical Document or Registration Certificate (for interim supply or starting materials) submitted to Aurora® by yourself 
      or your physician is not being used to seek or obtain medical cannabis from another source.`,
    ACKNOWLEDGEMENT8: frCA ? 'Le document médical original ou l’attestation d’inscription accompagne cette demande.'
      : 'The original of the Medical Document or Registration Certificate accompanies the application.',
    ACKNOWLEDGEMENT9: frCA ? `Le demandeur accepte qu’il soit possible qu’Aurora recueille, utilise, divulgue et stocke ses renseignements personnels
     et les renseignements personnels sur sa santé fournis par le demandeur, par l’aidant ou la personne responsable du demandeur ou par le ou les
      professionnels de la santé du demandeur (collectivement appelés les « renseignements » du demandeur) afin de déterminer l’admissibilité du demandeur 
      et de son inscription à titre de client d’AuroraMD, de traiter les commandes et transmettre de l’information au sujet d’AuroraMD et de ses produits 
      et services, et de recevoir et traiter les paiements du demandeur, ou en son nom, s’il y a lieu.`
      : `The Applicant agrees that Aurora® may collect, use, disclose and store their personal information and personal health information provided by the 
      Applicant, their caregiver or their health care professional(s) (collectively, the Applicant’s “information”) to determine their eligibility for, 
      and registration as, a client of Aurora®, and for the purpose of filing orders and providing information about Aurora® and its products and services 
      and for the purpose of obtaining and processing payments by, or on behalf of, the Applicant as applicable.`,
    ACKNOWLEDGEMENT10: frCA ? `Le demandeur reconnaît que, s’il y a lieu, AuroraMD pourrait communiquer et partager de l’information avec la société 
    d’assurance maladie, le syndicat ou l’assureur privé du demandeur afin de maximiser le ou les garanties offertes au demandeur.`
      : `The Applicant acknowledges that, when applicable, Aurora® may speak with, and share information with, the Applicant’s health insurer, union, 
      or private benefit provider to maximize the available benefit(s) to the Applicant.`,
    ACKNOWLEDGEMENT11: frCA ? `Le demandeur reconnaît que la modification de son adresse pourrait entraîner le transfert de son inscription vers
     un autre de nos sites autorisés. Ce transfert aura pour seul effet de modifier le site de provenance de ses produits. En envoyant ce formulaire, 
     le demandeur consent à un tel transfert, s’il y a lieu.`
      : `The applicant acknowledges your address change may require us to transfer your registration between our licensed sites. 
      This will not affect you other than your product will be fulfilled from a different site. By submitting this form you consent to such 
      transfer if required.`,
    ACKNOWLEDGEMENT: frCA ? 'Reconnaissance' : 'Acknowledgement',
    CORRECT_COMPLETE: frCA ? 'Les renseignements indiqués dans ce formulaire de demande et dans le document médical sont véridiques et complets.'
      : 'The information in this application and the Medical Document is correct and complete.',
    ACCEPT: frCA ? 'J\'accepte' : 'I accept',
    AURORA_PRIVACY_POLICY: frCA ? 'Politique de confidentialité d\'Aurora' : 'Aurora Privacy Policy',
    RESIDENTIAL_ADDRESS: frCA ? 'Adresse Résidentielle' : 'Residential Address',
    SHIPPING_ADDRESS: frCA ? 'Adresse de Livraison' : 'Shipping Address',
    MAILING_ADDRESS: frCA ? 'Adresse Postale' : 'Mailing Address',
    MAILING_ADDRESS_SAME: frCA ? 'L\'adresse postale est la même que l\'adresse résidentielle' : 'Mailing Address is the same as Residential Address',
    SHIP_TO: frCA ? 'Expédier à' : 'Ship to',
    AMENDMENTS_SUSPEND: frCA ? 'Les modifications suspendront le compte jusqu\'à ce qu\'elles soient vérifiées par l\'équipe du service clientèle.'
      : 'Amendments will suspend the account until the change can be verified by the Client Care Team.',
  }
}
