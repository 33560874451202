import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { State } from '../../../../../redux/State'
import { Beta } from '../../../../../redux/State/Beta'
import Card from '../../../../../components/Card'
import { Link } from 'react-router-dom'

interface Props {}
interface StateProps {
  beta: boolean
}

interface StateRouteProps extends RouteComponentProps, StateProps {}

export class ClinicianPagePres extends React.Component<StateRouteProps> {
  render () {
    const { beta } = this.props
    return (
      <Card style={{ flexDirection: 'column' }}>
        <div>
          <p>
          Welcome, Clinician
          </p>
          <Link to='./clinician/referClient' style={{ textDecoration: 'underline' }}>
              Refer a Client
          </Link>
        </div>
        {beta && <div style={{ padding: '1em 0' }}>
          <Link to='./clinician/clientmatching' style={{ textDecoration: 'underline' }}>
            Beta Client Matching
          </Link>
        </div>}
      </Card>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const beta = Beta.is()
  return { beta }
}

export const ClinicianProfilePage = connect(mapStateToProps)(withRouter(ClinicianPagePres))
