import { createStore } from 'redux'
import { Action } from './Action'
import { State } from './State'
import { reduce } from './reduce'

export const store = createStore<State, Action, unknown, unknown>(
  reduce,
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)
