import { SyncPromise } from '../SyncPromise'
import { Config } from './Config/IConfig'
import { InsuranceProvider } from './InsuranceProvider'
import { User } from './User'
import { IInsuranceCoverage } from './Client/Profile/IClientProfile'

export interface State {
  readonly beta: boolean
  readonly config: SyncPromise<Config>
  readonly insuranceProviders: SyncPromise<InsuranceProvider[]>
  readonly language: 'en-CA'|'fr-CA'
  readonly user: SyncPromise<User>
  readonly insuranceCoverage: SyncPromise<IInsuranceCoverage>
}

export const newState = (): State => ({
  beta: false,
  config: SyncPromise.UNINIT,
  insuranceProviders: SyncPromise.UNINIT,
  language: 'en-CA',
  user: SyncPromise.UNINIT,
  insuranceCoverage: SyncPromise.UNINIT,
})
