import { Action } from './Action'
import { Reducer } from './Reducer'
import { State } from './State'

const registry: Reducer[] = []

export const register = (r: Reducer): void => {
  registry.push(r)
}

/**
 * Iterate over the registry and return the first truthy value
 */
export const some = (state: State, action: Action): State|undefined => {
  let value
  registry.some(r => {
    value = r(state, action)
    return !!value
  })
  return value
}
