import { CSSProperties } from 'react'
import { BLACK, BLUE_DEEP } from '../../style'

export const wrapperStyle: CSSProperties = {
  position: 'relative',
  borderRadius: 10,
  overflow: 'hidden',
  marginBottom: 5,
}

export const generalFieldStyle: CSSProperties = {
  height: 60,
  width: '100%',
  border: '1px solid rgba(175, 177, 175, 0.5)',
  borderRadius: 10,
  fontSize: 16,
}

export const inputStyle: CSSProperties = {
  ...generalFieldStyle,
  padding: '30px 14px 10px 14px',
  color: BLACK,
  outline: 'none',
}

export const textareaStyle: CSSProperties = {
  width: '100%',
  height: 'auto',
  lineHeight: '22px',
  padding: '0 14px',
  color: BLACK,
  outline: 'none',
  border: 'none',
}

export const checkboxStyle: CSSProperties = {
  ...generalFieldStyle,
  height: 'auto',
  minHeight: 60,
  display: 'flex',
  alignItems: 'center',
  lineHeight: '22px',
  padding: '0px 14px',
  color: BLUE_DEEP,
}

export const labelStyle: CSSProperties = {
  position: 'absolute',
  pointerEvents: 'none',
  left: 14,
  top: 10,
  transition: '300ms ease all',
}
