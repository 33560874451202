import { Maybe } from '../../../Maybe'
import { SyncPromise } from '../../SyncPromise'
import { Config } from '../Config'
import { User } from '../User'
import {
  Caregiver as ICaregiver,
  CaregiverClient,
  CaregiverDownload,
} from './ICaregiver'
import { getImpersonationToken } from './getImpersonationToken'

export type Caregiver = ICaregiver

export const Caregiver = {
  calcName: (c: Caregiver): string => {
    const a = c.profile
    return `${a.firstName} ${a.lastName}`
  },
  fromDownload: (dl: Maybe<CaregiverDownload>): Maybe<Caregiver> => {
    return dl && {
      ...dl,
    }
  },
  get: (): SyncPromise<Caregiver> =>
    User.get().map(u => u.caregiver),
  getName: (): SyncPromise<string> =>
    Caregiver.get().map(Caregiver.calcName),
  getClients: (): SyncPromise<CaregiverClient[]> =>
    Caregiver.get().map((c: Caregiver) => c.clients),
  isActive: (): SyncPromise<boolean> =>
    Caregiver.get().map(c => c.status === 'Active'),
  shopAsClient: async (clientId: string) => {
    const token: string = await getImpersonationToken(clientId)
    const config = Config.get().value
    if (!config) throw new Error('!config')
    const href = config.URL_SHOPIFY + '?access_token=' + token
    window.location.href = href
  },
}
