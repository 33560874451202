import { InMemoryCache, NormalizedCacheObject, ApolloClient } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { GraphQLError } from 'graphql/error/GraphQLError'
import { Maybe } from './Maybe'
import { AccessToken } from './auth'
import { Config } from './redux/State/Config'

let acp: Promise<ApolloClient<NormalizedCacheObject>>

export const getApolloClient = () => {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  if (!acp) {
    acp = Config.promise.then(({ URL_CARE_PORTAL_BE }) =>
      new ApolloClient({
        cache: new InMemoryCache(),
        defaultOptions: { query: { fetchPolicy: 'no-cache' } },
        link: createUploadLink({ uri: URL_CARE_PORTAL_BE }),
      }),
    )
  }
  return acp
}

export async function handleGraphQLErrors (error: any): Promise<never> {
  console.warn(error)
  const graphQLErrors: Maybe<GraphQLError[]> = error.graphQLErrors
  if (!graphQLErrors?.length) {
    return Promise.reject(error)
  }
  if (graphQLErrors.some(error => error.extensions?.code === 'FORBIDDEN')) {
    AccessToken.onExpiration().catch(console.error)
  }
  if (graphQLErrors[0].extensions?.exception?.code === 'DPE-876c73e1-39cd-49c5-a409-2bd7098b9761') {
    return Promise.reject(new Error('The email you provided is already in use'))
  }
  const message = graphQLErrors.map(e => e.message).join('; ')
  return Promise.reject(new Error(message))
}
