import React from 'react'
import { DocumentsCard } from './DocumentsCard'
import { InsuranceCard } from './InsuranceCard'
import { ProfileCard } from './ProfileCard'

interface StateProps {
}

export class DashboardPres extends React.Component<StateProps> {
  render () {
    return (
      <div>
        <ProfileCard/>
        <InsuranceCard/>
        <DocumentsCard/>
      </div>
    )
  }
}

export const Dashboard = DashboardPres
