import { FetchResult } from 'apollo-link'
import gql from 'graphql-tag'
import { getApolloClient, handleGraphQLErrors } from '../../../../../../../getApolloClient'
export interface GroupPlan{
  accountid: string
  name: string
  groupPlanNumber: number
  groupPlanUUID: string
  discountLevel: string
  electronicAdjudication: string
  priorAuthorizationRequired: boolean
}
export async function getGroupPlans (insuranceProviderUuid: string): Promise<GroupPlan[]> {
    type Result = FetchResult<{getGroupPlans: any[]}>
    return getApolloClient()
      .then(apolloClient =>
        apolloClient.query({
          query: gql`
          query GetInsuranceProviders($insuranceProviderUuid: String!) {
  
            getGroupPlans(insuranceProviderUUID: $insuranceProviderUuid) {
              accountid
              name
              groupPlanNumber
              groupPlanUUID
              discountLevel
              electronicAdjudication
              priorAuthorizationRequired
            }
          }`,
          variables: { insuranceProviderUuid },
        }),
      )
      .then((result: Result) => {
        if (result.errors) {
          console.error({ result })
          throw result.errors[0]
        }
        if (!result.data) throw new Error('!result.data')
        return result.data.getGroupPlans
      })
      .catch(handleGraphQLErrors)
}
