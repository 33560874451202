export const BLACK = '#010101'
export const GREY = '#afb1af'
export const GREY_LIGHT = '#f8f8f8'
export const WHITE = '#ffffff'

export const BLUE_DARK = '#00263e'
export const BLUE = '#00b0ec'
export const BLUE_LIGHT = '#d0eaf8'
export const BLUE_DEEP = '#275a86'

export const STATUS_RED = 'red'
export const STATUS_AMBER = 'orange'
export const STATUS_GREEN = 'green'
