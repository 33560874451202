export interface I18n {
  I_HAVE_A_PHYSICIAN_TO_PRESCRIBE: string
  INSURANCE_COVERS_MEDICATION: string
  INSURANCE_COVERS_CANNABIS: string
  NAME: string
  GENDER: string
  LANGUAGE: string
  DATE_OF_BIRTH: string
  PHONE_NUMBER: string
  EMAIL: string
  K_NUMBER: string
  YOUR_PROVIDER: string
  YOUR_POLICY_NUMBER: string
  OTHER_PROVIDER: string
  OTHER_POLICY_NUMBER: string
  PHYSICAL_ADDRESS: string
  MAILING_ADDRESS: string
  SHIPPING_ADDRESS: string
  NEWS_AND_UPDATES: string
  PARTICIPATE_IN_RESEARCH: string
  ACCEPT_POLICY: string
  AMEND_NAME_OR_ADDRESS: string
  UPDATE_CONTACT_INFO: string
  LOADING: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    I_HAVE_A_PHYSICIAN_TO_PRESCRIBE: frCA ? 'J\'ai un médecin pour prescrire du cannabis' : 'I have a physician to prescribe cannabis',
    INSURANCE_COVERS_MEDICATION: frCA ? 'L\'assurance couvre les médicaments :' : 'Insurance covers medication:',
    INSURANCE_COVERS_CANNABIS: frCA ? 'L\'assurance couvre le cannabis :' : 'Insurance covers cannabis:',
    NAME: frCA ? 'Nom' : 'Name',
    GENDER: frCA ? 'Genre' : 'Gender',
    LANGUAGE: frCA ? 'Langue' : 'Language',
    DATE_OF_BIRTH: frCA ? 'Date de naissance' : 'Date of Birth',
    PHONE_NUMBER: frCA ? 'Numéro de téléphone' : 'Phone Number',
    EMAIL: frCA ? 'Courriel' : 'Email',
    K_NUMBER: frCA ? 'Numéro K' : 'K Number',
    YOUR_PROVIDER: frCA ? 'Votre fournisseur' : 'Your Provider',
    YOUR_POLICY_NUMBER: frCA ? 'Votre numéro de police' : 'Your Policy Number',
    OTHER_PROVIDER: frCA ? 'Autre fournisseur' : 'Other Provider',
    OTHER_POLICY_NUMBER: frCA ? 'Autre numéro de police' : 'Other Policy Number',
    PHYSICAL_ADDRESS: frCA ? 'Adresse physique' : 'Physical Address',
    MAILING_ADDRESS: frCA ? 'Adresse postale' : 'Mailing Address',
    SHIPPING_ADDRESS: frCA ? 'Adresse de livraison' : 'Shipping Address',
    NEWS_AND_UPDATES: frCA ? 'Les patients abonnés à l’infolettre d’AuroraMD reçoivent des offres promotionnelles, des rabais et d’autres avantages. Je ne souhaitez pas recevoir ces courriels:'
      : 'We offer special promotions, discounts and more to patients subscribed to the Aurora (R) newsletter. I wish to be excluded from these emails:',
    PARTICIPATE_IN_RESEARCH: frCA ? 'AuroraMD pourrait vous inviter à participer à des projets de recherche, comme des études cliniques et des groupes de discussion. Je ne souhaitez pas recevoir ces courriels:'
      : 'Aurora (R) may contact you to participate in patient research, such as clinical studies and focus groups. I wish to be excluded from these emails:',
    ACCEPT_POLICY: frCA ? 'J\'accepte la politique de confidentialité d\'Aurora :' : 'I accept Aurora privacy policy:',
    AMEND_NAME_OR_ADDRESS: frCA ? 'Modifiez le nom ou adresse' : 'Amend Name or Address',
    UPDATE_CONTACT_INFO: frCA ? 'Mettre à jour les informations de contact' : 'Update Contact Info',
    LOADING: frCA ? 'Chargement...' : 'Loading...',
  }
}
