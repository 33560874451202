import { Card } from 'antd'
import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { State } from '../../../../../../redux/State'
import { Client } from '../../../../../../redux/State/Client'
import { ClientInsuranceDownload } from '../../../../../../redux/State/Client/Profile/IClientProfile'
import { SyncPromise } from '../../../../../../redux/SyncPromise'
import { I18n, getI18n } from './InsuranceCard.i18n'

interface StateProps {
  insurance: SyncPromise<ClientInsuranceDownload>
  i18n: I18n
}

export const InsuranceCardPres: React.FC<StateProps> = ({
  insurance,
  i18n,
}) => {
  if (!insurance.value) {
    // Even if loading, hide element until we know we need to display it
    return <></>
  }

  const { period, vac } = insurance.value

  if (!period) return <></>

  return (
    <Card>
      {vac && <h2>{i18n.VAC_COVERAGE_TITLE}</h2>}
      {period && <>
        <p>{i18n.REMAINING_COVERAGE}: {period.allowanceRemaining} grams</p>
        <p>{i18n.END_DATE}: {period.endDate}</p>
        <p>{i18n.NEXT_PERIOD_STARTS}</p>
      </>}
    </Card>
  )
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (
  state,
  props,
): StateProps => {
  return {
    insurance: Client.get().map(c => c.profile.insurance),
    i18n: getI18n(state.language),
  }
}

export const InsuranceCard = connect(mapStateToProps)(InsuranceCardPres)
