export interface I18nCommon {
  FIELD_REQUIRED: string
  ENTER_PHONE: string
  MINIMUM: string
  MAXIMUM: string
  VALUE_OF_CHARACTERS: string
  INPUT_CORRECT: string
  DATE_BIRTH: string
  DATE_NOT_FUTURE: string
  CANCEL: string
  APPLY: string
  SUBMIT: string
  RENEW: string
  UPDATE: string
  SEARCH: string
  NOT_VALID_EMAIL: string
  ENTER_EMAIL: string
  ENTER_SELECT: string
  MAKE_SELECTION: string
  EMAIL_ID: string
  PASSWORD: string
  FORGOT_PASSWORD: string
  LOGIN_NOW: string
  NOT_ACCOUNT: string
  APPLY_NOW: string
  LOGIN: string
  ENTER_PASSWORD: string
  LOADING: string
  PHYSICAL_ADDRESS_EXPLAINED: string
  MAILING_ADDRESS_EXPLAINED: string
  SHIPPING_ADDRESS_EXPLAINED: string
}

export const getI18nCommon = (language: string): I18nCommon => {
  const frCA = language === 'fr-CA'

  return {
    FIELD_REQUIRED: frCA ? 'Ce champ est obligatoire' : 'This field is required',
    ENTER_PHONE: frCA ? 'Veuillez saisir votre numéro de téléphone' : 'Please enter your phone number',
    MINIMUM: frCA ? 'Minimum' : 'Minimum',
    MAXIMUM: frCA ? 'Maximum' : 'Maximum',
    VALUE_OF_CHARACTERS: frCA ? 'la valeur des caractères est' : 'value of characters is',
    INPUT_CORRECT: frCA ? 'Veuillez saisir l\'information correcte' : 'Please input correct',
    DATE_BIRTH: frCA ? 'Date de naissance' : 'Date of Birth',
    DATE_NOT_FUTURE: frCA ? 'La date ne peut pas être dans le futur' : 'Date can not be in future',
    APPLY: frCA ? 'Appliquer' : 'Apply',
    CANCEL: frCA ? 'Annuler' : 'Cancel',
    SUBMIT: frCA ? 'Soumettre' : 'Submit',
    RENEW: frCA ? 'Renouveler' : 'Renew',
    UPDATE: frCA ? 'Mise à jour' : 'Update',
    SEARCH: frCA ? 'Recherche' : 'Search',
    NOT_VALID_EMAIL: frCA ? 'L\'entrée n\'est pas dans un format d\'adresse électronique valide' : 'The input is not in a valid E-mail format',
    ENTER_EMAIL: frCA ? 'Veuillez saisir votre adresse électronique' : 'Please input your E-mail',
    ENTER_SELECT: frCA ? 'Veuillez saisir des données ou sélectionner une option' : 'Please enter data or select an option',
    MAKE_SELECTION: frCA ? 'Veuillez faire une sélection' : 'Please make a selection',
    EMAIL_ID: frCA ? 'Adresse courriel' : 'Email Id',
    PASSWORD: frCA ? 'Mot de passe' : 'Password',
    FORGOT_PASSWORD: frCA ? 'Mot de passe oublié?' : 'Forgot password?',
    LOGIN_NOW: frCA ? 'Connexion' : 'Login Now',
    NOT_ACCOUNT: frCA ? 'Vous n\'avez pas de compte?' : 'Don\'t have an Account?',
    APPLY_NOW: frCA ? 'S\'enregistrer' : 'Apply Now',
    LOGIN: frCA ? 'Ouverture de session' : 'Login',
    ENTER_PASSWORD: frCA ? 'Veuillez saisir votre mot de passe' : 'Please enter your password',
    LOADING: frCA ? 'Chargement...' : 'Loading...',
    PHYSICAL_ADDRESS_EXPLAINED: frCA ? 'Doit être une adresse physique' : 'Must be a physical address',
    MAILING_ADDRESS_EXPLAINED: frCA ? 'Si l\'adresse postale diffère de l\'adresse résidentielle, veuillez remplir cette section. - Il peut s\'agir d\'une boîte postale. REMARQUE : Toute adresse non privée doit être soumise via le formulaire d\'enregistrement pour compléter la section des informations sur l\'institution.' : 'Complete this section if different from residential – This can be a PO BOX. NOTE: Any non-private addresses must be submitted via the application form to complete the institution information section.',
    SHIPPING_ADDRESS_EXPLAINED: frCA ? 'Pour la livraison de colis, veuillez sélectionner soit l\'adresse résidentielle, soit l\'adresse postale' : 'Please select either residential or mailing for parcel delivery',
  }
}
