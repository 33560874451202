import React from 'react'
import { BLUE, WHITE } from '../../style'

interface IProps {
  icon?: any
  style?: React.CSSProperties
  iconStyle?: React.CSSProperties
  iconContainerStyle?: React.CSSProperties
}
interface IStyles {
  [key: string]: React.CSSProperties
}

const styles: IStyles = {
  container: {
    display: 'flex',
    backgroundColor: WHITE,
    borderRadius: 6,
    padding: 10,
    marginBottom: 15,
  },
  iconContainer: {
    position: 'relative',
    width: 44,
    height: 44,
    borderRadius: 6,
    marginRight: 10,
    backgroundColor: BLUE,
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 25,
    height: 25,
  },
}

// wrap content into div element when use Card component with icon
const Card: React.FC<IProps> = ({
  style = {},
  iconContainerStyle = {},
  iconStyle = {},
  icon = '',
  children,
}) => {
  return (
    <div style={{ ...styles.container, ...style }}>
      {icon && (
        <div style={{ ...styles.iconContainer, ...iconContainerStyle }}>
          <img src={icon} alt="" style={{ ...styles.icon, ...iconStyle }} />
        </div>
      )}
      {children}
    </div>
  )
}

export default Card
