import { ApolloQueryResult } from 'apollo-client'
import gql from 'graphql-tag'
import { State } from '../'
import { AccessToken } from '../../../auth/index'
import { getApolloClient, handleGraphQLErrors } from '../../../getApolloClient'
import { Action } from '../../Action'
import { SyncPromise } from '../../SyncPromise'
import * as registry from '../../registry'
import { store } from '../../store'
import { Caregiver } from '../Caregiver'
import { Client } from '../Client'
import { Language } from '../Language'
import { User as IUser, UserDownload } from './IUser'
import { formatQuery } from './formatQuery'

const TYPE = 'USER_DATA'

export type User = IUser

export const User = {
  get (): SyncPromise<User> {
    const user = store.getState().user
    if (!user.isUninitiated()) return user
    downloadUser().catch(console.error)
    return SyncPromise.LOADING
  },
  getRoles (): SyncPromise<string[]> {
    return User.get().map(u =>
      ['client', 'caregiver', 'clinician']
      // @ts-ignore
        .filter(r => u[r]),
    )
  },
  clearCache (): void {
    dispatchUser(SyncPromise.UNINIT)
  },
}

const fromDownload = (dl: UserDownload): User => {
  return {
    ...dl,
    client: Client.fromDownload(dl.client),
    caregiver: Caregiver.fromDownload(dl.caregiver),
  }
}

const downloadUser = async (): Promise<void> => {
  type Result = ApolloQueryResult<{getUser: UserDownload}>
  const accessToken = await AccessToken.get()
  if (!accessToken) {
    console.warn('!accessToken')
    return
  }
  dispatchUser(SyncPromise.LOADING)
  const promise = getApolloClient()
    .then(apolloClient => {
      return apolloClient.query({
        query: gql([formatQuery()]),
        variables: { accessToken },
      })
    })
    .then(result => {
      if (result.errors) {
        console.error(result)
        throw result.errors[0]
      }
      return fromDownload(result.data.getUser)
    })
    .catch(handleGraphQLErrors)
  SyncPromise.fromPromise(promise, dispatchUser)
  promise.then((user: User) => {
    const l = user.client?.profile.language
    if (l) Language.set(l)
  }).catch(console.error)
}

interface UserAction extends Action {
  readonly type: typeof TYPE
  readonly value: SyncPromise<User>
}

function dispatchUser (value: SyncPromise<User>) {
  store.dispatch({ type: TYPE, value })
}

const isUserAction = (x: any): x is UserAction =>
  x.type === TYPE

const reduce = (state: State, action: Action): State|undefined => {
  if (!isUserAction(action)) return
  const user: SyncPromise<User> = action.value
  return {
    ...state,
    user,
  }
}

registry.register(reduce)
