import { Action } from './Action'
import { State, newState } from './State'
import * as registry from './registry'

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reduce = (state: State = newState(), action: Action): State => {
  const s = registry.some(state, action)
  if (s) return s
  if (!isBuiltInAction(action)) {
    throw new Error('No reducer found for: ' + action.type)
  }
  return state
}

function isBuiltInAction (action: Action) {
  return action.type === '@@INIT' || action.type.startsWith('@@redux/INIT')
}
