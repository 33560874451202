import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import Card from '../../../../../../components/Card'
import {
  RegAppForm,
  RegFormData,
} from '../../../../../../components/RegAppForm'
import { Mode } from '../../../../../../components/RegAppForm/Mode'
import { amendClientProfile } from '../../../../../../redux/State/Client/Profile'
import { ClientProfileAmendment } from '../../../../../../redux/State/Client/Profile/IClientProfile'
import { Client } from '../../../../../../redux/State/Client'
import { SyncPromise } from '../../../../../../redux/SyncPromise'
import { Modal } from 'antd'
import { btnTypeConfigs } from '../../../../../../components/Button'
import { State as RootState } from '../../../../../../redux/State'

interface StateProps {
  amendment: SyncPromise<ClientProfileAmendment>
  language: 'en-CA'|'fr-CA'
}

interface State {
  error?: string
  saving?: boolean
}

export class ProfileAmendmentPres extends React.Component<StateProps, State> {
  state: State = {}

  render () {
    const { amendment } = this.props
    return amendment.switchMap(
      <Card><p>ERROR</p></Card>,
      <Card><p>Loading...</p></Card>,
      <Card><p>No Profile</p></Card>,
      p => this.renderValue(p),
    )
  }

  renderValue (amendment: ClientProfileAmendment) {
    const { language } = this.props
    const { error, saving } = this.state

    const handleSave = (data: RegFormData) => {
      this.setState({ saving: true })
      amendClientProfile(data as ClientProfileAmendment)
        .then(() => {
          Modal.info({
            title: 'Thank you for submitting.',
            content: (
              <div>Your amendment has been submitted, our Client Care team will verify your record shortly.</div>
            ),
            maskClosable: true,
            onOk: () => {
              window.location.href = '/#/secure/client/profile'
            },
            okButtonProps: {
              shape: 'round',
              style: {
                ...btnTypeConfigs['rounded-blue'].style,
                minWidth: '80px',
                height: '46px',
              },
            },
          })
        })
        .catch(error => {
          console.error(error)
          this.setState({ error: error.message || error, saving: false })
        })
    }

    return (
      <RegAppForm
        handleSave={handleSave}
        initialMode="rw"
        initialValue={amendment}
        saving={saving}
        mode={Mode.AMENDMENT}
        submitError={error}
        language={language}
      />
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (
  state,
  props,
): StateProps => {
  const amendment = Client.get().map(c => c.profile)
    .map(
      ({ firstName, lastName, physicalAddress, mailingAddress, shippingAddress }) =>
        ({ firstName, lastName, physicalAddress, mailingAddress, shippingAddress }),
    )
  return { amendment, language: state.language }
}

export const ProfileAmendment = connect(mapStateToProps)(ProfileAmendmentPres)
