import React from 'react'
import { I18n } from './index.i18n'

export const Acknowledgement: React.FC<{i18n: I18n}> = ({ i18n }) => {
  return (
    <>
      <p>{i18n.ACKNOWLEDGEMENT0}</p>
      <ul>
        <li>{i18n.ACKNOWLEDGEMENT1}</li>
        <li>{i18n.ACKNOWLEDGEMENT2}</li>
        <li>{i18n.ACKNOWLEDGEMENT3}</li>
        <li>{i18n.ACKNOWLEDGEMENT4}</li>
        <li>{i18n.ACKNOWLEDGEMENT5}</li>
        <li>{i18n.ACKNOWLEDGEMENT6}</li>
        <li>{i18n.ACKNOWLEDGEMENT7}</li>
        <li>{i18n.ACKNOWLEDGEMENT8}</li>
        <li>{i18n.ACKNOWLEDGEMENT9}</li>
        <li>{i18n.ACKNOWLEDGEMENT10}</li>
        <li>{i18n.ACKNOWLEDGEMENT11}</li>
      </ul>
    </>

  )
}
