import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { InsuranceProvider } from '../../../../../../redux/State/InsuranceProvider'
import { UpdateInsuranceForm } from './InsuranceForm/UpdateInsuranceForm'
import { SyncPromise } from '../../../../../../redux/SyncPromise'
import { InsuranceCoverage } from '../../../../../../redux/State/InsuranceCoverage'
import { IInsuranceCoverage } from '../../../../../../redux/State/Client/Profile/IClientProfile'
import { State as RState } from '../../../../../../redux/State'
import { I18n, getI18n } from './index.i18n'

interface StateProps {
  insuranceCoverage?: SyncPromise<IInsuranceCoverage>
  insuranceProviders?: SyncPromise<InsuranceProvider[]>
  i18n: I18n
}

interface StateInsurance {
  knumber?: string
  error?: string
  saving?: boolean
}

export class InsurancePres extends React.Component<StateProps, StateInsurance> {
  state: StateInsurance = {}

  render () {
    const { insuranceProviders, insuranceCoverage, i18n } = this.props

    return (
      <UpdateInsuranceForm
        insuranceProviders = {insuranceProviders?.value ?? []}
        insuranceCoverage={insuranceCoverage?.value ?? undefined}
        i18n={i18n}
      />
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, RState> = (
  state,
  props,
): StateProps => {
  const insuranceProviders = InsuranceProvider.get()
  const insuranceCoverage = InsuranceCoverage.get()

  return { insuranceProviders, insuranceCoverage, i18n: getI18n(state.language) }
}

export const InsurancePage = connect(mapStateToProps)(InsurancePres)
