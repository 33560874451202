import { Maybe } from '../../Maybe'
import { Address } from '../../redux/State/Address'

function getFields (type: string) {
  // @ts-ignore
  const { fieldMode: { SEARCH } } = pca

  return [
    { element: 'search', field: '', SEARCH },
  ].map(x => ({
    ...x,
    element: type + 'Address-' + x.element,
  }))
}

export type Callback = (error: Maybe<Error>, data: { type: string, address: Address}) => unknown

export function initPCA (callback: Callback) {
  // @ts-ignore
  if (typeof pca === 'undefined') return defer(callback)
  // @ts-ignore
  const { Address: PCAddressControl } = pca
  const options = { key: 'WU68-BJ24-YT45-CX99' }
  ;[
    'physical',
    'mailing',
  ].forEach(type => {
    const control = new PCAddressControl(getFields(type), options)
    control.listen('populate', function (pcaddress: any) {
      const address: Address = {
        line1: pcaddress.Line1,
        line2: pcaddress.Line2,
        city: pcaddress.City,
        province: pcaddress.Province,
        postalCode: pcaddress.PostalCode,
      }
      callback(null, { type, address })
    })
  })
}

function defer (callback: Callback) {
  setTimeout(() => initPCA(callback), 100)
}
