import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

export class MaintenancePagePres extends React.Component<RouteComponentProps> {
  render () {
    return (
      <h1>
        <span aria-label="construction emoji" role="img">🚧</span>
        Under Maintenance
        <span aria-label="construction worker emoji" role="img">👷🏽‍♀️</span>
      </h1>
    )
  }
}

export const MaintenancePage = MaintenancePagePres
