import { Config } from '../redux/State/Config'

export async function requestCookieFromToken (token: string): Promise<void> {
  const { LR_API_KEY, URL_LOGIN_RADIUS } = await Config.promise
  const xhr = new XMLHttpRequest()
  const url = URL_LOGIN_RADIUS + '/ssologin/setToken?apikey=' + LR_API_KEY + '&token=' + token
  xhr.open('GET', url, true)
  xhr.withCredentials = true
  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = function () {
      if (xhr.readyState !== 4) return
      if (xhr.status !== 200) {
        console.error(xhr)
        return reject(new Error(`${xhr.status}: ${xhr.responseText}`))
      }
      return resolve()
    }
    xhr.send()
  })
}
