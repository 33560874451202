import { Card } from 'antd'
import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { ClientList } from '../../../../../components/ClientList/index'
import { State } from '../../../../../redux/State'
import { Caregiver } from '../../../../../redux/State/Caregiver'
import { CaregiverClient } from '../../../../../redux/State/Caregiver/ICaregiver'
import { SyncPromise } from '../../../../../redux/SyncPromise'
import { I18n, getI18n } from './index.i18n'

interface Props {}
interface StateProps {
  i18n: I18n
  name: SyncPromise<string>
  isActive: SyncPromise<boolean>
  clients: SyncPromise<CaregiverClient[]>
}

interface StateRouteProps extends RouteComponentProps, StateProps {}

export class CaregiverClientsPagePres extends React.Component<StateRouteProps> {
  render () {
    const { clients, name, isActive, i18n } = this.props
    const list = clients.switch(
      [],
      [],
      [],
    )
    const message = name.switchMap(
      'ERROR',
      'Loading...',
      'No Name',
      n => <>{i18n.WELCOME} <strong>{n}</strong></>,
    )
    return (
      <>
        <Card style={{ marginBottom: 5 }}>
          <h2>{message}</h2>
          <Link to='/secure/caregiver/profile' style={{ textDecoration: 'underline' }}>
          My Profile
          </Link>
          { isActive.value === false && <p>
            Your caregiver account has been deactivated. Please note you
            will no longer be able to access our online shop to order on
            behalf of an Aurora client.
          </p>}
        </Card>
        <Card>
          <ClientList list={list} />
        </Card>
      </>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  return {
    i18n: getI18n(state.language),
    name: Caregiver.getName(),
    isActive: Caregiver.isActive(),
    clients: Caregiver.getClients(),
  }
}

export const CaregiverClientsPage = connect(mapStateToProps)(withRouter(CaregiverClientsPagePres))
