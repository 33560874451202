import { AddressSetBuilder, ShippingAlias } from '../../redux/State/Address'

export type SelectItems = Array<{
  label: string
  value: string
}>

export const getLanguages = (language: 'en-CA'|'fr-CA' = 'en-CA'): SelectItems => {
  const frCA = language === 'fr-CA'

  return [{
    label: frCA ? 'Anglais' : 'English',
    value: 'en-CA',
  },
  {
    label: frCA ? 'Français' : 'French',
    value: 'fr-CA',
  }]
}

export const getGenders = (language: 'en-CA'|'fr-CA' = 'en-CA'): SelectItems => {
  const frCA = language === 'fr-CA'

  return [{
    label: frCA ? 'Homme' : 'Male',
    value: 'male',
  },
  {
    label: frCA ? 'Femme' : 'Female',
    value: 'female',
  },
  {
    label: frCA ? 'Autre' : 'Other',
    value: 'other',
  },
  {
    label: frCA ? 'Non dévoilé' : 'Undisclosed',
    value: 'undisclosed',
  }]
}

export const provinces = [
  {
    label: 'Alberta',
    value: 'AB',
  },
  {
    label: 'British Columbia',
    value: 'BC',
  },
  {
    label: 'Manitoba',
    value: 'MB',
  },
  {
    label: 'New Brunswick',
    value: 'NB',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    label: 'Nova Scotia',
    value: 'NS',
  },
  {
    label: 'Northwest Territories',
    value: 'NT',
  },
  {
    label: 'Nunavut',
    value: 'NU',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Prince Edward Island',
    value: 'PE',
  },
  {
    label: 'Quebec',
    value: 'QC',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    label: 'Yukon',
    value: 'YT',
  },
]

export interface ShippingOption {
  label: string
  value: ShippingAlias
}
export function getShippingOptions (addresses: AddressSetBuilder, language: 'en-CA'|'fr-CA' = 'en-CA'): ShippingOption[] {
  const frCA = language === 'fr-CA'

  return [
    addresses.physical && {
      label: frCA ? 'Adresse Résidentielle' : 'Residential Address',
      value: 'physical',
    },
    addresses.mailing && !addresses.isMailingAddressSameAsPhysical() && {
      label: frCA ? 'Adresse Postale' : 'Mailing Address',
      value: 'mailing',
    },
    addresses.practitioner && {
      label: frCA ? 'Adresse du praticien' : 'Practitioner Address',
      value: 'practitioner',
    },
  ].filter(x => x) as ShippingOption[]
}
