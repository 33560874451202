import { Card } from 'antd'
import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { State } from '../../../../../../redux/State'
import { Beta } from '../../../../../../redux/State/Beta'
import { Client } from '../../../../../../redux/State/Client'
import { SyncPromise } from '../../../../../../redux/SyncPromise'
import { I18n, getI18n } from './ProfileCard.i18n'

interface StateProps {
  i18n: I18n
  name: SyncPromise<string>
  beta: boolean
  priceAdjustment: SyncPromise<{
    discount: number
    type: string
  }>
  callToActionMessage: string[] | undefined
}

export const ProfileCardPres: React.FC<StateProps> = ({
  i18n,
  name,
  beta,
  priceAdjustment,
  callToActionMessage,
}) => {
  const message = name.switchMap(
    'ERROR',
    'Loading...',
    'No Name',
    n => <>{i18n.WELCOME}<strong>{n}</strong></>,
  )
  const pa = priceAdjustment.value

  return (
    <Card>
      <h2>{message}</h2>
      {callToActionMessage?.map((el, i) => (
        <p key={i}>{el}</p>
      ))}
      <p>
        <Link to='/secure/client/insurance' style={{ textDecoration: 'underline' }}>
          {i18n.MY_INSURANCE}
        </Link>
      </p>
      <p>
        <Link to='/secure/client/profile' style={{ textDecoration: 'underline' }}>
          {i18n.MY_PROFILE}
        </Link>
      </p>
      <p>
        <Link to='/secure/client/privacy' style={{ textDecoration: 'underline' }}>
          {i18n.MY_PRIVACY}
        </Link>
      </p>
      {pa && <p>
        <strong>{i18n.PRICE_ADJUSTMENTS}</strong> {pa.type} -{pa.discount * 100}%
      </p>}
    </Card>
  )
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (
  state,
  props,
): StateProps => {
  return {
    i18n: getI18n(state.language),
    name: Client.getName(),
    beta: Beta.is(),
    priceAdjustment: Client.get().map(c => c.priceAdjustment),
    callToActionMessage: Client.getCallToActionMessage(),
  }
}

export const ProfileCard = connect(mapStateToProps)(ProfileCardPres)
