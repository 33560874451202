import React, { useState, useEffect } from 'react'
import { Card, Form, Input } from 'antd'
import FormInput, { SelectItem } from '../../../../../../../components/FormInput'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { InsuranceProvider } from '../../../../../../../redux/State/InsuranceProvider'
import { HelpPromptTooltip } from '../../../../../../../components/Tooltip'
import { getGroupPlans } from './getGroupPlans'
import { IInsuranceCoverage } from '../../../../../../../redux/State/Client/Profile/IClientProfile'
import { I18n, getI18n } from '../index.i18n'
import { State } from '../../../../../../../redux/State'
import { connect } from 'react-redux'
import { getI18nCommon, I18nCommon } from '../../../../../../../components/index.i18n'

export enum InsuranceType {
  GROUP_PLAN = 'PRV',
  VAC = 'VAC',
  NONE = 'NONE'
}

const insurancePlans = (i18n: I18n): SelectItem[] => [
  {
    label: i18n?.GROUP_PLAN,
    value: InsuranceType.GROUP_PLAN,
  },
  {
    label: i18n?.VAC,
    value: InsuranceType.VAC,
  },
  {
    label: i18n?.NONE,
    value: InsuranceType.NONE,
  },
]
const GROUP_PLAN_NUMBER_MAX_LENGTH = 10
const insurancePlansDisabled = (i18n: I18n): SelectItem[] => {
  return insurancePlans(i18n).map((plan) => ({
    ...plan,
    disabled: true,
  }))
}

const cardHolderOptions = (i18n: I18n): SelectItem[] => [
  {
    label: i18n.CARDHOLDER,
    value: 'Cardholder',
  },
  {
    label: i18n.SPOUSE,
    value: 'Spouse',
  },
  {
    label: i18n.UNDERAGE_CHILD,
    value: 'Underage-Child',
  },
  {
    label: i18n.OVERAGE_CHILD,
    value: 'Overage-Child',
  },
  {
    label: i18n.DISABLED_DEPENDENT,
    value: 'Disabled-Dependent',
  },
  {
    label: i18n.DEPENDENT_STUDENT,
    value: 'Dependent-Student',
  },
  {
    label: i18n.UNKNOWN,
    value: 'Unknown',
  },
]

export const getProviderUUIDByName = (insuranceProviders?: InsuranceProvider[], name?: String) => {
  return insuranceProviders && name ? insuranceProviders?.find((insuranceProvider: InsuranceProvider) => insuranceProvider.name === name)?.id : undefined
}

export interface OwnProps {
  initialMode: 'ro' | 'rw'
  initialValue?: IInsuranceCoverage
  insuranceProviders?: InsuranceProvider[]
  typeReadOnly?: boolean
  formReadOnly?: {[key in InsuranceType]: boolean}
}

export interface ConnectedProfileInsuranceProps {
  i18n: I18n
  i18nCommon: I18nCommon
}

export type ProfileInsuranceProps = OwnProps & ConnectedProfileInsuranceProps

export interface ProfileInsuranceFormProps extends ProfileInsuranceProps {
  form: WrappedFormUtils
}

const decOptProviderName = (i18n: I18n) => {
  return {
    rules: [
      {
        validator: (_r: unknown, v: unknown, cb: Function) => {
          if (!v) {
            cb(new Error(i18n.PLEASE_SELECT))
            return
          } else if ((v as string).toUpperCase() === 'OTHER') {
            cb(new Error(`${i18n.INSURER_NOT_LISTED} 1-877-928-7672`))
            return
          }
          cb()
        },
      },
    ],
  }
}

const decOptRequiredTrue = (msg: string) => {
  return {
    rules: [{
      message: msg,
      validator: (_r: unknown, v: unknown, cb: (b: any) => unknown) => cb(!v || undefined),
    }],
  }
}

interface GroupPlanSelector extends SelectItem {
  electronicAdjudication?: boolean
}

export const ProfileInsurancePres = ({
  form,
  initialMode,
  initialValue,
  insuranceProviders,
  typeReadOnly = false,
  formReadOnly = {
    [InsuranceType.GROUP_PLAN]: false,
    [InsuranceType.VAC]: false,
    [InsuranceType.NONE]: false,
  },
  i18n,
  i18nCommon,
}: ProfileInsuranceFormProps) => {
  const [providers, setProviders] = useState<SelectItem[]>([])
  const [groupPlans, setGroupPlans] = useState<GroupPlanSelector[]>([])
  const [loadingGroupPlans, setLoadingGroupPlans] = useState<boolean>(false)

  useEffect(() => {
    setProviders((insuranceProviders?.map(ip => ({
      label: ip.name,
      value: ip.id,
    })) ?? []).concat([{
      label: i18n.OTHER,
      value: 'OTHER',
    }]))
  }, [insuranceProviders, i18n])

  useEffect(() => {
    form.setFieldsValue({ insuranceType: initialValue?.insuranceType })
    form.setFieldsValue({ directBilling: false })

    setTimeout(() => {
      form.setFieldsValue({ ...initialValue })
    }, 0)

    if (initialValue?.insuranceType === InsuranceType.GROUP_PLAN && initialValue?.insuranceProviderName && insuranceProviders) {
      const providerUUID = getProviderUUIDByName(insuranceProviders, initialValue?.insuranceProviderName)

      if (providerUUID) {
        setInsuranceCoverageGroupPlans(providerUUID)
      }
    }
    // eslint-disable-next-line
  }, [initialValue])

  useEffect(() => {
    setDirectBilling(form.getFieldValue('groupPlanNumber'))
    // eslint-disable-next-line
  }, [groupPlans])

  const handleProviderChange = (providerUUID: string) => {
    form.setFieldsValue({ groupPlanNumber: undefined })
    setInsuranceCoverageGroupPlans(providerUUID)
  }

  const setInsuranceCoverageGroupPlans = (providerUUID: string) => {
    setGroupPlans([])

    if (providerUUID && providerUUID !== 'OTHER') {
      setLoadingGroupPlans(true)
      getGroupPlans(providerUUID).then((groupPlans) => {
        setLoadingGroupPlans(false)
        setGroupPlans(groupPlans?.map(groupPlan => ({
          label: `${groupPlan.groupPlanNumber}`,
          value: `${groupPlan.groupPlanNumber}`,
          electronicAdjudication: !!groupPlan.electronicAdjudication,
        })) ?? [])
      }).catch(() => {
        setLoadingGroupPlans(false)
      })
    }
  }

  const decOptGroupPlan = () => {
    return {
      rules: [
        {
          validator: (_r: unknown, v: unknown, cb: Function) => {
            if (!v) {
              cb(new Error(i18nCommon.ENTER_SELECT))
              return
            } else if ((v as String).length > GROUP_PLAN_NUMBER_MAX_LENGTH) {
              cb(new Error(`${i18nCommon.MAXIMUM} ${i18nCommon.VALUE_OF_CHARACTERS} ${GROUP_PLAN_NUMBER_MAX_LENGTH}`))
              return
            }
            cb()
          },
        },
      ],
    }
  }

  const setDirectBilling = (groupPlanNumber: String): void => {
    let isDirectBilling = 'false'

    if (groupPlanNumber) {
      const currentGroupPlanObject = groupPlans
        ? groupPlans.find((groupPlan) => groupPlanNumber.trim() === groupPlan.value)
        : undefined

      /* A group plan number is not in the Provider Group Plans list. */
      if (currentGroupPlanObject) {
        isDirectBilling = 'true'
      }
      /* Client selects a group plan that has the attribute “Electronic Adjudication: FALSE” */
      if (!currentGroupPlanObject?.electronicAdjudication) {
        isDirectBilling = 'false'
      }
    }

    form.setFieldsValue({ directBilling: isDirectBilling })
  }

  return (
    <div>
      <Card>
        <p>{i18n?.DO_YOU_HAVE_INSURANCE_COVERAGE}</p>
        <FormInput
          form={form}
          type="radioGroup"
          name="insuranceType"
          selectItems={typeReadOnly ? insurancePlansDisabled(i18n) : insurancePlans(i18n)}
          decoratorOptions={{
            rules: [
              {
                required: true,
                message: i18nCommon.MAKE_SELECTION,
              },
            ],
          }}
        />
      </Card>
      <Form.Item>
        {form.getFieldDecorator('directBilling')(
          <Input type='hidden'/>,
        )}
      </Form.Item>
      <Form.Item>
        {form.getFieldDecorator('insuranceCoverageUUID')(
          <Input type='hidden'/>,
        )}
      </Form.Item>
      {form.getFieldValue('insuranceType') === InsuranceType.GROUP_PLAN && (
        <Card title={i18n?.INSURANCE_COVERAGE} style={{ marginTop: 10 }}>
          <HelpPromptTooltip label={i18n?.RELATIONSHIP_TO_CARDHOLDER}
            toolTipText={i18n?.INSURANCE_COVERAGE_TOOLTIP} />
          <FormInput
            name="relationshipToPolicyHolder"
            form={form}
            type="select"
            mode={initialMode}
            placeholder={i18n.SELECT_RELATIONSHIP}
            selectItems={cardHolderOptions(i18n)}
            decoratorOptions={decOptRequiredTrue(i18nCommon.MAKE_SELECTION)}
          />
          <HelpPromptTooltip label={i18n?.INSURANCE_PROVIDER} toolTipText={i18n?.INSURANCE_PROVIDER_TOOLTIP} />
          <FormInput
            decoratorOptions={decOptProviderName(i18n)}
            form={form}
            mode={initialMode}
            name="insuranceProviderName"
            showSearch={true}
            selectItems={providers}
            onSelect={handleProviderChange}
            type="select"
          />
          <HelpPromptTooltip label={i18n?.GROUP_PLAN_POLICY_NUMBER} toolTipText={i18n?.GROUP_PLAN_TOOLTIP}/>

          <FormInput
            decoratorOptions={decOptGroupPlan()}
            form={form}
            type="autoSuggest"
            selectItems={groupPlans}
            mode={initialMode}
            onChange={setDirectBilling}
            name="groupPlanNumber"
          />
          {loadingGroupPlans && <div style={{ position: 'absolute', color: '#00b0ec', fontSize: '12', marginTop: '-25' }}>{i18n.LOADING_GROUP_PLANS}</div>}
          <HelpPromptTooltip label={i18n?.CERTIFICATE_NUMBER} toolTipText={i18n?.CERTIFICATE_NUMBER_TOOLTIP} />
          <FormInput
            decoratorOptions={decOptRequiredTrue(i18n.CERTIFICATE_ERROR)}
            form={form}
            type="text"
            mode={initialMode}
            maxLength={15}
            name="certificateNumber"
          />

          <HelpPromptTooltip label={i18n?.CARDHOLDER_ID} toolTipText={i18n?.CARDHOLDER_ID_TOOLTIP} />
          <FormInput
            form={form}
            type="text"
            mode={initialMode}
            maxLength={5}
            name="cardholderId"
          />
        </Card>
      )}

      {form.getFieldValue('insuranceType') === InsuranceType.VAC && (
        <Card title={i18n.VETERAN_COVERAGE} style={{ marginTop: 10 }}>
          {!formReadOnly[InsuranceType.VAC] && <div className="double-input-field">
            <FormInput
              type="checkbox"
              name="isVeteran"
              label={i18n?.CHECKBOX_I_AM_A_VETERAN}
              form={form}
              mode={initialMode}
              decoratorOptions={decOptRequiredTrue(i18nCommon.FIELD_REQUIRED)}
            />
            <FormInput
              name="kNumber"
              label={i18n?.K_NUMBER}
              form={form}
              mode={initialMode}
              maskType="kNumber"
              decoratorOptions={{
                rules: [
                  {
                    pattern: /\d{7}/,
                    required: true,
                    message: i18n.SUBMIT_K_NUMBER,
                  },
                ],
              }}
            />
          </div>}
          {formReadOnly[InsuranceType.VAC] && <div>
            {initialValue?.kNumber && <p>{i18n?.K_NUMBER}: <b>{initialValue?.kNumber}</b></p>}
            <p>{i18n?.TO_UPDATE_DETAILS_CALL} <a href="tel:1-877-928-7672">1-877-928-7672</a>.</p>
          </div>}
        </Card>
      )}
    </div>
  )
}

const mapStateToProps = (state: State): { i18n: I18n, i18nCommon: I18nCommon } => ({ i18n: getI18n(state.language), i18nCommon: getI18nCommon(state.language) })

export const ProfileInsurance = connect(mapStateToProps)(ProfileInsurancePres)
