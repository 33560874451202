import './style.less'
import React from 'react'
import { WHITE, BLACK } from '../../../../../style'

interface Props {
  title: string
  topic: string
  style?: React.CSSProperties
}

const ReceivedApplicationCard: React.FC<Props> = ({
  title,
  topic,
  style = {},
  children,
}) => {
  return (
    <div
      className="cta-wrapped-card"
      style={{
        backgroundColor: WHITE,
        padding: '70px 100px',
        borderRadius: 6,
        ...style,
      }}
    >
      <h1 style={{ fontFamily: 'Copernicus' }}>{title}</h1>
      <div style={{ marginBottom: 25, color: BLACK }}>{topic}</div>
      <div style={{ display: 'flex', flexDirection: 'column' }} className='cta-wrapped-container'>
        {children}
      </div>
    </div>
  )
}

export default ReceivedApplicationCard
