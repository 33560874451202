import './style.less'
import { Collapse, Icon } from 'antd'
import React from 'react'

const { Panel } = Collapse

interface IProps {
  className?: string
  style?: React.CSSProperties
  header: string
}

export const Collapsible: React.FC<IProps> = ({ className = '', style = {}, header = '', children }) => {
  return (
    <div className={`card-wrapper-collapsible ${className}`} style={style}>
      <Collapse
        bordered={false}
        accordion
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <Icon type={isActive ? 'minus-circle' : 'plus-circle'} theme="twoTone" />}
        expandIconPosition={'right'}
      >
        <Panel header={header} key="1">
          <div className={`panel-content ${className}`}>
            {children}
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default Collapsible
