import './style.less'
import { Layout } from 'antd'
import React from 'react'
import { Provider } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'
import { Footer } from '../components/Footer'
import { HeaderContent } from '../components/Header'
import ScrollToTop from '../components/ScrollToTop'
import { store } from '../redux/store'
import { WHITE } from '../style'
import { Page } from './Page'
import { GoogleTagManagerHelmet } from '../components/GoogleTagManagerHelmet'

const { Header, Content } = Layout

export class App extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <Router>
          <Layout>
            <div className="App">
              <ScrollToTop/>
              <Header style={{ background: WHITE, padding: 0 }}>
                <HeaderContent/>
              </Header>
              <GoogleTagManagerHelmet/>
              <Content
                style={{ minHeight: 380 }}
                className="layout-main-box"
              >
                <Page />
              </Content>
              <Footer />
            </div>
          </Layout>
        </Router>
      </Provider>
    )
  }
}
