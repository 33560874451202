import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import Card from '../../../../../../components/Card'
import {
  RegAppForm,
  RegFormData,
} from '../../../../../../components/RegAppForm'
import { Mode } from '../../../../../../components/RegAppForm/Mode'
import { Beta } from '../../../../../../redux/State/Beta'
import { Client } from '../../../../../../redux/State/Client'
import { renewClientProfile } from '../../../../../../redux/State/Client/Profile'
import {
  ClientInsuranceUpload,
  ClientProfileRenewal, IInsuranceCoverage,
} from '../../../../../../redux/State/Client/Profile/IClientProfile'
import { InsuranceProvider } from '../../../../../../redux/State/InsuranceProvider'
import { SyncPromise } from '../../../../../../redux/SyncPromise'
import Button from '../../../../../../components/Button'
import { InsuranceCoverage } from '../../../../../../redux/State/InsuranceCoverage'
import { postInsuranceCoverage } from '../Insurance/InsuranceForm/postInsuranceCoverage'
import { AccessToken } from '../../../../../../auth'
import { User } from '../../../../../../redux/State/User'
import { showNoDirectBillingWarning } from '../Insurance/InsuranceForm/UpdateInsuranceForm'
import { State as RootState } from '../../../../../../redux/State'
import { I18n, getI18n } from '../Insurance/index.i18n'

interface StateProps {
  beta: boolean
  insuranceProviders: SyncPromise<InsuranceProvider[]>
  renewal: SyncPromise<ClientProfileRenewal>
  insuranceCoverage: SyncPromise<IInsuranceCoverage>
  i18n: I18n
  language: 'en-CA'|'fr-CA'
}

interface State {
  error?: string
  saving?: boolean
  success?: boolean
}

export class RenewalPagePres extends React.Component<StateProps, State> {
  state: State = {}

  render () {
    const { insuranceCoverage } = this.props

    return insuranceCoverage.switchMap(
      <Card><p>ERROR</p></Card>,
      <Card><p>Loading...</p></Card>,
      <Card><p>No Profile</p></Card>,
      () => this.renderValue(),
    )
  }

  onError (error: Error) {
    console.error(error)
    this.setState({
      error: error.message,
      saving: false,
    })
  }

  onSuccess () {
    User.clearCache()
    this.setState({ success: true })
  }

  renderValue () {
    const { beta, insuranceProviders, renewal, insuranceCoverage, i18n, language } = this.props
    const { error, saving } = this.state

    const handleSave = (data: RegFormData, insuranceData?: ClientInsuranceUpload) => {
      this.setState({ saving: true })
      renewClientProfile(data as ClientProfileRenewal)
        .then(() => {
          if (insuranceData) {
            AccessToken.get().then(accessToken => {
              postInsuranceCoverage(insuranceData, accessToken as any).then(() => {
                if ((document.getElementById('application_directBilling') as HTMLInputElement).value !== 'true') {
                  showNoDirectBillingWarning(this.onSuccess.bind(this), i18n)
                } else {
                  this.onSuccess()
                }
              }).catch(this.onError)
            }).catch(this.onError)
          } else {
            this.onSuccess()
          }
        })
        .catch(error => {
          console.error(error)
          this.setState({ error: error.message || error, saving: false })
        })
    }

    if (this.state.success) { return this.renderSuccess() }

    return (
      <RegAppForm
        beta={beta}
        handleSave={handleSave}
        initialMode="rw"
        initialValue={renewal.value ?? undefined}
        initialInsurance={insuranceCoverage.value ?? undefined}
        insuranceProviders={insuranceProviders.value ?? []}
        mode={Mode.RENEWAL}
        saving={saving}
        submitError={error}
        language={language}
      />
    )
  }

  renderSuccess () {
    window.scrollTo(0, 0)
    const { i18n } = this.props

    return (
      <Card
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '5%',
        }}
      >
        <p>{i18n.RECEIVED_APPLICATION}</p>
        <Button
          btnType="rounded-blue"
          onClick={() => { window.location.href = '/#/secure/client' }}
          style={{
            height: '46px',
            fontFamily: 'Polaris, sans-serif',
          }}
        >
          {i18n.FINISH}
        </Button>
      </Card>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (
  state,
  props,
): StateProps => {
  const beta = Beta.is()
  const insuranceProviders = InsuranceProvider.get()
  const renewal = Client.get().map(c => c.profile)
    .map(
      ({ firstName, lastName, dateOfBirth, gender, physicalAddress, mailingAddress, shippingAddress }) => {
        return { firstName, lastName, dateOfBirth, gender, physicalAddress, mailingAddress, shippingAddress }
      },
    )
  const insuranceCoverage = InsuranceCoverage.get()
  return { beta, insuranceProviders, renewal, insuranceCoverage, i18n: getI18n(state.language), language: state.language }
}

export const RenewalPage = connect(mapStateToProps)(RenewalPagePres)
