export interface I18n {
  MY_PRESCRIPTION: string
  EXPIRATION_DATE: string
  REMAINING: string
  GRAMS: string
  PER_DAY: string
  ERROR: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    MY_PRESCRIPTION: frCA ? 'Ma Prescription' : 'My Prescription',
    EXPIRATION_DATE: frCA ? 'Date d\'expiration' : 'Expiration Date',
    REMAINING: frCA ? 'restants' : 'remaining',
    GRAMS: frCA ? 'grammes' : 'grams',
    PER_DAY: frCA ? 'par jour' : 'per day',
    ERROR: frCA ? 'ERREUR' : 'ERROR',
  }
}
