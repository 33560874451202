import React, { CSSProperties } from 'react'
import Cta, { CtaItem } from '../Cta'

interface Props {
  items: CtaItem[]
  style?: CSSProperties
}

export const CtaTwoCol: React.FC<Props> = ({ items, style = {} }) => {
  return (
    <div style={{
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: '70px',
      ...style,
    }}>
      {items.map(el => {
        return <Cta
          key={el.title}
          {...el}
          style={{
            minWidth: 300,
            flex: '0 46%',
            marginRight: '4%',
            marginBottom: 20,
          }}
          titleStyle={{
            fontFamily: '"Polaris", sans-serif',
            fontSize: '18px',
            lineHeight: '30px',
            fontWeight: 600,
          }}
        />
      })}
    </div>
  )
}

export default CtaTwoCol
